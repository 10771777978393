import { Component, Input, OnInit } from '@angular/core';
import { SegmentacionService } from 'src/app/Services/admin/segmentacion.service';

@Component({
  selector: 'app-paises',
  templateUrl: './paises.component.html',
  styleUrls: ['./paises.component.scss']
})
export class PaisesComponent implements OnInit {

  @Input() pais;
  @Input() empresas;
  @Input() negocios;
  @Input() cuentas;
  @Input() centroCostos;
  @Input() areas;
  @Input() curso;
  bEmpresa:boolean;

  constructor(
    private segmentacionService: SegmentacionService
  ) {
    this.bEmpresa = false;
  }

  ngOnInit(): void {
  }

  mostrarEmpresa(CodPais:string){
    this.bEmpresa = !this.bEmpresa;

    if(this.bEmpresa){
      this.getEmpresa(CodPais);
    }
  }

  getEmpresa(CodPais:string):void{
    this.segmentacionService.getSegmentacionAppBiEmpresa(CodPais,this.curso.intCursoID).subscribe(result=>{

      if(result.code==200){
        this.empresas = result.result;
      }

    }, (err)=>{
      console.log(err);
    });
  }
  onCheckboxChange(value,obj):void{

    if (value.target.checked) {
      var Entity = {
        intCursoID : this.curso.intCursoID,
        intTipo: 1,
        strSegmentacion: obj.codPais,
        intAccion: 1
      };
       this.segmentacionService.postSegmentacion(Entity).subscribe(result=>{
         if(result.code==200){

         }
         else{

         }
       },(err)=>{
         console.log(err);
       });

    } else {

      var _Entity = {
        intCursoID : this.curso.intCursoID,
        intTipo: 1,
        strSegmentacion: obj.codPais,
        intAccion: 0
      };
       this.segmentacionService.postSegmentacion(_Entity).subscribe(result=>{
         if(result.code==200){

         }
         else{

         }
       },(err)=>{
         console.log(err);
       });

    }

  }
}
