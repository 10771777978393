import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {

  IsAuthorized: any;
  private authSource = new Subject<boolean>();
  authChallenge$ = this.authSource.asObservable();
  constructor( private storeService: StorageService) {
    if (this.storeService.retrieve('IsAuthorized') !== '') {
      this.IsAuthorized = this.storeService.retrieve('IsAuthorized');
      this.authSource.next(this.IsAuthorized);
    }
   }

  public GetToken(): any {
    return this.storeService.retrieve('authData');
  }

  public GetUsuarioID(): any {
    return this.storeService.retrieve('intUsuarioID');
  }

  public GetUsuario(): any {
    return this.storeService.retrieve('strUsuario');
  }

  public GetFoto(): any {
    return this.storeService.retrieve('strFoto');
  }

  public GetProfesorID(): any {
    return this.storeService.retrieve('intProfesorID');
  }
  public GetEsAdmin(): any {
    return this.storeService.retrieve('intEsAdmin');
  }
  public GetNombres(): any {
    return this.storeService.retrieve('strNombres');
  }

  public GetApellidoPaterno(): any {
    return this.storeService.retrieve('strApellidopaterno');
  }

  public GetApellidoMaterno(): any {
    return this.storeService.retrieve('strApellidomaterno');
  }

  public GetCorreo(): any {
    return this.storeService.retrieve('strCorreo');
  }

  public GetCodPais(): any {
    return this.storeService.retrieve('strCod_PAIS');
  }

  public GetCodNegocio(): any {
    return this.storeService.retrieve('strCod_NEGOCIO');
  }


  public GetCodCuenta(): any {
    return this.storeService.retrieve('strCod_CUENTA');
  }
  public GetCodAdministrador(): any {
    return this.storeService.retrieve('intAdministradorID');
  }

  public GetIDJerarquia(): any {
    return this.storeService.retrieve('idjerarquia');
  }


  public ResetAuthData() {
    this.storeService.store('authData', '');
    this.IsAuthorized = false;
    this.storeService.store('IsAuthorized', false);
    this.storeService.store('intUsuarioID', '');
    this.storeService.store('strUsuario', '');
    this.storeService.store('strFoto', '');
    this.storeService.store('intProfesorID', '');
    this.storeService.store('intEsAdmin', '');
    this.storeService.store('strNombres', '');
    this.storeService.store('strApellidopaterno', '');
    this.storeService.store('strApellidomaterno', '');
    this.storeService.store('strCorreo', '');
    this.storeService.store('strCod_PAIS', '');
    this.storeService.store('strCod_NEGOCIO', '');
    this.storeService.store('strCod_CUENTA', '');
    this.storeService.store('intAdministradorID', '');
    this.storeService.store('idjerarquia', '');

  }

  public SetAuthData(
    token: any,
    intUsuarioID:number,
    strUsuario:string,
    strFoto:string,
    intProfesorID:number,
    intEsAdmin:number,
    strNombres:string,
    strApellidopaterno :string,
    strApellidomaterno :string,
    strCorreo :string,
    strCod_PAIS :string,
    strCod_NEGOCIO :string,
    strCod_CUENTA :string,
    intAdministradorID:number,
    idjerarquia : number
    ) {
    this.storeService.store('authData', token);
    this.IsAuthorized = true;
    this.storeService.store('IsAuthorized', true);
    this.storeService.store('intUsuarioID', intUsuarioID);
    this.storeService.store('strUsuario', strUsuario);
    this.storeService.store('strFoto', strFoto);
    this.storeService.store('intProfesorID', intProfesorID);
    this.storeService.store('intEsAdmin', intEsAdmin);
    this.storeService.store('strNombres', strNombres);
    this.storeService.store('strApellidopaterno', strApellidopaterno);
    this.storeService.store('strApellidomaterno', strApellidomaterno);
    this.storeService.store('strCorreo', strCorreo);
    this.storeService.store('strCod_PAIS', strCod_PAIS);
    this.storeService.store('strCod_NEGOCIO', strCod_NEGOCIO);
    this.storeService.store('strCod_CUENTA', strCod_CUENTA);
    this.storeService.store('intAdministradorID', intAdministradorID);
    this.storeService.store('idjerarquia', idjerarquia);

    this.authSource.next(true);
  }

  public LogOff() {
    this.ResetAuthData();

    this.authSource.next(false);
  }
}
