import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'src/app/Services/comun/dialog.service';

import { SecurityService } from 'src/app/system/security.service';
import { CursosService } from '../../../../Services/estudiante/cursos.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {
  curso:any;
  valor:number;
  constructor(
    private utilsService : UtilsService,
    public dialogRef: MatDialogRef<RatingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private securityService:SecurityService,
      private cursosService: CursosService,
      private dialogSerive: DialogService
  ) {
    this.curso = data;
  }

  ngOnInit(): void {
    this.valor = 1;
  }

  mouseEnter(valor:number):void {
    this.valor = valor;
  }

  ValorarCurso(strComentario:string):void {
    debugger;
    var oBE = {
      intClasificacion : this.valor,
      strComentario :strComentario,
      intCursoID : this.curso.intCursoID,
      //intUsuarioID : this.securityService.GetUsuarioID()
      strUsuarioID : this.utilsService.convertBase64Url(this.securityService.GetUsuarioID())
    };
    this.cursosService.PostValoracion(oBE).subscribe(result=>{
      if(result.code==200){
        this.dialogSerive.openMsgSuccessDialog("Aviso","Se registró correctamente la valoración").afterClosed().subscribe(res=>{
        });
      }
      else{
        this.dialogSerive.openMsgErrorDialog(result.message).afterClosed().subscribe(res=>{
        });
      }
    },(err)=>{
      console.log(err);
    });
  }
}
