<header>
  <div class="rounded p-3 mb-1 flex justify-between items-center">
    <div>
      <h1 (click)="mostrarCuenta(negocio.codPais,negocio.codEmpresa,negocio.codNegocio)" class="cursor-pointer"><strong>Unidad de Negocio: </strong> {{negocio.descripcion}}</h1>
    </div>
    <div>
      <label class="flex items-center">
        <span class="inline-flex">
          <input *ngIf="negocio.intCursoID" checked type="checkbox" (change)="onCheckboxChange($event,negocio)" class="text-blue-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed">
          <input *ngIf="!negocio.intCursoID"   type="checkbox" (change)="onCheckboxChange($event,negocio)" class="text-blue-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed">
        </span>
      </label>
    </div>
  </div>

</header>

<div *ngIf="bCuenta">
  <ng-container *ngFor="let cuenta of cuentas;" >
      <article *ngIf="cuenta.codPais == negocio.codPais && cuenta.codEmpresa == negocio.codEmpresa && cuenta.codNegocio == negocio.codNegocio" class="card mt-4">
        <div class="card-body ">
          <app-cuentas [curso]="curso" [pais]="pais" [empresa]="empresa" [negocio]="negocio" [cuenta]="cuenta" [centroCostos]="centroCostos" [areas]="areas"></app-cuentas>


        </div>
      </article>
  </ng-container>
</div>
