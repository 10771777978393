import { Component, Input, OnInit } from '@angular/core';
import { SegmentacionService } from 'src/app/Services/admin/segmentacion.service';

@Component({
  selector: 'app-puesto-trabajo',
  templateUrl: './puesto-trabajo.component.html',
  styleUrls: ['./puesto-trabajo.component.scss']
})
export class PuestoTrabajoComponent implements OnInit {

  @Input() curso;
  @Input() pais;
  @Input() empresa;
  @Input() negocio;
  @Input() cuenta;
  @Input() puestoTrabajo;

  constructor(
    private segmentacionService: SegmentacionService
  ) { }

  ngOnInit(): void {
  }

  onCheckboxChange(value,obj):void{
    if (value.target.checked) {
      var Entity = {
        intCursoID : this.curso.intCursoID,
        intTipo: 5,
        strSegmentacion: ""+obj.idPuestoTrabajador,
        intAccion: 1
      };
       this.segmentacionService.postSegmentacion(Entity).subscribe(result=>{
         if(result.code==200){

         }
         else{

         }
       },(err)=>{
         console.log(err);
       });

    } else {

      var _Entity = {
        intCursoID : this.curso.intCursoID,
        intTipo: 5,
        strSegmentacion:  ""+obj.idPuestoTrabajador,
        intAccion: 0
      };
       this.segmentacionService.postSegmentacion(_Entity).subscribe(result=>{
         if(result.code==200){

         }
         else{

         }
       },(err)=>{
         console.log(err);
       });

    }

  }
}
