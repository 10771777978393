import { Component, Input, OnInit } from '@angular/core';
import { ParametrosService } from 'src/app/Services/admin/parametros.service';
import { element } from 'protractor';
import { CursosService } from '../../../../../../Services/admin/cursos.service';

import { DialogService } from 'src/app/Services/comun/dialog.service';

@Component({
  selector: 'app-course-edit',
  templateUrl: './course-edit.component.html',
  styleUrls: ['./course-edit.component.scss']
})
export class CourseEditComponent implements OnInit {

  bInformation: boolean;
  bFilesComplementary: boolean;
  bLecciones: boolean;
  bGoals: boolean;
  bStudents: boolean;
  bSegmentation:boolean;
  bObservations:boolean;

  parametros:any[];
  Cursos:any[];

  @Input() data;

  constructor(
    private parametrosService:ParametrosService,
    private cursosService:CursosService,

    private dialogServices: DialogService
  ) {
    this.bInformation = true;
    this.bFilesComplementary = false;
    this.bLecciones = false;
    this.bGoals = false;
    this.bStudents = false;
    this.bSegmentation = false;
    this.bObservations = false;
  }

  ngOnInit(): void {
    this.pageLoad();

    console.log("data-curso",this.data);
    if(this.data.tabData.element){
      this.Cursos = this.data.tabData.element;
    }
  }

  pageLoad():void {
    this.parametrosService.ObtenerParametros().subscribe(result=>{
      this.parametros = result.result;
      console.log("parametros",this.parametros);
    }, (err) =>{
      console.log("error",err);
    });
  }

  showAside(index: number): void {
    switch (index) {
      case 0:
        this.bInformation = true;
        this.bFilesComplementary = false;
        this.bLecciones = false;
        this.bGoals = false;
        this.bStudents = false;
        this.bSegmentation = false;
        this.bObservations = false;
        break;

      case 1:
        this.bInformation = false;
        this.bFilesComplementary = true;
        this.bLecciones = false;
        this.bGoals = false;
        this.bStudents = false;
        this.bSegmentation = false;
        this.bObservations = false;
        break;

      case 2:
        this.bInformation = false;
        this.bFilesComplementary = false;
        this.bLecciones = true;
        this.bGoals = false;
        this.bStudents = false;
        this.bSegmentation = false;
        this.bObservations = false;
        break;
      case 3:
        this.bInformation = false;
        this.bFilesComplementary = false;
        this.bLecciones = false;
        this.bGoals = true;
        this.bStudents = false;
        this.bSegmentation = false;
        this.bObservations = false;
        break;
      case 4:
        this.bInformation = false;
        this.bFilesComplementary = false;
        this.bLecciones = false;
        this.bGoals = false;
        this.bStudents = true;
        this.bSegmentation = false;
        this.bObservations = false;
        break;

      case 5:
        this.bInformation = false;
        this.bFilesComplementary = false;
        this.bLecciones = false;
        this.bGoals = false;
        this.bStudents = false;
        this.bSegmentation = true;
        this.bObservations = false;
        break;

      case 6:
        this.bInformation = false;
        this.bFilesComplementary = false;
        this.bLecciones = false;
        this.bGoals = false;
        this.bStudents = false;
        this.bSegmentation = false;
        this.bObservations = true;
        break;
      default:

        this.bInformation = true;
        this.bFilesComplementary = false;
        this.bLecciones = false;
        this.bGoals = false;
        this.bStudents = false;
        this.bSegmentation = false;
        this.bObservations = false;
        break;
    }
  }
  publicar(){
      this.cursosService.putPublicarCurso(this.Cursos).subscribe(result=>{
          if(result.code==200){

            this.dialogServices.openMsgSuccessDialog("Aviso", "Se publicó correctamente").afterClosed().subscribe(res =>
              {

              }
            );



          }
          else{
            this.dialogServices.openMsgErrorDialog(result.message).afterClosed().subscribe(res =>
              {

              }
            );


          }
      },(err)=>{
        console.log(err);
      });
  }
}
