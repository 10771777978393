import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/Services/comun/dialog.service';


@Component({
  selector: 'app-enrolled-mailbox',
  templateUrl: './enrolled-mailbox.component.html',
  styleUrls: ['./enrolled-mailbox.component.scss']
})
export class EnrolledMailboxComponent implements OnInit {

  mailBoxs:any[];
  studentID:number;
  teacherID:number;

  messageBox:string;

  constructor(
    private dialogSerive: DialogService
  ) { }

  ngOnInit(): void {
  }

  createMailBox():void{
    /*
    const mailBoxModel = {
        'message': this.messageBox,
        'user': {
          'id': this.securityService.GetUserID()
        },
        'course': {
          'id': 4
        }
    }
    this.mailboxService.CreateMailBox(mailBoxModel).subscribe(
      result=>{
          if(result.code==200){
            this.mailBoxs = result.result;
            console.log(this.mailBoxs);
            this.messageBox = '';
            this.getMessageBoxByStudentTeacher();
          }
      }
    );
    */
  }
}
