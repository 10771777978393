import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from 'src/app/system/security.service';

@Component({
  selector: 'app-site-layouts',
  templateUrl: './site-layouts.component.html',
  styleUrls: ['./site-layouts.component.scss']
})
export class SiteLayoutsComponent implements OnInit {

  bopenMenuWeb : boolean;
  bOpenMenuMobile:boolean;

  nombre:string;
  nombres:string;
  apellidoPaterno:string;
  apellidoMaterno:string;
  correo:string;
  constructor(
    private router : Router,
    private securityService: SecurityService
  ) { }

  ngOnInit(): void {
    this.bOpenMenuMobile = false;
    this.nombres = this.securityService.GetNombres() + " " + this.securityService.GetApellidoPaterno() + " " + this.securityService.GetApellidoMaterno();
    this.correo = this.securityService.GetCorreo();
    this.nombre = this.securityService.GetNombres();
    this.apellidoPaterno = this.securityService.GetApellidoPaterno();
    this.apellidoPaterno = this.securityService.GetApellidoMaterno();
  }
  openMenuWeb(){
    this.bopenMenuWeb = !this.bopenMenuWeb;
  }

  openMenuMobile():void{
    this.bOpenMenuMobile = !this.bOpenMenuMobile;
  }
  closeMenuMobile():void{
    this.bOpenMenuMobile = false;
  }
  redirect(item:number):void{
    switch (item) {
      case 1:
        this.bOpenMenuMobile = false;
        this.router.navigate(['/home']);
        break;
      case 2:
        this.bOpenMenuMobile = false;
        this.router.navigate(['/profile']);
        break;
      case 3:
        this.bOpenMenuMobile = false;
        this.router.navigate(['/Learning']);
        break;
      case 4:
        this.bOpenMenuMobile = false;
        this.router.navigate(['/MailBox']);
        break;
      case 5:
        this.bOpenMenuMobile = false;
        this.router.navigate(['/Help']);
        break;
      case 6:
        this.bOpenMenuMobile = false;
        this.router.navigate(['/']);
        break;
      default:
        break;
    }
  }

  closeMenuMobileOut(value):void{
    this.bOpenMenuMobile = value;
  }

}
