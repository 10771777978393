<div class="py-5 px-10" role="alert">
  <div class="flex">
    <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
    <div>
      <p class="font-bold text-xl">¡oh no!</p>
      <p class="font-roboto text-base">{{data.message}}</p>
    </div>

  </div>
  <div class="mx-auto w-full py-2 text-center">
    <button mat-flat-button id="yes-button" [mat-dialog-close]="true" class="btn-rom-magenta cursor-pointer" >
      <span>OK</span>
  </button>
</div>
</div>

