// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /***** INICIO MODO LOCAL *****/
  //BASE_URL_API: 'http://localhost:50853/api',
  //BASE_URL_UPLOAD: 'http://localhost:50853/api/Upload',
  //BASE_URL_PUBLIC: 'http://localhost:50853/recursos/',
  /***** FIN MODO LOCAL *****/
  /***** INICIO MODO PRODUCCIÓN *****/
  BASE_URL_API: 'https://intranet.grupotawa.com/CapacitateRestROMPE/Portal/Api',
  BASE_URL_UPLOAD: 'https://intranet.grupotawa.com/CapacitateRestROMPE/Portal/Api/Upload',
  BASE_URL_PUBLIC: 'https://intranet.grupotawa.com/CapacitateRestROMPE/Portal/recursos/',
  /***** FIN MODO PRODUCCIÓN *****/
  /***** INICIO MODO QA *****/
  //BASE_URL_API: 'http://10.10.1.50:1010/api',
  //BASE_URL_UPLOAD: 'http://10.10.1.50:1010/api/Upload',
  //BASE_URL_PUBLIC: 'http://10.10.1.50:1010/recursos/',
  /***** FIN MODO QA *****/
  //RECAPCHAT_KEY: '6Ld42j0bAAAAAFY8wh6Em2y7wF92IjWevpf0glik',
  RECAPCHAT_KEY: '6LeY1CIhAAAAAON3852kUU4Xh3DrBWMgX4oWVLkb',
  Negocio: '016',
  Cuenta: '048',
  Pais: '155'
  //Pais: '156'
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
