import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ResultModel } from 'src/app/models/result.model';
import { SecurityService } from 'src/app/system/security.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MetasService extends AppService {

  constructor(http: HttpClient, securityService:SecurityService) {
    super(http,securityService);
  }

  getMetas<T>(intCursoID:number):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/metas/${intCursoID}`;
    return this.get(this.endpoint);
  }

  postMetas<T>(datos:any):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/metas`;
    return this.post(this.endpoint,datos);
  }

  putMetas<T>(datos:any):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/metas`;
    return this.put(this.endpoint,datos);
  }
  deleteMetas<T>(intMetasID:number):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/metas/${intMetasID}`;
    return this.delete(this.endpoint);
  }
}
