<div class="card" >
  <div class="card-body">
    <fieldset class="w-full">
      <legend>Búsqueda</legend>
      <div class="card">
        <div class="card-body">

          <form class="w-full" autocomplete="off" [formGroup]="form">
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                  Empresa
                </label>
                <div class="relative">
                  <select formControlName="codEmpresa" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                    <option value="0" >Todos</option>
                    <ng-container  *ngFor="let empresa of empresas">
                      <option *ngIf="empresa.codPais==155" value="{{empresa.codEmpresa}}" >{{empresa.descripcion}}</option>
                    </ng-container>
                  </select>

                </div>
              </div>
              <div class="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                  Unidad de negocio
                </label>
                <div class="relative">
                  <select formControlName="codNegocio" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                    <option value="0" >Todos</option>
                    <ng-container  *ngFor="let negocio of negocios">
                      <option *ngIf="negocio.codEmpresa==this.form.controls['codEmpresa'].value"   value="{{negocio.codNegocio}}" >{{negocio.descripcion}}</option>
                    </ng-container>
                  </select>

                </div>
              </div>
              <div class="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                  &nbsp;
                </label>
                <div class="flex">
                  <div class="w-full  px-3 mb-6 md:mb-0">
                    <button (click)="buscar()" class="font-medium pt-2 pb-2 pl-4 pr-4 rounded text-white btn-sm pull-right" style="background-color: #FF455C !important;" >
                      <i class="fa fa-search" aria-hidden="true"></i>
                      Buscar Curso</button>
                  </div>

                </div>
              </div>
            </div>
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-full px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                  Buscador
                </label>
                <div class="relative">
                   <input formControlName="strTitulo"    type="text" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </fieldset>

    <fieldset class="w-full">
      <legend>Listado</legend>
      <div class="card">
        <div class="card-body">

          <div class="mat-elevation-z8 ">
            <table mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="strEstudiante">
                <th  mat-header-cell *matHeaderCellDef> Nombres y Apellidos </th>
                <td  style="width: 35%" class="px-6 py-4 whitespace-nowrap" mat-cell *matCellDef="let element">
                   <div class="text-sm text-gray-900 "> {{element.nombres}}</div>
                </td>
              </ng-container>

              <!-- Position Column -->
              <ng-container matColumnDef="strDescripcion">
                <th   mat-header-cell *matHeaderCellDef> Datos del empleado </th>
                <td style="width: 35%" class="px-6 py-4 whitespace-nowrap" mat-cell *matCellDef="let element">
                  <div class="flex items-center">
                    <div class="ml-4">
                        <div class="text-sm font-medium text-gray-900">
                          {{element.empresa | slice:0:65}}
                        </div>
                        <div   class="text-sm text-gray-500">
                          {{element.unidaD_NEGOCIO}}
                        </div>
                        <div   class="text-sm text-gray-500">
                          {{element.pais}}
                        </div>
                    </div>
                  </div>
                </td>
              </ng-container>

              <!-- Name Column -->


              <ng-container matColumnDef="opcion">
                <th   mat-header-cell *matHeaderCellDef> Opciones </th>
                <td mat-cell *matCellDef="let element">
                  <button *ngIf="!element.intUsuarioID" (click)="Asignar(element)" type="button" class="font-medium pt-2 pb-2 pl-4 pr-4 rounded  text-white btn-sm pull-right bg-blue-900" >
                    <i class="fa fa-book" aria-hidden="true"></i>
                   Asignar
                  </button>
                  <button *ngIf="element.intUsuarioID" (click)="Asignar(element)" type="button" style="background-color: #FF455C !important;" class="font-medium pt-2 pb-2 pl-4 pr-4 rounded  text-white btn-sm pull-right" >
                    <i class="fa fa-book" aria-hidden="true"></i>
                   Desasignar
                  </button>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator #paginatorDatasource [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
          </div>

        </div>
      </div>
    </fieldset>

  </div>
</div>
