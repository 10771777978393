import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogService } from 'src/app/Services/comun/dialog.service';
import { CursosService } from 'src/app/Services/estudiante/cursos.service';
import { SecurityService } from '../../../../system/security.service';


@Component({
  selector: 'app-course-content-lesson-view',
  templateUrl: './course-content-lesson.component.html',
  styleUrls: ['./course-content-lesson.component.scss']
})
export class CourseContentLessonComponent implements OnInit {

  @Input() leccion;
  @Input() intLeccionIDActual;
  @Input() doPorcentaje;
  @Input() EL_LeccionUsuario_DTO;

  @Output() vistaLeccion = new EventEmitter();
  @Output() LeccionUsuario = new EventEmitter();
  constructor(

    private securityService:SecurityService,
    private cursosService : CursosService,
    private dialogSerive: DialogService
  ) { }

  ngOnInit(): void {


  }


  verLeccion():void{
    this.vistaLeccion.emit(this.leccion);
  }

  terminarLeccion(leccion:any):void {
    var oBE = {
      intLeccionID: +leccion.intLeccionID,
      intUsuarioID: +this.securityService.GetUsuarioID()
    };
    this.cursosService.PostLeccionUsuario(oBE).subscribe(result=>{
      if(result.code==200){

        this.dialogSerive.openMsgSuccessDialog("Aviso","Se aprobó la lección correctamente").afterClosed().subscribe(res=>{
          this.LeccionUsuario.emit(true);
        });

      }
      else{

        this.dialogSerive.openMsgErrorDialog(result.message).afterClosed().subscribe(res=>{
          this.LeccionUsuario.emit(false);
        });

      }
    },(err)=>{
        console.log(err);
    });
  }
}
