import { Component, Input, OnInit } from '@angular/core';
import { SegmentacionService } from 'src/app/Services/admin/segmentacion.service';

@Component({
  selector: 'app-negocios',
  templateUrl: './negocios.component.html',
  styleUrls: ['./negocios.component.scss']
})
export class NegociosComponent implements OnInit {

  @Input() pais;
  @Input() empresa;
  @Input() negocio;
  @Input() cuentas;
  @Input() centroCostos;
  @Input() areas;
  @Input() curso;

  bCuenta:boolean;
  constructor(
    private segmentacionService: SegmentacionService
  ) {
    this.bCuenta = false;
  }

  ngOnInit(): void {
  }

  mostrarCuenta(CodPais:string,codEmpresa,codUnidadNegocio:string){
    this.bCuenta = !this.bCuenta;

    if(this.bCuenta){
      this.getCuenta(CodPais,codEmpresa,codUnidadNegocio);
    }
  }

  onCheckboxChange(value,obj):void{

    if (value.target.checked) {
      var Entity = {
        intCursoID : this.curso.intCursoID,
        intTipo: 3,
        strSegmentacion: obj.codNegocio,
        intAccion: 1
      };
       this.segmentacionService.postSegmentacion(Entity).subscribe(result=>{
         if(result.code==200){

         }
         else{

         }
       },(err)=>{
         console.log(err);
       });

    } else {

      var _Entity = {
        intCursoID : this.curso.intCursoID,
        intTipo: 3,
        strSegmentacion: obj.codNegocio,
        intAccion:0
      };
       this.segmentacionService.postSegmentacion(_Entity).subscribe(result=>{
         if(result.code==200){

         }
         else{

         }
       },(err)=>{
         console.log(err);
       });

    }
  }

  getCuenta(CodPais:string,codEmpresa,codUnidadNegocio:string):void{
    this.segmentacionService.getSegmentacionAppBiCuenta(CodPais,codEmpresa,codUnidadNegocio,this.curso.intCursoID).subscribe(result=>{

      if(result.code==200){
        this.cuentas = result.result;
      }

    }, (err)=>{
      console.log(err);
    });
  }

}
