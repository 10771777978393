  <header>
    <div class="rounded p-3 mb-1 flex justify-between items-center">
      <div>
        <h1 (click)="mostrarNegocio(empresa.codPais,empresa.codEmpresa)" class="cursor-pointer"><strong>Empresa: </strong> {{empresa.descripcion}}</h1>
      </div>
      <div>
        <label class="flex items-center">
          <span class="inline-flex">
            <input *ngIf="empresa.intCursoID" checked type="checkbox"  (change)="onCheckboxChange($event,empresa)"  class="text-blue-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed">
            <input *ngIf="!empresa.intCursoID"   type="checkbox"  (change)="onCheckboxChange($event,empresa)"  class="text-blue-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed">
          </span>
        </label>
      </div>
    </div>


  </header>

  <div *ngIf="bNegocio">
    <ng-container *ngFor="let negocio of negocios;" >
        <article *ngIf="empresa.codEmpresa == negocio.codEmpresa && negocio.codPais == empresa.codPais" class="card mt-4">
          <div class="card-body bg-gray-100">
            <app-negocios [curso]="curso" [pais]="pais" [empresa]="empresa" [negocio]="negocio" [cuentas]="cuentas" [centroCostos]="centroCostos" [areas]="areas"></app-negocios>
          </div>
        </article>
    </ng-container>
  </div>

