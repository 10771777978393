<div class="card" >
  <div class="card-body">
    <fieldset class="w-full">
      <legend>Búsqueda</legend>
      <div class="card">
        <div class="card-body">
          <form class="w-full">
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-full px-3 mb-6 md:mb-0">
                <div class="relative">
                    <input type="text" placeholder="Búsqueda....." class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"  />
                </div>
              </div>




            </div>
          </form>
        </div>
      </div>
    </fieldset>

    <fieldset class="w-full">
      <legend>Listado</legend>
      <div class="card">
        <div class="card-body">

          <div class="mat-elevation-z8 ">
            <table mat-table [dataSource]="dataSource">


              <!-- Name Column -->
              <ng-container matColumnDef="nombre">
                <th style="width: 50%" class="text-left" mat-header-cell *matHeaderCellDef>Nombres&nbsp;y&nbsp;Apellidos </th>
                <td  class="flex px-6 py-4   text-center" mat-cell *matCellDef="let element">
                  <!--
                   <div class="flex-shrink-0 h-10 w-10">
                    <img  class="h-10 w-10 rounded-full object-cover object-center" src="{{element.photo}}" alt="">
                    </div>
                  -->
                  <div class="ml-4">
                    <div class="text-xs font-medium text-gray-900 text-left">
                      {{element.strNombres}}
                    </div>
                    <div class="text-xs font-medium text-gray-900 text-left">
                      {{element.strApellidoPaterno}}   {{element.strApellidoMaterno}}
                    </div>
                  </div>
                </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="correo">
                <th style="width: 40%" class="text-center" mat-header-cell *matHeaderCellDef>Correo&nbsp;Electronico</th>
                <td  class="px-6 py-4 whitespace-nowrap text-center" mat-cell *matCellDef="let element">
                  <div class="text-xs text-gray-900 text-center">

                    {{ element.strCorreo }}

                  </div>

                </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="progreso">
                <th style="width: 20%" mat-header-cell *matHeaderCellDef> Proceso </th>
                <td  mat-cell *matCellDef="let element">  </td>
              </ng-container>

              <ng-container matColumnDef="opcion">
                <th style="width: 10%;" mat-header-cell *matHeaderCellDef> Detalle </th>
                <td mat-cell *matCellDef="let element">
                    <i (click)="enrolledShow(element)" class="fa fa-bars cursor-pointer text-sm pl-2 text-blue-700" aria-hidden="true"></i>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
          </div>

        </div>
      </div>
    </fieldset>

  </div>
</div>
