<div class="min-h-screen bg-gray-100">
  <div class="container py-8 grid grid-cols-7 gap-6">
    <aside class="col-span-2">
      <h1 class="font-bold text-lg mb-4">Edición del curso</h1>
      <ul class="text-sm text-gray-600">
          <li (click)="showAside(0)" [ngClass]="bInformation?'cursor-pointer leading-7 mb-1 border-l-4 border-indigo-400  pl-2':'cursor-pointer leading-7 mb-1 border-l-4 pl-2'">
              <a>Información del Estudiante</a>
          </li>
          <!--
          <li (click)="showAside(1)" [ngClass]="bprogress?'cursor-pointer leading-7 mb-1 border-l-4 border-indigo-400  pl-2':'cursor-pointer leading-7 mb-1 border-l-4 pl-2'">
            <a>Avances, tareas y evaluaciones</a>
          </li>
          <li (click)="showAside(2)" [ngClass]="bMailbox?'cursor-pointer leading-7 mb-1 border-l-4 border-indigo-400  pl-2':'cursor-pointer leading-7 mb-1 border-l-4 pl-2'">
              <a>Buzón electronico</a>
          </li>
          -->

      </ul>
    </aside>


    <div class="col-span-5 card">
      <main [ngClass]="bInformation?'card-body text-gray-600':'hidden'">
          <app-enrolled-detail [students]="students"></app-enrolled-detail>
      </main>

      <main [ngClass]="bprogress?'card-body text-gray-600':'hidden'" >
          <app-enrolled-progress [students]="students" ></app-enrolled-progress>
      </main>
      <main [ngClass]="bMailbox?'card-body text-gray-600':'hidden'" >
        <app-enrolled-mailbox></app-enrolled-mailbox>
    </main>
    </div>
  </div>
  </div>
