import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { HttpClient } from '@angular/common/http';
import { SecurityService } from 'src/app/system/security.service';
import { Observable } from 'rxjs';
import { ResultModel } from 'src/app/models/result.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComunService extends AppService {

  constructor(http: HttpClient, securityService:SecurityService) {
    super(http,securityService);
  }

  getComentariosNoLeidos<T>(strUsuarioID:string):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/estudiante/home/preguntas/${strUsuarioID}`;
    return this.get(this.endpoint);
  }

  getComentariosNoLeidosProfesor<T>(strProfesorID:string):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/estudiante/home/preguntas/profesor/${strProfesorID}`;
    return this.get(this.endpoint);
  }
}
