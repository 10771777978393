import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Tab } from 'src/app/tabconfig/tab.model';
import { TabService } from 'src/app/tabconfig/tab.service';
import { CourseCreateComponent } from '../course-create/course-create.component';
import { CourseEditComponent } from '../course-edit/course-edit.component';
import { ParametrosService } from '../../../../../../Services/admin/parametros.service';
import { forkJoin } from 'rxjs';
import { CursosService } from 'src/app/Services/admin/cursos.service';
import { MatSort } from '@angular/material/sort';
import { SecurityService } from 'src/app/system/security.service';
import { element } from 'protractor';
import { DialogService } from 'src/app/Services/comun/dialog.service';

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss']
})
export class CourseListComponent implements OnInit {

  displayedColumns: string[] = ['strDescripcion', 'intEstudiantes','intEvaloraciones','strEstado','opcion'];
  dataSource = new MatTableDataSource<any[]>();
  @ViewChild(MatSort, {static:true}) sort:MatSort;

  @ViewChild('paginatorDatasource', {read:MatPaginator, static:true}) paginator: MatPaginator;

  form: FormGroup;
  courses: any[];
  parametros: any[];

  rating: number;
  BASE_URL_PUBLIC:string;

  constructor(
    private tabService: TabService,
    private parametrosService:ParametrosService,
    private formBuilder: FormBuilder,
    private cursoService: CursosService,
    private securityService: SecurityService,
    private dialogServices: DialogService
  ) {
    this.BASE_URL_PUBLIC = environment.BASE_URL_PUBLIC;
   }

  ngOnInit(): void {
    this.iniForm();
    this.PageLoad();
  }

  iniForm():void {
    this.form = this.formBuilder.group({
      intCategoriaID : [0,Validators.required],
      intEstadoID:[0,Validators.required],
      strTitulo:[null,Validators.required]
    });
  }

  PageLoad(): void {

    forkJoin([
      this.parametrosService.ObtenerParametros()
    ]).subscribe((result:any)=>{


      this.parametros = result[0].result;
      console.log("parameters",this.parametros);
    }, (err) =>{
      console.log(err);
    });

  }

  buscar(){

    const intCategoriaID = +this.form.controls["intCategoriaID"].value;
    const intEstadoID = +this.form.controls["intEstadoID"].value;
    const strFilter = this.form.controls["strTitulo"].value;
    const intProfesorID = +this.securityService.GetProfesorID();
    debugger;
    this.cursoService.getCurso(intCategoriaID,intEstadoID,intProfesorID).subscribe(result=>{
      
      console.log(result);

      if(result.code==200){

        var filtros;
       if(strFilter==null){
          filtros = result.result;
       }
       else{
          filtros = result.result.filter(item => item.strTitulo.toLowerCase().includes(strFilter.toLowerCase()));
       }

       this.dataSource = new MatTableDataSource<any[]>(filtros);
       this.dataSource.sort = this.sort;
       this.dataSource.paginator = this.paginator;
      //  this.dataSource.filter = strFilter;
        console.log("dataSource",this.dataSource);
      }

    });
  }

  edit(element:any):void{

    this.tabService.addTab(new Tab(CourseEditComponent, "Editar Curso", { parent: 'CoursesListComponent', element: element }));
  }
  create():void{
    this.tabService.addTab(new Tab(CourseCreateComponent, "Crear Curso", { parent: 'CoursesListComponent', element: null }));
  }
  eliminar(element:any):void {
    this.dialogServices.openConfirmDialog("¿Seguro que desea eliminar este curso?")
    .afterClosed().subscribe(res=>{

      if(res){

        this.cursoService.deleteCurso(element.intCursoID).subscribe(result=>{

          if(result.code==200){
            this.dialogServices.openMsgSuccessDialog("Aviso","Se eliminó correctamente").afterClosed().subscribe(resr=>{
              if(resr){
                this.buscar();
              }
            })
          }
          else{
            this.dialogServices.openMsgErrorDialog(result.message);
          }
        },
        (err)=>{
          console.log(err);
        });
      }
    });
  }
}
