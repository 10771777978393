import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CursosService } from 'src/app/Services/admin/cursos.service';

import { SecurityService } from 'src/app/system/security.service';
import { DialogService } from 'src/app/Services/comun/dialog.service';

@Component({
  selector: 'app-course-modify',
  templateUrl: './course-modify.component.html',
  styleUrls: ['./course-modify.component.scss']
})
export class CourseModifyComponent implements OnInit {

  @Input() parametros;
  @Input() Cursos;

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private cursoService: CursosService,

    private securityService: SecurityService,
    private dialogServices: DialogService
  ) { }

  ngOnInit(): void {
    console.log("parametros - CourseModifyComponent",this.parametros);
    this.iniForm();

     this.form.controls["intCursoID"].setValue(this.Cursos.intCursoID);
     this.form.controls["strTitulo"].setValue(this.Cursos.strTitulo);
     this.form.controls["strSubTitulo"].setValue(this.Cursos.strSubTitulo);
     this.form.controls["strDescripcion"].setValue(this.Cursos.strDescripcion);
     this.form.controls["intNivelesID"].setValue(this.Cursos.intNivelesID);
     this.form.controls["intCategoriaID"].setValue(this.Cursos.intCategoriaID);
     this.form.controls["intDuracionValue"].setValue(this.Cursos.intDuracionValue);
     this.form.controls["intDuracionID"].setValue(this.Cursos.intDuracionID);
     this.form.controls["intCostoID"].setValue(this.Cursos.intCostoID);
     this.form.controls["strSlug"].setValue(this.Cursos.strSlug);
     this.form.controls["intEstadoID"].setValue(this.Cursos.intEstadoID);


  }

  iniForm():void {
    this.form = this.formBuilder.group({
      intCursoID : [0],
      strTitulo : [null,Validators.required],
      strSlug : [null],
      strSubTitulo : [null,Validators.required],
      strDescripcion : [null,Validators.required],
      intNivelesID : [null,Validators.required],
      intCategoriaID : [null,Validators.required],
      intDuracionValue : [null,Validators.required],
      intDuracionID : [null,Validators.required],
      intCostoID:[null,Validators.required],
      intEstadoID:[null]
    });
  }

  ActualizarCurso():void {
    var Entity = {
      intCursoID : +this.form.controls["intCursoID"].value,
      strTitulo : this.form.controls["strTitulo"].value,
      strSubTitulo : this.form.controls["strSubTitulo"].value,
      strDescripcion : this.form.controls["strDescripcion"].value,
      intNivelesID : +this.form.controls["intNivelesID"].value,
      intCategoriaID : +this.form.controls["intCategoriaID"].value,
      intDuracionValue : +this.form.controls["intDuracionValue"].value,
      intDuracionID : +this.form.controls["intDuracionID"].value,
      intCostoID : +this.form.controls["intCostoID"].value,
      strSlug : this.form.controls["strSlug"].value,
      intEstadoID : +this.form.controls["intEstadoID"].value,
      intProfesorID: +this.securityService.GetProfesorID()
    };
    this.cursoService.putCurso(Entity).subscribe(result=>{
      console.log(result);
      if(result.code==200){
        this.dialogServices.openMsgSuccessDialog("Aviso", "Se actualizó correctamente").afterClosed().subscribe(res =>
          {

          }
        );


      }
      else{

        this.dialogServices.openMsgErrorDialog(result.message).afterClosed().subscribe(res =>
          {

          }
        );


      }
    }, (err)=>{
      console.log(err);
      this.dialogServices.openMsgErrorDialog("Hubo un error al intentar actualizar el curso").afterClosed().subscribe(res =>
        {

        }
      );

    });

  }
}
