import { Component, OnInit } from '@angular/core';
import { ParametrosService } from 'src/app/Services/admin/parametros.service';
import { CursosService } from 'src/app/Services/estudiante/cursos.service';
import { HomeService } from 'src/app/Services/estudiante/home.service';
import { environment } from 'src/environments/environment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SecurityService } from 'src/app/system/security.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  cursos:any[];
  cursos_inicial:any[];
  BASE_URL_PUBLIC:string;
  parametros:any[];
  bopenFiltro:boolean;

  marquesinas:any[];

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    margin: 30,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true,
    autoplay:false
  }

  customOptionsBanner: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
    margin: 0,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true,
    autoplay:true
  }

  constructor(
    private homeService : HomeService,
    private utilsService : UtilsService,
    private parametrosService:ParametrosService,
    private securityService:SecurityService
  ) {
    this.BASE_URL_PUBLIC = environment.BASE_URL_PUBLIC;
    this.bopenFiltro = false;
   }

  ngOnInit(): void {
    this.getCursos();
    this.getParametros();
  }

  getCursos():void {
    //const intUsuarioID = this.securityService.GetUsuarioID();
    const intUsuarioID = this.utilsService.convertBase64Url(this.securityService.GetUsuarioID());
    //this.homeService.getCurso(+intUsuarioID).subscribe(result=>{
    this.homeService.getCurso(intUsuarioID).subscribe(result=>{
      debugger;
      console.log('ddd',result);
      if(result.code==200){
        //this.cursos = result.result;

        //this.cursos =  this.groupBy(result.result,pet => pet.intCategoriaID) ;
        this.cursos_inicial = result.result;
        this.cursos = result.result.reduce((r,{strCategoria})=>{
          if(!r.some(o=>o.strCategoria==strCategoria)){
            r.push({
                strCategoria,
                groupItem:result.result.filter(v=>v.strCategoria==strCategoria),
                group : this.contarCurso(result.result.filter(v=>v.strCategoria==strCategoria).length) // (result.result.filter(v=>v.strCategoria==strCategoria).length>0? 4 - result.result.filter(v=>v.strCategoria==strCategoria).length : '4' ));
            });
        }
        return r;
        },[]);

        console.log(this.cursos);

       //  console.log(this.groupBy(result.result,pet => pet.intCategoriaID));

      }
    },(err)=>{
      console.log(err);
    });
  }

  getParametros():void {
    this.parametrosService.ObtenerParametros().subscribe(result=>{
      if(result.code==200){
        this.parametros = result.result;
        console.log(this.parametros);

        this.marquesinas = this.parametros.filter(x=>x.intGrupo==1500);

      }
    },(err)=>{
      console.log(err);
    });
  }
  Redireccion(url:string):void {
    //window.location.href = url;

    window.open(url,'_blank');
  }

  MostrarFiltros():void{
    this.bopenFiltro = !this.bopenFiltro;
  }

  contarCurso(array:number){
  let _contar = [];
  let resto = 4 - array;
  if(resto>0){
    for (let index = 0; index < resto; index++) {
      _contar.push(1);
    }
  }
  return _contar;
  }

  filtroCurso(value:string):void {
    if(this.cursos_inicial!=null){

      let filtro = this.cursos_inicial.filter(item=>item.strTitulo.toLowerCase().includes(value.toLowerCase()));

      this.cursos = filtro.reduce((r,{strCategoria})=>{
        if(!r.some(o=>o.strCategoria==strCategoria)){
          r.push({
              strCategoria,
              groupItem:filtro.filter(v=>v.strCategoria==strCategoria),
              group : this.contarCurso(filtro.filter(v=>v.strCategoria==strCategoria).length) // (result.result.filter(v=>v.strCategoria==strCategoria).length>0? 4 - result.result.filter(v=>v.strCategoria==strCategoria).length : '4' ));
          });
      }
      return r;
      },[]);

    }
  }






}
