import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from 'src/app/Services/login/login.service';
import { SecurityService } from 'src/app/system/security.service';
import { FormGroup } from '@angular/forms';
import { DialogService } from 'src/app/Services/comun/dialog.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  recapchat:string;
  recapchat_key:string;
  constructor(
    private router: Router,
    private securityService: SecurityService,
    private loginService:LoginService,

    private dialogServices: DialogService
  ) {

    this.recapchat_key = environment.RECAPCHAT_KEY;
    console.log(this.recapchat_key);
  }

  ngOnInit(): void {
    this.securityService.ResetAuthData();
  }

  resolved(captchaResponse: string) {
    this.recapchat = captchaResponse;
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
  errored() {
    console.warn(`reCAPTCHA error encountered`);
  }

  login(username:string,password:string):void {
    var oBE = {
      "USUARIO" :username,
      "CLAVE":password,
      "COD_NEGOCIO":environment.Negocio,
      "COD_CUENTA":environment.Cuenta,
      "COD_PAIS":environment.Pais
    };    

    if(this.recapchat==null){
       this.dialogServices.openMsgErrorDialog("¡Debes validar que no eres un robot!");
       return;
    }

    this.loginService.Login(oBE).subscribe(result=>{
      console.log(result);
      if(result.code==200){
       const  idusuario = result.obj.idusuario; //": 5159,
       const  token = result.resToken; //": "123",
       const  nombres = result.obj.nombres; //": "HUGO FRANCE",
       const  apellidopaterno = result.obj.apellidopaterno; //": "VILCHEZ",
       const  apellidomaterno = result.obj.apellidomaterno; //": "ROSALES",
       const  correo = result.obj.correo; //": "Hugo.vilchez@grupotawa.com",
       const  usuario = result.obj.usuario; //": "71724167",
       const  coD_PAIS = result.obj.coD_PAIS; //": "156",
       const  coD_NEGOCIO = result.obj.coD_NEGOCIO; //": "015",
       const  coD_CUENTA = result.obj.coD_CUENTA; //": "047",
       const  eS_ADMIN = result.obj.eS_ADMIN; //": "1",
       const idjerarquia = result.obj.idjerarquia;

       let intProfesorID = result.data.intProfesorID;
       let intAdministradorID = result.data.intAdministradorID;

       /*
       if(result.result!=null){
         if(result.result.length>0){
           result.result.forEach(element => {
            intProfesorID = element.intProfesorID;
            intAdministradorID = element.intAdministradorID;
           });
         }
       }
       */

        this.securityService.SetAuthData(
          token,
          idusuario,
          usuario,
          '',
          intProfesorID,
          eS_ADMIN,
          nombres,
          apellidopaterno,
          apellidomaterno,
          correo,
          coD_PAIS,
          coD_NEGOCIO,
          coD_CUENTA,
          intAdministradorID,
          idjerarquia
          );

        this.dialogServices.openMsgSuccessDialog("Bienvenido", "¿Qué vas aprender hoy?").afterClosed().subscribe(res =>
          {
            if(res){
              this.router.navigate(['/home']);
            }
          }
        );

         // this.toastr.success("Se autentico correctamente","AVISO");
      }
      else{
         this.dialogServices.openMsgErrorDialog("Usuario y/o Contraseña inválidos");
      }
    },
      (err)=>{
      console.log(err);
    });




  }

}
