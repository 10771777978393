import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ResultModel } from 'src/app/models/result.model';
import { SecurityService } from 'src/app/system/security.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParametrosService extends AppService {

  constructor(http: HttpClient, securityService:SecurityService) {
    super(http,securityService);
  }

  ObtenerParametros<T>():Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/parametros`;
    return this.get(this.endpoint);
  }
}
