import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'src/app/Services/comun/dialog.service';


@Component({
  selector: 'app-enrolled-show',
  templateUrl: './enrolled-show.component.html',
  styleUrls: ['./enrolled-show.component.scss']
})
export class EnrolledShowComponent implements OnInit {

  @Input() data;


  bInformation: boolean;
  bprogress: boolean;
  bMailbox: boolean;

  students:any[];

  constructor(
    private dialogSerive: DialogService
  ) {
    this.bInformation = true;
    this.bprogress = false;
    this.bMailbox = false;
   }

  ngOnInit(): void {
    if(this.data.tabData.element){
      this.students = this.data.tabData.element;
    }
  }

  showAside(index: number): void {
    switch (index) {
      case 0:
        this.bInformation = true;
        this.bprogress = false;
        this.bMailbox = false;

        break;

      case 1:
        this.bInformation = false;
        this.bprogress = true;
        this.bMailbox = false;

        break;

      case 2:
        this.bInformation = false;
        this.bprogress = false;
        this.bMailbox = true;

        break;

      default:

        this.bInformation = true;
        this.bprogress = false;
        this.bMailbox = false;


        break;
    }
  }
}
