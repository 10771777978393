import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReportesService } from 'src/app/Services/admin/reportes.service';
import { SegmentacionService } from 'src/app/Services/admin/segmentacion.service';
import { ExcelJSService } from 'src/app/Services/comun/excel-js.service';
import { SecurityService } from 'src/app/system/security.service';

@Component({
  selector: 'app-curso',
  templateUrl: './curso.component.html',
  styleUrls: ['./curso.component.scss']
})
export class CursoComponent implements OnInit {

  form: FormGroup;
  parametros:any[];

  segmentaciones:any[];
  paises:any[];
  empresas:any[];
  negocios:any[];
  cuentas:any[];
  centroCostos:any[];
  areas:any[];
  puestoTrabajadores: any[];

  constructor(
    private formBuilder: FormBuilder,
    private reporteService:ReportesService,
    private exceljsService:ExcelJSService,
    private segmentacionService : SegmentacionService,
    private securityServices : SecurityService
  ) { }

  ngOnInit(): void {
    this.iniForm();
    this.getApp_BI();
  }

  iniForm():void {
    this.form = this.formBuilder.group({
      codEmpresa : ['00',Validators.required],
      codNegocio:['000',Validators.required],
      puestoTrabajador:[0,Validators.required],
      estadoTrabajador:['',Validators.required]

    });
  }

  getApp_BI():void{

    this.segmentacionService.getSegmentacionAppBi().subscribe(result=>{
       if(result.code==200){
        console.log(result);
        this.paises = result.obj.pais;
        this.empresas = result.obj.empresas.filter(x=>x.codPais=='155');
        this.negocios= result.obj.negocio.filter(x=>x.codPais=='155');
        this.cuentas= result.obj.cuenta;
        this.centroCostos= result.obj.centroCosto;
        this.areas= result.obj.area;
        this.areas= result.obj.area;
        this.puestoTrabajadores = result.obj.tipoPuesto;
       }
    },(err)=>{
      console.log(err);
    });
  }

  exportar():void{
    var oBE = {
      CODEMPRESA : this.form.controls["codEmpresa"].value,
      CODUNIDADNEGOCIO : this.form.controls["codNegocio"].value,
      IDPUESTOTRABAJADOR : +this.form.controls["puestoTrabajador"].value,
      FLAG: this.form.controls["estadoTrabajador"].value

    };
    this.reporteService.cursos(oBE).subscribe(resul=>{
      console.log(resul);
      if(resul.code==200){
        const FechaActual = new Date();
        const AnioActual = FechaActual.getFullYear();
        const MesActual = (FechaActual.getMonth()<=9? '0'+ (FechaActual.getMonth()+1): FechaActual.getMonth() + 1); //FechaActual.getMonth() + 1;
        const DiaActual = (FechaActual.getDate()<=9? '0'+FechaActual.getDate(): FechaActual.getDate());
        const HoraActual = (FechaActual.getHours()<=9? '0'+FechaActual.getHours(): FechaActual.getHours()); //FechaActual.getHours();
        const MinutoActual = (FechaActual.getMinutes()<=9? '0'+FechaActual.getMinutes(): FechaActual.getMinutes()); //FechaActual.getMinutes();
        const SegundosActual = (FechaActual.getSeconds()<=9? '0'+FechaActual.getSeconds(): FechaActual.getSeconds()); //FechaActual.getMinutes(); //FechaActual.getSeconds();


        const Archivo = "ReporteCursos_"+ AnioActual+MesActual+DiaActual+"T"+HoraActual+MinutoActual+SegundosActual;
        const Sheet = "Cursos";


        const header = ["DNI","NOMBRES Y APELLIDOS","UNIDAD DE NEGOCIO","PUESTO DE TRABAJO","EMPRESA","PAIS","NEGOCIO","CUENTA","CATEGORIA","TITULO","LECCION","STATUS","CALIFICACIÓN","FECHA DE FINALIZACION","ESTADOALUMNO"];
        let body = [];
        for (let index = 0; index < resul.result.length; index++) {
          const datos = resul.result[index];

          var DNI = datos.dni;
          var NOMBRES = datos.nombres;
          var UNIDAD_NEGOCIO = datos.unidaD_NEGOCIO;
          var PUESTO = datos.puestotrabajo;
          var EMPRESA = datos.empresa;
          var PAIS = datos.pais;
          var NEGOCIO = datos.negocio;
          var CUENTA = datos.cuenta;
          var CATEGORIA = datos.categoria;
          var TITULO = datos.titulo;
          var LECCION = datos.leccion;
          var STATUS = datos.estado;
          var CALIFICACION = datos.calificacion;
          var FECHA_FINALIZACION = datos.fechA_FINALIZACION;
          var ESTADOALUMNO = datos.estadoalumno;

          var datoarray = [DNI,NOMBRES,UNIDAD_NEGOCIO,PUESTO,EMPRESA,PAIS,NEGOCIO,CUENTA,CATEGORIA,TITULO,LECCION,STATUS,CALIFICACION,FECHA_FINALIZACION,ESTADOALUMNO]
          body.push(datoarray)

        }


        this.exceljsService.DescargarExcelGenerico(Archivo,Sheet,header,body);
      }


    },(err)=>{
      console.log(err);
    });
  }

}
