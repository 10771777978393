<header>
  <div class="rounded p-3 mb-1 flex justify-between items-center">
    <div>
      <h1 (click)="mostrarCentroCosto(cuenta.codPais,cuenta.codEmpresa,cuenta.codNegocio,cuenta.codCuenta)" class="cursor-pointer"><strong>Cuenta: </strong> {{cuenta.descripcion}}</h1>
    </div>
    <div>
      <label class="flex items-center">
        <span class="inline-flex">
          <input *ngIf="cuenta.intCursoID" checked type="checkbox" type="checkbox" (change)="onCheckboxChange($event,cuenta)" class="text-blue-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed">
          <input *ngIf="!cuenta.intCursoID" type="checkbox" type="checkbox" (change)="onCheckboxChange($event,cuenta)" class="text-blue-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed">
        </span>
      </label>
    </div>
  </div>


</header>

<div *ngIf="bCentroCosto">
  <ng-container *ngFor="let puestoTrabajo of puestoTrabajos;" >
      <article *ngIf="puestoTrabajo.codPais == cuenta.codPais && puestoTrabajo.codEmpresa == cuenta.codEmpresa && puestoTrabajo.codNegocio == cuenta.codNegocio && puestoTrabajo.codCuenta == cuenta.codCuenta" class="card mt-4">
        <div class="card-body bg-gray-100">
          <app-puesto-trabajo [curso]="curso" [pais]="pais" [empresa]="empresa" [negocio]="negocio" [cuenta]="cuenta" [puestoTrabajo]="puestoTrabajo"></app-puesto-trabajo>
        </div>
      </article>
  </ng-container>
</div>
