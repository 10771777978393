import { Component, Input, OnInit } from '@angular/core';
import { SeccionService } from 'src/app/Services/admin/seccion.service';
import { DialogService } from 'src/app/Services/comun/dialog.service';


@Component({
  selector: 'app-enrolled-progress',
  templateUrl: './enrolled-progress.component.html',
  styleUrls: ['./enrolled-progress.component.scss']
})
export class EnrolledProgressComponent implements OnInit {

  @Input() students;

  secciones:any[];
  constructor(
    private seccionService: SeccionService,
    private dialogSerive: DialogService
  ) { }

  ngOnInit(): void {
    this.getSeccion();
  }

  getSeccion(){
    this.seccionService.getSeccion(this.students.intCursoID).subscribe(result=>{
      if(result.code==200){
        this.secciones = result.result;
        console.log("this.secciones",this.secciones);
      }
    },(err)=>{
      console.log(err);
    });
  }
}
