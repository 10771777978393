import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-course-content-section',
  templateUrl: './course-content-section.component.html',
  styleUrls: ['./course-content-section.component.scss'],
})
export class CourseContentSectionComponent implements OnInit {
  @Input() seccion;
  @Input() leccionActual;
  @Input() intLeccionIDActual;
  @Input() item;
  @Input() doPorcentaje;
  @Input() EL_LeccionUsuario_DTO;

  @Output() vistaLeccion = new EventEmitter();
  @Output() LeccionUsuario = new EventEmitter();

  lecciones: any[];
  bOpenLesson: boolean;
  leccionSeleccionadaIndex: number | null = null;

  intTotalLeccion: number = 0;
  constructor() {
    this.intTotalLeccion = 0;
  }

  ngOnInit(): void {
    this.intTotalLeccion = 0;
    this.bOpenLesson = false;
    this.lecciones = this.seccion.eL_Leccion_DTO;

    if (this.leccionActual.intSeccionID == this.seccion.intSeccionID) {
      this.bOpenLesson = true;
      // primera leccion seleccionada
      this.leccionSeleccionadaIndex = 0;
    }

    if (this.doPorcentaje == 100) {
      this.bOpenLesson = false;
    }

    // Para verificar si la leccion actual esta dentro de la seccion
    const leccionEnSeccion = this.seccion.eL_Leccion_DTO.findIndex(
      (leccion) => leccion.intLeccionID === this.leccionActual.intLeccionID
    );

    if (leccionEnSeccion >= 0) {
      this.leccionSeleccionadaIndex = leccionEnSeccion;
    }
  }

  OpenLesson() {
    // this.intTotalLeccion = 0;
    this.bOpenLesson = !this.bOpenLesson;
  }

  verLeccion(leccion: any) {
    this.vistaLeccion.emit(leccion);
  }

  contarLeccion() {
    this.intTotalLeccion = this.intTotalLeccion + 1;
  }
  AsignarLeccionUsuario(value: boolean): void {
    this.LeccionUsuario.emit(value);
  }


  // funcion click
  seleccionarLeccion(index: number): void {
    this.leccionSeleccionadaIndex = index;
  }
}
