import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ResultModel } from 'src/app/models/result.model';
import { SecurityService } from 'src/app/system/security.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArchivoComplementarioService extends AppService {

  constructor(http: HttpClient, securityService:SecurityService) {
    super(http,securityService);
  }

  listar<T>(intCursoID:number):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/archivo-complementario/${intCursoID}`;
    return this.get(this.endpoint);
  }

  insertar<T>(datos:any):Observable<ResultModel>{
    debugger;
    this.endpoint = `${environment.BASE_URL_API}/admin/archivo-complementario`;
    return this.post(this.endpoint,datos);
  }
  Eliminar<T>(intArchivoComplementarioID:number):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/archivo-complementario/${intArchivoComplementarioID}`;
    return this.delete(this.endpoint);
  }

}
