import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { SiteLayoutsComponent } from './components/layouts/site-layouts/site-layouts.component';
import { HomeComponent } from './components/pages/site/home/home.component';
import { CourseComponent } from './components/pages/site/course/course.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminLayoutsComponent } from './components/layouts/admin-layouts/admin-layouts.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { TabService } from './tabconfig/tab.service';

import {BrowserAnimationsModule, NoopAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import { CourseContentComponent } from './components/pages/students/course-content/course-content.component';
import { CourseContentSectionComponent } from './components/pages/students/course-content-section/course-content-section.component';
import { LoginComponent } from './components/pages/login/login.component';

import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { Ng2SearchPipeModule } from 'ng2-search-filter';


import {MatDialogModule} from '@angular/material/dialog';

import { CourseContentLessonComponent } from './components/pages/students/course-content-lesson/course-content-lesson.component';
import { CommentsComponent } from './components/pages/students/comments/comments.component';
import { RatingComponent } from './components/pages/students/rating/rating.component';
import { NavbarMobileComponent } from './components/shared/navbar-mobile/navbar-mobile.component';


import { CarouselModule } from 'ngx-owl-carousel-o';
import { DialogConfirmComponent } from './components/shared/dialog-confirm/dialog-confirm.component';
import { LoadingComponent } from './components/shared/loading/loading.component';
import { MsgErrorComponent } from './components/shared/msg-error/msg-error.component';
import { MsgSuccessComponent } from './components/shared/msg-success/msg-success.component';
import { SecurityService } from './system/security.service';
import { JwtInterceptor } from './system/jwt.interceptor';

import {MatIconModule} from '@angular/material/icon';
import { NgxSpinnerModule } from "ngx-spinner";

import { RecaptchaModule } from "ng-recaptcha";
import { ErrorsComponent } from './components/pages/errors/errors.component';

@NgModule({
  declarations: [
    AppComponent,
    SiteLayoutsComponent,
    HomeComponent,
    CourseComponent,
    AdminLayoutsComponent,
    NavbarComponent,
    SidebarComponent,
    CourseContentComponent,
    CourseContentSectionComponent,
    CourseContentLessonComponent,
    CommentsComponent,
    LoginComponent,
    RatingComponent,
    NavbarMobileComponent,
    DialogConfirmComponent,
    LoadingComponent,
    MsgErrorComponent,
    MsgSuccessComponent,
    ErrorsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,

    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,

    NoopAnimationsModule,
    Ng2SearchPipeModule,
    MatDialogModule,
    CarouselModule,
    MatIconModule,
    NgxSpinnerModule,
    RecaptchaModule
  ],
  providers: [
    TabService,
    SecurityService,
    [{provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true}],
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
