<div class="card" >
  <div class="card-body">
    <fieldset class="w-full">
      <legend style="color: aliceblue;">Búsqueda</legend>
      <div class="card">
        <div class="card-body">

          <form class="w-full" autocomplete="off" [formGroup]="form">
            <div class="flex flex-wrap -mx-3 mb-2">
              <div class="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                  Empresa
                </label>
                <div class="relative">
                  <select formControlName="codEmpresa" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                    <option value="00" >Todos</option>
                    <ng-container  *ngFor="let empresa of empresas">
                      <option *ngIf="empresa.codPais==155" value="{{empresa.codEmpresa}}" >{{empresa.descripcion}}</option>
                    </ng-container>
                  </select>

                </div>
              </div>
              <div class="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                  Unidad de Negocio
                </label>
                <div class="relative">
                  <select formControlName="codNegocio" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                    <option value="000" >Todos</option>
                    <ng-container  *ngFor="let negocio of negocios">
                      <option *ngIf="negocio.codEmpresa==this.form.controls['codEmpresa'].value"   value="{{negocio.codNegocio}}" >{{negocio.descripcion}}</option>
                    </ng-container>
                  </select>

                </div>
              </div>
              <div class="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-3 mb-6 md:mb-0">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                  Puesto de trabajo
                </label>
                <div class="relative">
                  <select formControlName="puestoTrabajador" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                    <option value="0" >Todos</option>
                    <ng-container  *ngFor="let puestoTrabajador of puestoTrabajadores">
                      <option  *ngIf="puestoTrabajador.codEmpresa==this.form.controls['codEmpresa'].value  && puestoTrabajador.codNegocio==this.form.controls['codNegocio'].value"  value="{{puestoTrabajador.idPuestoTrabajador}}" >{{puestoTrabajador.puestoTrabajador}}</option>
                    </ng-container>
                  </select>

                </div>
              </div>
              <div class="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 px-3 mb-6 md:mb-0">
                <!-- Cuarto filtro - Estado -->
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                  Estado
                </label>
                <div class="relative">
                  <!-- Reemplaza el siguiente bloque de código con tu lógica para el filtro de Estado -->

                  <select formControlName="estadoTrabajador" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                    <option value="">Todos</option>
                    <option value="A">Activo</option>
                    <option value="I">Inactivo</option>
                    <!-- Agrega más opciones según sea necesario -->
                  </select>
                </div>
              </div>
              <div class="w-full sm:w-full  px-3 mb-6 md:mb-0">
                <div class="flex justify-items-end">
                  <div class="w-full   mb-6 md:mb-0">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                      &nbsp;
                    </label>
                    <button (click)="exportar()" type="button" class="font-medium pt-2 pb-2 pl-4 pr-4 rounded  text-white btn-sm pull-right bg-blue-900" >
                      <i class="fa fa-book" aria-hidden="true"></i>
                      Exportar</button>

                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </fieldset>
  </div>
</div>
