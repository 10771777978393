import { Injectable } from '@angular/core';
import { Tab } from './tab.model';
import {BehaviorSubject} from 'rxjs';


import { HomeComponent } from '../components/pages/admin/home/home.component';


@Injectable()

export class TabService{
    public tabs: Tab[] = [
        new Tab(HomeComponent, "Inicio", { parent: "AppComponent" })
    ];

    public tabSub = new BehaviorSubject<Tab[]>(this.tabs);

    public removeTabAll(){
      if (this.tabs.length > 0) {
         this.tabs.splice(1, this.tabs.length);
         this.tabSub.next(this.tabs);
      }
    }

    public removeTab(index: number) {

        this.tabs.splice(index, 1);
        if (this.tabs.length > 0) {
          this.tabs[this.tabs.length - 1].active = true;
        }
        this.tabSub.next(this.tabs);
    }

    public addTab(tab: Tab) {
        for (let i = 0; i < this.tabs.length; i++) {
          if (this.tabs[i].active === true) {
            this.tabs[i].active = false;
          }
        }
        tab.id = this.tabs.length + 1;
        tab.active = true;
        this.tabs.push(tab);
        setTimeout(()=> {
          this.tabSub.next(this.tabs);
        }, 1);
    }
}
