

<div>
  <section>
    <div class="flex justify-between items-center">
      <div>
        <h1 class="text-2xl font-bold">Segmentación del Curso</h1>
      </div>
      <div>

      </div>
    </div>

    <hr class="mt-2 mb-6">

    <article  class="card mb-6" *ngFor="let pais of paises">
      <div class="card-body bg-gray-100 ">
          <app-paises [curso]="curso" [pais]="pais" [empresas]="empresas" [negocios]="negocios" [cuentas]="cuentas" [centroCostos]="centroCostos" [areas]="areas"></app-paises>
      </div>
    </article>


</section>
