import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'src/app/Services/comun/dialog.service';
import { SegmentacionService } from '../../../../../../Services/admin/segmentacion.service';

@Component({
  selector: 'app-cuentas',
  templateUrl: './cuentas.component.html',
  styleUrls: ['./cuentas.component.scss']
})
export class CuentasComponent implements OnInit {

  @Input() pais;
  @Input() empresa;
  @Input() negocio;
  @Input() cuenta;
  @Input() centroCostos;
  @Input() areas;
  @Input() curso;

  puestoTrabajos:any[];

  bCentroCosto:boolean;
  constructor(
    private segmentacionService: SegmentacionService,
    private dialogService:DialogService
  ) {
    this.bCentroCosto = false;
  }

  ngOnInit(): void {
  }

  mostrarCentroCosto(CodPais:string,codEmpresa,codUnidadNegocio:string,codCentroCosto:string){
    this.bCentroCosto = !this.bCentroCosto;

    if(this.bCentroCosto){
      this.getPuestoTrabajo(CodPais,codEmpresa,codUnidadNegocio,codCentroCosto);
    }
  }

  onCheckboxChange(value,obj):void{

    if (value.target.checked) {
      var Entity = {
        intCursoID : this.curso.intCursoID,
        intTipo: 4,
        strSegmentacion: obj.codCuenta,
        intAccion: 1
      };
       this.segmentacionService.postSegmentacion(Entity).subscribe(result=>{
         if(result.code==200){

         }
         else{
          this.dialogService.openMsgErrorDialog("Hubo un problema al intertar registrar una segmentación");
         }
       },(err)=>{
         console.log(err);
       });

    } else {

      var _Entity = {
        intCursoID : this.curso.intCursoID,
        intTipo: 4,
        strSegmentacion: obj.codCuenta,
        intAccion: 0
      };
       this.segmentacionService.postSegmentacion(_Entity).subscribe(result=>{
         if(result.code==200){

         }
         else{
          this.dialogService.openMsgErrorDialog("Hubo un problema al intertar eliminar una segmentación");
         }
       },(err)=>{
         console.log(err);
       });

    }

  }




  getPuestoTrabajo(CodPais:string,codEmpresa,codUnidadNegocio:string,codCentroCosto:string):void{
    this.segmentacionService.getSegmentacionAppBiPuestoTrabajo(CodPais,codEmpresa,codUnidadNegocio,codCentroCosto,this.curso.intCursoID).subscribe(result=>{

      if(result.code==200){
        this.puestoTrabajos = result.result;
        console.log(this.puestoTrabajos);
      }

    }, (err)=>{
      console.log(err);
    });
  }
}
