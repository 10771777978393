import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CursoUsuarioService } from 'src/app/Services/admin/curso-usuario.service';
import { DialogService } from 'src/app/Services/comun/dialog.service';
import { Tab } from 'src/app/tabconfig/tab.model';
import { TabService } from 'src/app/tabconfig/tab.service';
import { EnrolledShowComponent } from '../enrolled/enrolled-show/enrolled-show.component';


@Component({
  selector: 'app-students-list',
  templateUrl: './students-list.component.html',
  styleUrls: ['./students-list.component.scss']
})
export class StudentsListComponent implements OnInit {

  @Input() parametros;
  @Input() Cursos;

  students:any[];
  displayedColumns: string[] = ['nombre','correo','progreso','opcion'];
  dataSource = new MatTableDataSource<any[]>();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private tabService: TabService,
    private cursoUsuarioService: CursoUsuarioService,
    private dialogSerive: DialogService
  ) { }

  ngOnInit(): void {
  //  dataSource = new MatTableDataSource<any[]>() = this.students;
    this.getCursoUsuario();
  }

  enrolledShow(element:any):void{
  //  element.course = this.course;
    console.log(element);
    this.tabService.addTab(new Tab(EnrolledShowComponent, "Detalle del Alumno", { parent: 'CoursesListComponent', element: element }));
  }

  getCursoUsuario(){
    this.cursoUsuarioService.getCursoUsuario(this.Cursos.intCursoID).subscribe(result=>{
      if(result.code==200){
        this.students = result.result;
        this.dataSource = result.result as unknown as MatTableDataSource<any[]>;
      }
    },(err)=>{
      console.log(err);
    });
  }
}
