import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SecurityService } from '../../../system/security.service';
import { Router } from '@angular/router';
import { ComunService } from 'src/app/Services/comun/comun.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Output() btnAccion = new EventEmitter();

  bopenMenuWeb : boolean;
  bOpenMenuMobile:boolean;

  nombre:string;
  nombres:string;
  apellidoPaterno:string;
  apellidoMaterno:string;
  correo:string;

  intProfesorID:number;
  intAdministradorID:number;

  intTotalPreguntasNoLeidos:number = 0;
  intTotalPreguntasNoLeidosProfesor:number = 0;
  constructor(
    private utilsService : UtilsService,
    private router : Router,
    private securityService: SecurityService,
    private comunService : ComunService,
  ) {
    this.intProfesorID = this.securityService.GetProfesorID();
    this.intAdministradorID = this.securityService.GetCodAdministrador();
  }

  ngOnInit(): void {
    this.bOpenMenuMobile = false;
    this.nombres = this.securityService.GetNombres() + " " + this.securityService.GetApellidoPaterno() + " " + this.securityService.GetApellidoMaterno();
    this.correo = this.securityService.GetCorreo();
    this.nombre = this.securityService.GetNombres();
    this.apellidoPaterno = this.securityService.GetApellidoPaterno();
    this.apellidoMaterno = this.securityService.GetApellidoMaterno();

    this.TotalPreguntasNoLeidos();
    this.TotalPreguntasNoLeidosProfesor();
  }

  openMenuWeb(){
    this.bopenMenuWeb = !this.bopenMenuWeb;
  }

  openMenuMobile():void{
    this.bOpenMenuMobile = !this.bOpenMenuMobile;
  }
  closeMenuMobile():void{
    this.bOpenMenuMobile = false;
  }
  redirect(item:number):void{
    switch (item) {
      case 1:
        this.bopenMenuWeb = false;
        this.bOpenMenuMobile = false;
        this.btnAccion.emit();
        this.router.navigate(['/Learning']);

        break;
      /*case 2:
        this.bopenMenuWeb = false;
        this.bOpenMenuMobile = false;
        this.btnAccion.emit();
        this.router.navigate(['/profile']);

        break;
      case 3:
        this.bopenMenuWeb = false;
        this.bOpenMenuMobile = false;
        this.btnAccion.emit();
        this.router.navigate(['/Learning']);

        break;
      case 4:
        this.bopenMenuWeb = false;
        this.bOpenMenuMobile = false;
        this.btnAccion.emit();
        this.router.navigate(['/MailBox']);

        break;
        */
      case 5:
        this.bopenMenuWeb = false;
        this.bOpenMenuMobile = false;
        this.btnAccion.emit();
        this.router.navigate(['/MailBox']);
        break;
      case 6:
        this.bopenMenuWeb = false;
        this.bOpenMenuMobile = false;
        this.btnAccion.emit();
        this.router.navigate(['/dashboard']);
        break;
      default:
        break;
    }
  }
  CerrarSesion():void{
    var token = this.securityService.GetToken();
    this.cancelarToken(token);
    this.btnAccion.emit();
    this.securityService.ResetAuthData();
    this.router.navigate(['/login']);
  }

  cancelarToken(token){
    var oBE = {
      TOKEN: token
    }
    this.utilsService.cancelarToken(oBE).subscribe(result=>{
      if(result.code==200){
        this.intTotalPreguntasNoLeidos = +result.obj;
      }
    }, (err)=>{
      console.log(err);
    });
  }

  closeMenu(){
    this.bopenMenuWeb = false;
    this.bOpenMenuMobile = false;
  }

  TotalPreguntasNoLeidos(){
    //const intUsuarioID = +this.securityService.GetUsuarioID();
    const intUsuarioID = this.utilsService.convertBase64Url(this.securityService.GetUsuarioID());
    this.comunService.getComentariosNoLeidos(intUsuarioID).subscribe(result=>{
      if(result.code==200){
        this.intTotalPreguntasNoLeidos = +result.obj;
      }
    }, (err)=>{
      console.log(err);
    });
  }

  TotalPreguntasNoLeidosProfesor(){
    //const intUsuarioID = +this.securityService.GetProfesorID();
    const intUsuarioID = this.utilsService.convertBase64Url(this.securityService.GetUsuarioID());
    this.comunService.getComentariosNoLeidosProfesor(intUsuarioID).subscribe(result=>{
      if(result.code==200){
        this.intTotalPreguntasNoLeidosProfesor = +result.obj;
      }
    }, (err)=>{
      console.log(err);
    });
  }
}
