import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/Services/comun/dialog.service';


@Component({
  selector: 'app-observations',
  templateUrl: './observations.component.html',
  styleUrls: ['./observations.component.scss']
})
export class ObservationsComponent implements OnInit {

  constructor(
    private dialogSerive: DialogService
  ) { }

  ngOnInit(): void {
  }

}
