<header>
  <div class="rounded p-3 mb-1 flex justify-between items-center">
    <div>
      <h1 class="cursor-pointer"><strong>Puesto Trabajo: </strong> {{puestoTrabajo.puestoTrabajador}}</h1>
    </div>
    <div>
      <label class="flex items-center">
        <span class="inline-flex">
          <input *ngIf="puestoTrabajo.intCursoID" checked type="checkbox" type="checkbox" (change)="onCheckboxChange($event,puestoTrabajo)" class="text-blue-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed">
          <input *ngIf="!puestoTrabajo.intCursoID"   type="checkbox" type="checkbox" (change)="onCheckboxChange($event,puestoTrabajo)" class="text-blue-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed">
        </span>
      </label>
    </div>
  </div>
</header>
