

<input type="checkbox" id="sidebar-toggle">
<section class="h-12 md:h-24 bg-white items-center">
  <nav class="relative px-2 md:px-6 py-0 h-full flex justify-between items-center ">
      <div class="items-center">
        <a routerLink="/home">
          <img class="h-8 md:h-10 pl-5 md:pl-16" src="assets/img/ROM.png">
        </a>
      </div>
      <div class="lg:hidden">

        <button (click)="openMenuMobile()" class="navbar-burger flex items-center text-blue-900 p-3">
          <svg class="block h-4 w-4 fill-current" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Mobile menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
          </svg>
        </button>

      </div>
      <div class="hidden md:block">
        <app-navbar (btnAccion)="closeMenuMobile()"></app-navbar>
      </div>

  </nav>

  <div *ngIf="bOpenMenuMobile" class="navbar-menu relative z-50 block md:hidden">
    <div class="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
    <app-navbar-mobile (btnAccion)="closeMenuMobile($event)"></app-navbar-mobile>
   </div>
</section>


  <router-outlet></router-outlet>


 <footer class="mt-4 bg-white h-12 shadow-lg items-center pt-2">
    <div class="flex w-full text-sm text-gray-600 items-center">
      <div class="mx-auto">
        <div class="flex items-center justigy-center">
          <strong>Aplicativo</strong> &nbsp;GRUPO TAWA © - 2022 &nbsp;-&nbsp;<strong><label id="lblPais">Perú</label></strong> &nbsp;
          <img class="h-4 w-4 mt-1"   src="assets/img/company/peru.png">
        </div>
      </div>
    </div>
 </footer>

 <label for="sidebar-toggle" class="body-label"></label>
