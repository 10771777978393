<mat-dialog-content>

<form autocomplete="off" class="w-full" >
  <h1 class="text-2xl font-bold">Hacer un pregunta</h1>
  <hr class="mt-2 mb-6">
  <div class="mb-4">
    <label for="title">Título de la pregunta</label>
    <input #strTitulo   name="strTitulo"  type="text"  class="form-input block w-full mt-1">
  </div>

  <div class="mb-4">
    <label for="description">Descripción de la pregunta:</label>
    <textarea #strDescripcion  class="form-input block w-full mt-1" name="strDescripcion"  ></textarea>
  </div>

  <div class="flex justify-end">
    <button (click)="close()"  class="font-medium pt-2 pb-2 pl-4 pr-4 rounded text-white btn-sm pull-right" style="background-color: #FF455C !important;" type="button">Cancelar</button> &nbsp;&nbsp;&nbsp;
      <button (click)="PostLeccionComentario(strTitulo.value,strDescripcion.value)"  class="font-medium pt-2 pb-2 pl-4 pr-4 rounded  text-white btn-sm pull-right bg-blue-900"  type="button">Guardar</button>
  </div>
</form>

</mat-dialog-content>



