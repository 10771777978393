<div class="sidebar-main ">
   <!--
  <div class="sidebar-user">
      <img  src="assets/img/company/a2.jpg" alt="">
      <div class="py-5">
          <h3 class="text-lg font-semibold"> {{nombres}}</h3>
          <span>{{correo}}</span>
      </div>


  </div>
 -->
 <br><br>
  <div class="sidebar-menu">
      <div class="menu-block">
          <div class="menu-head">
              <span>Gestión</span>
          </div>
      </div>
      <ul>
          <li class="">
              <a (click)="addNewTab(0,null)" class="cursor-pointer text-color-rom-magenta">

                <img src="assets/img/home.svg">
                  Home
              </a>
          </li>
          <li *ngIf="intProfesorID>0">
              <a class="cursor-pointer" (click)="addNewTab(1,null)">
                  <img src="assets/img/notebook-computer.svg">
                  Cursos
              </a>
          </li>
          <li *ngIf="intProfesorID>0">
            <a class="cursor-pointer" (click)="addNewTab(2,null)">
              <img src="assets/img/chat.svg">
                Buzón de Mensajes
                <p class="absolute bg-white justify-center rounded-full px-1 mr-4 text-color-rom-azul  text-xs" style="right:0px;"  >{{intTotalPreguntasNoLeidosProfesor}}</p>
            </a>
        </li>

      </ul>

      <div class="menu-block">
          <div class="menu-head">
              <span>Configuración</span>
          </div>
      </div>
      <ul>
        <li *ngIf="intAdministradorID>0">
          <a (click)="addNewTab(7,null)" class="cursor-pointer">
            <img src="assets/img/cap.svg">
              Alumnos
          </a>
        </li>

        <li *ngIf="intAdministradorID>0">
          <a (click)="addNewTab(3,null)" class="cursor-pointer">
            <img src="assets/img/blackboard.svg">

              Profesores
          </a>
        </li>
        <li *ngIf="intAdministradorID>0">
          <a class="cursor-pointer" (click)="addNewTab(4,null)">

            <img src="assets/img/group.svg">
              Administradores


          </a>
        </li>



      </ul>
      <div class="menu-block">
        <div class="menu-head">
            <span>Reportes</span>
        </div>
      </div>
        <ul>
          <li *ngIf="intProfesorID>0 || intAdministradorID>0">
            <a (click)="addNewTab(5,null)" class="cursor-pointer">
                <img src="assets/img/clipboard.svg">

                Reporte de Usabilidad
            </a>
          </li>
          <li *ngIf="intProfesorID>0 || intAdministradorID>0">
            <a (click)="addNewTab(6,null)" class="cursor-pointer">
              <img src="assets/img/trend.svg">
                Reporte de Curso
            </a>
          </li>
        </ul>

        <div class="menu-block">
          <div class="menu-head">
              <span>Sistema</span>
          </div>
        </div>
        <ul>
          <li>
            <a routerLink="/home" class="cursor-pointer">
              <img src="assets/img/return.svg">
                Regresar a la Plataforma
            </a>
          </li>

          <li>
            <a (click)="CerrarSesion()" class="cursor-pointer">
              <img src="assets/img/log-out.svg">
                Cerrar Sesión
            </a>
          </li>
        </ul>




  </div>
</div>





