<!-- component -->
<div class="font-sans antialiased h-screen flex">
  <!-- Sidebar / channel list -->

  <!-- Chat content -->
  <div class="flex-1 flex flex-col bg-white overflow-hidden">
      <!-- Top bar -->
      <div class="border-b flex px-6 py-2 items-center flex-none">

          <div class="hidden md:block w-full">
              <div class="w-full">
                  <input type="text" placeholder="Search" class="appearance-none border border-grey rounded-lg pl-8 pr-4 py-2 w-full">

              </div>
          </div>
      </div>
      <!-- Chat messages -->
      <div class="px-6 py-4 flex-1 overflow-y-scroll">


          <ng-container *ngFor="let mailbox of mailBoxs">

            <div *ngIf="teacherID==mailbox.user.id" class="flex justify-end mb-4">
              <div
                class="mr-2 py-3 px-4 bg-blue-400 rounded-bl-3xl rounded-tl-3xl rounded-tr-xl text-white"
              >
              {{mailbox.message}}
              </div>
              <img
                src="{{mailbox.user.photo}}"
                class="object-cover h-8 w-8 rounded-full"
                alt=""
              />
            </div>


            <div *ngIf="studentID==mailbox.user.id" class="flex justify-start mb-4">
              <img
                src="{{mailbox.user.photo}}"
                class="object-cover h-8 w-8 rounded-full"
                alt=""
              />
              <div
                class="ml-2 py-3 px-4 bg-gray-400 rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-white"
              >
              {{mailbox.message}}
              </div>
            </div>

          </ng-container>
      </div>
      <div class="pb-6 px-4 flex-none">
          <div class="flex rounded-lg border-2 border-grey overflow-hidden">
              <span class="text-3xl text-grey border-r-2 border-grey p-2">
                  <svg class="fill-current h-6 w-6 block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M16 10c0 .553-.048 1-.601 1H11v4.399c0 .552-.447.601-1 .601-.553 0-1-.049-1-.601V11H4.601C4.049 11 4 10.553 4 10c0-.553.049-1 .601-1H9V4.601C9 4.048 9.447 4 10 4c.553 0 1 .048 1 .601V9h4.399c.553 0 .601.447.601 1z"/></svg>
                </span>
              <input type="text" class="w-full px-4" [(ngModel)]="messageBox" (keyup.enter)="createMailBox()" placeholder="Message #general" />
          </div>
      </div>
  </div>
</div>
