import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CursosService } from 'src/app/Services/estudiante/cursos.service';
import { environment } from 'src/environments/environment';

import { SecurityService } from 'src/app/system/security.service';
import { DialogService } from 'src/app/Services/comun/dialog.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {

  curso:any;

  detalle : boolean;
  temas : boolean;
  expositor : boolean;
  valoracion : boolean;

  bOpenTemasLesson: boolean;

  slug: string;
  BASE_URL_PUBLIC: string;

  loading:boolean = true;
  constructor(
    private utilsService : UtilsService,
    private CursosService:CursosService,
    private rutaActiva: ActivatedRoute,
    private securityService:SecurityService,
    private router: Router,
    private dialogService: DialogService
  ) {
    this.slug = this.rutaActiva.snapshot.params.slug;
    this.BASE_URL_PUBLIC = environment.BASE_URL_PUBLIC;
   }

  ngOnInit(): void {
    this.curso = null;
    this.detalle = true;
    this.bOpenTemasLesson = false;

    this.getCursoPorSlug();
  }

  active(value:number):void{

    switch (value) {
      case 1:
        this.detalle = true;
        this.temas = false;
        this.expositor = false;
        this.valoracion = false;
      break;
      case 2:
        this.detalle = false;
        this.temas = true;
        this.expositor = false;
        this.valoracion = false;
      break;
      case 3:
        this.detalle = false;
        this.temas = false;
        this.expositor = true;
        this.valoracion = false;
      break;
      case 4:
        this.detalle = false;
        this.temas = false;
        this.expositor = false;
        this.valoracion = true;
      break;
      default:
        this.detalle = true;
        this.temas = false;
        this.expositor = false;
        this.valoracion = false;
      break;
    }
  }

  OpenTemasLesson() : void{
    this.bOpenTemasLesson = !this.bOpenTemasLesson;
  }

  getCursoPorSlug():void {
    const intUsuarioID = this.utilsService.convertBase64Url(this.securityService.GetUsuarioID());
    this.CursosService.getCursoPorSlug(this.slug,intUsuarioID).subscribe(result=>{
      this.loading = false;
      if(result.code==200){
        this.curso = result.obj;

      }
    },(err)=>{
      this.loading = false;
      console.log(err);
    });
  }

  SolicitarCurso():void {
   const oBE = {
    intCursoID: +this.curso.intCursoID,
    intUsuarioID: +this.securityService.GetUsuarioID()
   };

    this.CursosService.getCursoSolicitar(oBE).subscribe(result=>{
      if(result.code==200){
        this.getCursoPorSlug();
        this.dialogService.openMsgSuccessDialog("Aviso", "Su solicitud se ha generado");
      }
      else{
        this.dialogService.openMsgErrorDialog(result.message);
      }
    },(err)=>{
      console.log(err);
    });
  }

  redireccionar(slug:string):void{
    this.slug = slug;
    this.router.navigate(['/curso/info/',slug]);
    this.ngOnInit();
    //location.reload();
  }

//  routerLink="/curso/info/{{cursotop.strSlug}}"
}
