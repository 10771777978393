import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { Observable } from 'rxjs';
import { ResultModel } from '../../models/result.model';
import { SecurityService } from 'src/app/system/security.service';

@Injectable({
  providedIn: 'root'
})
export class ReportesService extends AppService {

  constructor(http: HttpClient, securityService:SecurityService) {
    super(http,securityService);
  }

  usabilidad<T>(datos:any):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/reporte/usabilidad`;
    return this.post(this.endpoint,datos);
  }

  cursos<T>(datos:any):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/reporte/curso`;
    return this.post(this.endpoint,datos);
  }
}
