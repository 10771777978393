import { Component, OnInit } from '@angular/core';
import { TabService } from 'src/app/tabconfig/tab.service';
import { Router } from '@angular/router';
import { SecurityService } from '../../../system/security.service';

@Component({
  selector: 'app-admin-layouts',
  templateUrl: './admin-layouts.component.html',
  styleUrls: ['./admin-layouts.component.scss']
})
export class AdminLayoutsComponent implements OnInit {

  bmostrarNegocios = false;

  nombre:string;
  nombres:string;
  apellidoPaterno:string;
  apellidoMaterno:string;
  correo:string;

  intProfesorID:number;
  intAdministradorID:number;

  constructor(
    private tabService: TabService,
    private router : Router,
    private securityService: SecurityService
  ) {

    this.intProfesorID = this.securityService.GetProfesorID();
    this.intAdministradorID = this.securityService.GetCodAdministrador();

    this.nombres = this.securityService.GetNombres() + " " + this.securityService.GetApellidoPaterno() + " " + this.securityService.GetApellidoMaterno();
    this.correo = this.securityService.GetCorreo();
    this.nombre = this.securityService.GetNombres();
    this.apellidoPaterno = this.securityService.GetApellidoPaterno();
    this.apellidoPaterno = this.securityService.GetApellidoMaterno();

   }

  ngOnInit(): void {


  }

  mostrarNegocios(){
    this.bmostrarNegocios = !this.bmostrarNegocios;
  }
}
