import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from 'src/app/components/shared/dialog-confirm/dialog-confirm.component';
import { MsgErrorComponent } from 'src/app/components/shared/msg-error/msg-error.component';
import { MsgSuccessComponent } from 'src/app/components/shared/msg-success/msg-success.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private dialog: MatDialog
  ) { }

  openConfirmDialog(msg){
    return this.dialog.open(DialogConfirmComponent,{
      width: '390px',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data :{
        message : msg
      }
    });
  }

  openMsgSuccessDialog(title:string,msg:string){
    return this.dialog.open(MsgSuccessComponent,{
      width: '390px',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data :{
        title : title,
        message : msg
      }
    });
  }

  openMsgErrorDialog(msg:string){
    return this.dialog.open(MsgErrorComponent,{
      width: '390px',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data :{
        message : msg
      }
    });
  }

}
