<div>
  <h1>LECCIONES DEL CURSO</h1>
  <hr class="mt-2 mb-6">
  <article *ngFor="let seccion of secciones;let i = index" class="card mb-6">
      <div class="card-body bg-gray-100">
        <app-section-body [seccion]="seccion" [item]="i+1" [Cursos]="Cursos" [parametros]="parametros"></app-section-body>
      </div>
  </article>

  <div>
    <a (click)="newSection()" class="flex items-center cursor-pointer">
      <i  class="fa fa-plus-square-o text-2xl text-red-500 mr-2" aria-hidden="true"></i>
      Agregar nueva sección
    </a>

    <article [ngClass]="showSection? 'show card': 'hidden'">
      <div class="card-body bg-gray-100">
          <h1 class="text-xl font-bold mb-4">Agregar nueva sección</h1>
          <div class="mb-4">
              <input type="text"  [(ngModel)]="strDescripcion" maxlength="100" (keyup.enter)="postSeccion()"  class="form-input w-full" placeholder="Escriba el nombre de la sección" required>
          </div>
          <div class="flex justify-end">
              <button (click)="cancelar()"  class="btn-rom-magenta">Cancelar</button>
              <button (click)="postSeccion()"  class="btn-rom-azul ml-2">Agregar</button>
          </div>
      </div>
  </article>
  </div>
</div>
