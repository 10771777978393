import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SeccionService } from 'src/app/Services/admin/seccion.service';
import { DialogService } from 'src/app/Services/comun/dialog.service';


@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {


  @Input() parametros;
  @Input() Cursos;

  showSection:boolean;
  formCourse: FormGroup;
  strDescripcion:string = '';

  secciones:any[];

  constructor(
   private seccionService:SeccionService,
   private dialogSerive: DialogService
  ) { }

  ngOnInit(): void {
    this.getSeccion();
  }

  newSection():void{
    this.showSection = !this.showSection;
  }

  getSeccion(){
    this.seccionService.getSeccion(this.Cursos.intCursoID).subscribe(result=>{
      debugger;
      this.secciones = result.result;
      console.log("secciones",this.secciones);
    }, (err)=>{
        console.log("Error",err);
    });
  }
  postSeccion(){
    const oBE = {
      'strDescripcion' : this.strDescripcion,
      'intCursoID' : this.Cursos.intCursoID
    };

    this.seccionService.postSeccion(oBE).subscribe(result=>{
      console.log("registrar",result);
       if(result.code==200){
        this.getSeccion();

        this.dialogSerive.openMsgSuccessDialog("Aviso","Se registró correctamente").afterClosed().subscribe(res=>{

        });

       }
       else{
        this.dialogSerive.openMsgErrorDialog(result.message).afterClosed().subscribe(res=>{

        });

       }

    }, (err)=>{
        console.log("Error",err);

        this.dialogSerive.openMsgErrorDialog("Se presento un problema al intentar registrar").afterClosed().subscribe(res=>{

        });

    });
  }

  cancelar():void{
    this.showSection = false;
    this.strDescripcion = '';
  }
}
