<div class="relative hidden md:block">
  <p class="absolute bg-color-rom-azul justify-center rounded-full px-1 mr-4 text-white text-xs" style="right: -15px;">{{intTotalPreguntasNoLeidos + intTotalPreguntasNoLeidosProfesor }}</p>

  <div class="sm:flex sm:items-center sm:ml-2 w-12">

    <img
    (click)="openMenuWeb()"
     class="h-12 w-12 md:h-10 md:w-10 rounded-full object-cover cursor-pointer items-center"
     src="https://ui-avatars.com/api/?name={{nombre| slice:0:1}}+{{apellidoPaterno| slice:0:1}}&amp;color=ffffff&amp;background=FF455C"

   />

  </div>


 <div [ngClass]="bopenMenuWeb? 'show z-50 absolute right-0  mt-2 origin-top-right rounded-md shadow-lg  md:w-80': 'hidden'">
    <div class="px-2 py-2 bg-white rounded-md shadow dark-mode:bg-gray-800">
      <div class="block   pt-2 text-xs text-gray-500">
         <div class="flex items-center">
           <div class="px-3">
             <div class="font-bold text-xl">{{nombres}}</div>
             <div>{{correo}}</div>
           </div>
         </div>
      </div>
      <div class="border-t border-gray-200   mt-4"></div>
      <!-- <a  routerLink="/profile" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer">Mi Perfil</a> -->
      <a (click)="redirect(1)"  class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer">Mis Cursos</a>
      <div class="border-t border-gray-200"></div>
      <a (click)="redirect(5)" routerLink="/Help" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer">
        <p class="absolute bg-color-rom-azul justify-center rounded-full px-1 mr-4 text-white text-xs" style="right:0px;" >{{intTotalPreguntasNoLeidos}}</p>
        Mis Preguntas

      </a>

      <div class="border-t border-gray-200 pt-1 mt-1"></div>
      <a *ngIf="intProfesorID>0 || intAdministradorID>0" (click)="redirect(6)" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer">
        <p class="absolute bg-color-rom-azul justify-center rounded-full px-1 mr-4 text-white text-xs" style="right:0px;" >{{intTotalPreguntasNoLeidosProfesor}}</p>
        Administración
      </a>
      <div *ngIf="intProfesorID>0 || intAdministradorID>0" class="border-t border-gray-200 pt-1 mt-1"></div>
      <!-- <div class="border-t border-gray-200 pt-1 mt-1"></div> -->
      <!-- <a routerLink="/MailBox" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer">Mensajes</a>
      <div class="border-t border-gray-200 pt-1 mt-1"></div>-->
      <!--  <a routerLink="/Help" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer">Ayuda</a>  -->
      <!-- <div class="border-t border-gray-200 pt-1 mt-1"></div> -->
      <a (click)="CerrarSesion()" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer ">Cerrar Sesión</a>
    </div>
  </div>
</div>
