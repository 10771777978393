import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from 'src/app/Services/comun/dialog.service';
import { RequisitosService } from '../../../../../../Services/admin/requisitos.service';


@Component({
  selector: 'app-requirement',
  templateUrl: './requirement.component.html',
  styleUrls: ['./requirement.component.scss']
})
export class RequirementComponent implements OnInit {

  @Input() parametros;
  @Input() Cursos;

  requisitos:any[];

  formRequisito: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private requisitosService : RequisitosService,
    private dialogSerive: DialogService
  ) { }

  ngOnInit(): void {
    this.getRequisitos();
    this.initFormRequisitos();
  }

  initFormRequisitos() : void{
    this.formRequisito = this.formBuilder.group({
      intRequisitoID : [null,Validators.required],
      intCursoID:[null,Validators.required],
      strDescripcion:[null,Validators.required]
    });
  }

  getRequisitos(){
    this.requisitosService.getRequisito(this.Cursos.intCursoID).subscribe(result=>{
      if(result.code==200){
        this.requisitos = result.result;
      }
    },(err)=>{
      console.log(err);
    });
  }

  registrar(){
    const oBE = {
      "intMetasID" : 0,
      "intCursoID":this.Cursos.intCursoID,
      "strDescripcion": this.formRequisito.controls["strDescripcion"].value
    }
    this.requisitosService.postRequisito(oBE).subscribe(result=>{
      if(result.code==200){
        this.formRequisito.controls["strDescripcion"].setValue(null);
        this.getRequisitos();


        this.dialogSerive.openMsgSuccessDialog("Aviso","Se registró correctamente").afterClosed().subscribe(res=>{

        });


      }
      else{
        this.dialogSerive.openMsgErrorDialog(result.message).afterClosed().subscribe(res=>{

        });
      }
    },(err)=>{
      console.log(err);
    });
  }

  eliminar(intRequisitoID:number){
    this.requisitosService.deleteRequisito(intRequisitoID).subscribe(result=>{
      if(result.code==200){
        this.getRequisitos();
        this.dialogSerive.openMsgSuccessDialog("Aviso","Se elimino correctamente").afterClosed().subscribe(res=>{

        });

      }
      else{
        this.dialogSerive.openMsgErrorDialog(result.message).afterClosed().subscribe(res=>{

        });
      }
    },(err)=>{
      console.log(err);
    });
  }
}
