import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog,    MatDialogModule, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { SecurityService } from 'src/app/system/security.service';
import { ComentariosService } from 'src/app/Services/estudiante/comentarios.service';
import { DialogService } from 'src/app/Services/comun/dialog.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';


@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {

  form: FormGroup;
  curso:any;

  strTitulo!:string;
  strDescripcion!:string;

  constructor(
    private utilsService : UtilsService,
    public dialogRef: MatDialogRef<CommentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private securityService:SecurityService,
    private formBuilder: FormBuilder,
    private dialogSerive: DialogService,
    private comentarioService:ComentariosService
  ) {
    this.curso = data;
  }

  ngOnInit(): void {


  }


  close(){
    this.dialogRef.close(false);
  }
  PostLeccionComentario(strTitulo:string,strComentario:string):void
  {
    const oBE = {
      strTitulo :  strTitulo, //(document.getElementById("strTitulo") as HTMLInputElement).value),
      strComentario : strComentario, // ((document.getElementById("strComentario") as HTMLInputElement).value),
      intLeccionComentarioPadreID : 0,
      intLeccionID : this.curso.eL_Leccion_DTO_Actual.intLeccionID,
      //intUsuarioID : this.securityService.GetUsuarioID()
      strUsuarioID : this.utilsService.convertBase64Url(this.securityService.GetUsuarioID())
    };

    //((document.getElementById("text") as HTMLInputElement).value);
    this.comentarioService.postComentarios(oBE).subscribe(result=>{
      if(result.code==200){
        this.dialogSerive.openMsgSuccessDialog("Aviso","Se registró correctamente").afterClosed().subscribe(res=>{
          this.dialogRef.close(true);
        });
      }
      else{
        this.dialogSerive.openMsgErrorDialog(result.message).afterClosed().subscribe(res=>{

        });
      }
    },(err)=>{
      console.log(err);
    });
  }



}
