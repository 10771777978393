import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ParametrosService } from 'src/app/Services/admin/parametros.service';
import { DialogService } from 'src/app/Services/comun/dialog.service';
import { SecurityService } from 'src/app/system/security.service';
import { TabService } from 'src/app/tabconfig/tab.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { AdministradorService } from '../../../../Services/admin/administrador.service';
import { EstudianteMatriculadosService } from 'src/app/Services/admin/estudiante-matriculados.service';

@Component({
  selector: 'app-estudiantes',
  templateUrl: './estudiantes.component.html',
  styleUrls: ['./estudiantes.component.scss']
})
export class EstudiantesComponent implements OnInit {

  displayedColumns: string[] = ['strDescripcion', 'strEstudiante','opcion'];
  dataSource = new MatTableDataSource<any[]>();
  @ViewChild(MatSort, {static:true}) sort:MatSort;
  @ViewChild('paginatorDatasource', {read:MatPaginator, static:true}) paginator: MatPaginator;


  form: FormGroup;
  parametros: any[];

  empresas:any[];
  negocios:any[];

  constructor(
    private tabService: TabService,
    private parametrosService:ParametrosService,
    private formBuilder: FormBuilder,
    private administradorService: AdministradorService,
    private dialogService: DialogService,
    private securityServices : SecurityService,
    private estudianteMatriculadosService : EstudianteMatriculadosService
  ) { }

  ngOnInit(): void {
    this.iniForm();
    this.getAppbi();
  }

  getAppbi():void {

    this.administradorService.getAppbi().subscribe(result=>{
      if(result.code==200){
        this.empresas = result.obj.empresas.filter(x=>x.codPais=='155');
        this.negocios= result.obj.negocio.filter(x=>x.codPais=='155');
      }
      else{
        this.dialogService.openMsgErrorDialog(result.message);
      }
    },(err)=>{
      this.dialogService.openMsgErrorDialog("Se presento un problema al intentar obtener la información")
      console.log(err);
    });
  }
  iniForm():void {
    this.form = this.formBuilder.group({
      codEmpresa : [0,Validators.required],
      codNegocio:[0,Validators.required],
      strTitulo:[null,Validators.required]
    });
  }

  buscar():void{
    const CodEmpresa = this.form.controls['codEmpresa'].value;
    const codNegocio = this.form.controls['codNegocio'].value;
    const strTitulo = this.form.controls['strTitulo'].value;
    this.estudianteMatriculadosService.getEstudiantes(CodEmpresa,codNegocio).subscribe(result=>{
      if(result.code==200){

        var filtros;
        if(strTitulo==null){
           filtros = result.result;
        }
        else{
           filtros = result.result.filter(item => item.nombres.toLowerCase().includes(strTitulo.toLowerCase()));
        }

        this.dataSource = new MatTableDataSource<any[]>(filtros);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
       //  this.dataSource.filter = strFilter;

      }
      else{

      }
    },(err)=>{

      console.log(err);
    });
  }

  AsignarPermiso(element:any):void{
    console.log(element);
    var oBE = {
      IDUSUARIO: element.idusuario,
      Accion: 1
    }
    this.estudianteMatriculadosService.AsignarPermiso(oBE).subscribe(result=>{
      if(result.code==200){
        this.buscar();
      }
    },(err)=>{
      console.log(err);
    })
  }
  QuitarPermiso(element:any):void{
    console.log(element);
    var oBE = {
      IDUSUARIO: element.idusuario,
      Accion: 0
    }
    this.estudianteMatriculadosService.AsignarPermiso(oBE).subscribe(result=>{
      if(result.code==200){
        this.buscar();
      }
    },(err)=>{
      console.log(err);
    })
  }
  
}
