<div class="flex-1 flex flex-col overflow-hidden">
  <header class=" justify-between items-center mt-6 px-2 border-b-2 border-gray-300">
      <div class="items-center space-x-2 lg:space-x-0">
        <div class=" flex justify-between">
          <div class=" inline-flex justify-center">

            <span (click)="showAside(0)" [ngClass]="bPreguntas?'px-2 -ml-1 font-semibold  border-b-2 border-orange cursor-pointer':'px-2 -ml-1  border-b-2 border-orange cursor-pointer'" >Preguntas</span>
            <span (click)="showAside(1)" [ngClass]="bNotificaciones?'px-2 -ml-1 font-semibold  border-b-2 border-orange cursor-pointer hidden':'px-2 -ml-1  border-b-2 border-orange cursor-pointer hidden'" >Buzon de mensaje</span>
          </div>
          <div class="inline-flex py-3 -mt-4">

                  <div class="relative text-gray-600 focus-within:text-gray-400">
                    <span class="absolute inset-y-0 left-0 flex items-center pr-2">
                    <button  class="p-1 focus:outline-none focus:shadow-outline">
                      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    </button>
                    </span>
                    <input type="text" name="strNombres" #strNombres (keyup.enter)="buscador(strNombres.value)"  (keyup)="buscador(strNombres.value)" class="py-2 text-sm text-gray-900 border-gray-50 rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900" placeholder="buscador..." autocomplete="off">
                  </div>

          </div>
        </div>
      </div>

  </header>
<!-- main -->
  <main [ngClass]="bPreguntas?'flex-1 overflow-x-hidden overflow-y-auto':'hidden'">
    <ng-container *ngFor="let pregunta of preguntas" >
        <div class="px-10 py-2 mt-1">
          <span class="text-gray-500 font-semibold">
              {{pregunta.dtFechaRegistro | date:'dd/MM/yyyy'}}
          </span>
        </div>
        <div *ngFor="let group of pregunta.groupItem"  class="container mx-auto w-full px-6 -mb-2 ">
          <div class="mt-4 flex px-4 py-4 justify-between bg-white dark:bg-gray-600 shadow-sm rounded-lg cursor-pointer">
          <!-- Card -->
            <div class="flex justify-center">
            <!-- Left side -->
              <img class="h-12 w-12 rounded-full object-cover" src="https://ui-avatars.com/api/?name={{group.strNombre}}+{{group.strPrimerApellido}}&amp;color=ffffff&amp;background=000064"  alt="">
              <div class="px-2 flex flex-col capitalize text-gray-600">
                  <span class="font-bold text-md">{{group.strNombre}} {{group.strPrimerApellido}} {{group.strSegundoApellido}}</span>
              </div>
            </div>
            <div class="flex pr-8 w-full">
            <!-- Rigt side -->
                    <div class="ml-16 pl-4 flex flex-col capitalize text-gray-600
                        dark:text-gray-400">

                        <span *ngIf="group.intRespuesta==0" (click)="PreguntasRespuestas(group)" class="btn-rom-magenta">
                            Responder
                        </span>

                        <div *ngIf="group.intRespuesta>0"  class="bg-green-600 text-white font-bold py-2 px-4 rounded font-roboto flex flex-col justify-center">
                          <div class="relative  sm:max-w-xl sm:mx-auto">
                            <div class="group cursor-pointer relative inline-block  text-center">Respondido
                              <div class="opacity-0 w-96 bg-black text-white text-center text-xs rounded-lg py-2 absolute z-50 group-hover:opacity-100  -left-1/2 ml-14 px-3 pointer-events-none">
                                {{group.strRespuesta}}
                                <svg class="absolute text-black h-2 w-full left-0 top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
                              </div>
                            </div>
                          </div>
                        </div>

                    </div>


                    <div class="ml-8 flex flex-col capitalize text-gray-600 dark:text-gray-400">
                      <p><span class="font-bold">{{group.strTitulo}} - </span>{{group.strComentario}}</p>
                      <p><b>Curso:</b> {{group.strCurso}}</p>
                      <p><b>Leccion:</b> {{group.strLeccion}}</p>
                    </div>
            </div>
        </div>
      </div>
    </ng-container>
  </main>

  <main [ngClass]="bNotificaciones?'flex-1 overflow-x-hidden overflow-y-auto':'hidden'">
    <ng-container *ngFor="let pregunta of preguntas" >
        <div class="px-10 py-2 mt-1">
          <span class="text-gray-500 font-semibold">
               {{pregunta.dtFechaRegistro | date:'dd/MM/yyyy'}}
          </span>
        </div>
        <div *ngFor="let group of pregunta.groupItem"  class="container mx-auto w-full px-6 -mb-2 ">
          <div class="mt-4 flex px-4 py-4 justify-between bg-white dark:bg-gray-600 shadow-sm rounded-lg cursor-pointer">
          <!-- Card -->
            <div class="flex justify-center">
            <!-- Left side -->
              <img class="h-12 w-12 rounded-full object-cover" src="https://ui-avatars.com/api/?name={{group.strNombre}}+{{group.strPrimerApellido}}&amp;color=ffffff&amp;background=000064"  alt="">
              <div class="px-2 flex flex-col capitalize text-gray-600">
                  <span class="font-bold text-md">{{group.strNombre}} {{group.strPrimerApellido}} {{group.strSegundoApellido}}</span>
              </div>
            </div>
            <div class="flex pr-8 w-full">
            <!-- Rigt side -->
                    <div class="ml-16 pl-4 flex flex-col capitalize text-gray-600
                        dark:text-gray-400">

                        <span *ngIf="group.intRespuesta==0" (click)="PreguntasRespuestas(group)" class="btn-rom-magenta">
                            Responder
                        </span>

                        <div *ngIf="group.intRespuesta>0"  class="btn-rom-azul flex flex-col justify-center">
                          <div class="relative  sm:max-w-xl sm:mx-auto">
                            <div class="group cursor-pointer relative inline-block  text-center">Respuesta
                              <div class="opacity-0 w-96 bg-black text-white text-center text-xs rounded-lg py-2 absolute z-50 group-hover:opacity-100  -left-1/2 ml-14 px-3 pointer-events-none">
                                {{group.strRespuesta}}
                                <svg class="absolute text-black h-2 w-full left-0 top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
                              </div>
                            </div>
                          </div>
                        </div>

                    </div>


                    <div class="ml-8 flex flex-col capitalize text-gray-600 dark:text-gray-400">
                      <p><span class="font-bold">{{group.strTitulo}} - </span>{{group.strComentario}}</p>
                      <p><b>Curso:</b> {{group.strCurso}}</p>
                      <p><b>Leccion:</b> {{group.strLeccion}}</p>
                    </div>
            </div>
        </div>
      </div>
    </ng-container>
  </main>
  <!-- end of main -->
</div>
