import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map} from 'rxjs/operators';
import { DialogService } from '../Services/comun/dialog.service';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptor  implements HttpInterceptor {

  constructor(
    private dialogSerive: DialogService,
    private router: Router,
    private securityService: SecurityService,
    public _spinner: NgxSpinnerService,
  ) { }

  intercept(req: HttpRequest<any>, handler: HttpHandler): Observable<HttpEvent<any>> {
    let newHeader = req.headers.set('Accept', 'application/json');

    if(this.router.url !== '/login')
    {

      let token: string = null;
      try {
        token = this.securityService.GetToken();
      } catch (err) {
        console.log(err);
      }

      if (token !== null) {
        newHeader = newHeader.set('Authorization', 'Bearer ' + token);
      } else {
        console.log("x")
        this.securityService.LogOff();
      }
    }

    const reqHeader = req.clone({
      headers: newHeader
    });

    this._spinner.show();
    return handler.handle(reqHeader).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);
        }
        return event;
      }),
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 0:
              this.router.navigate(['/login']);
              break;
            case 401:
              if(this.router.url === '/login')
              {
                //alert("Usuario y/o contraseña incorrectos");
                this.dialogSerive.openMsgErrorDialog("Usuario y/o contraseña incorrectos").afterClosed().subscribe(res=>{

                });


              }else{

                this.dialogSerive.openMsgErrorDialog("No esta autorizado para utilizar el recurso 401").afterClosed().subscribe(res=>{

                });
                // this.toastr.warning("No esta autorizado para utilizar el recurso", "Advertencia");
                this.router.navigate(['/login']);
              }

              break;
            case 403:
              //alert("No esta autorizado para utilizar el recurso 403");

              this.dialogSerive.openMsgErrorDialog("No esta autorizado para utilizar el recurso").afterClosed().subscribe(res=>{

              });

              this.securityService.LogOff();
              this.router.navigate(['/login']);

                break;
            default:
          }
        }
        return throwError(err);
      }),
      finalize(() => {
        //  this.count--;
        //  if (this.count == 0) {
            this._spinner.hide();
        //  }
      })
    );


  }
}
