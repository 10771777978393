import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FileUploader, FileUploaderOptions, FileItem } from 'ng2-file-upload';
import { ArchivoComplementarioService } from 'src/app/Services/admin/archivo-complementario.service';
import { environment } from 'src/environments/environment';

import { SecurityService } from 'src/app/system/security.service';
import { DialogService } from 'src/app/Services/comun/dialog.service';


@Component({
  selector: 'app-supplementary-files',
  templateUrl: './supplementary-files.component.html',
  styleUrls: ['./supplementary-files.component.scss']
})
export class SupplementaryFilesComponent implements OnInit {

  @Input() parametros;
  @Input() Cursos;

  slug:string;
  archivosComplementarios:any[];

  form: FormGroup;
  formCourse: FormGroup;
 // uploader: FileUploader;

  paramterID : number;

  optionExist: boolean =false;
  values:string = '';

  uploader: FileUploader;

  archivosComplementario_current:any;
  constructor(
    private archivoComplementarioService : ArchivoComplementarioService,
    private dialogSerive: DialogService,
    private securityService:SecurityService
  ) {
    //console.log("environment.BASE_URL_UPLOAD",environment.BASE_URL_UPLOAD);
    this.uploader = new FileUploader({
      url: environment.BASE_URL_UPLOAD,
     // authToken: "Authorization",
     // authTokenHeader: 'Bearer '+ this.securityService.GetToken(),
      /*
      headers: [
        {name:'Content-Type', value:'application/json'},
        {name:'Authorization', value:'Bearer '+ this.securityService.GetToken() }
      ],
      */
      isHTML5:true,

      itemAlias: 'file',
      disableMultipart: false,
      autoUpload: false,
      method: 'post' //,
      //allowedFileType: ['image', 'pdf']
    });
  }

  ngOnInit(): void {
    debugger;
    this.pageLoad();

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.uploader.onBeforeUploadItem = (fileItem: any) => {
      fileItem.formData.push( this.Cursos );
     };

    this.uploader.onCompleteItem = (item: any, status: any) => {
       let obj = JSON.parse(status);
       if(obj['code']==200){
        const oBE = {
          'intArchivoComplementarioID': 0,
          'intTipoArchivo' : +this.archivosComplementario_current.intTipoArchivo,
          'intCursoID' : +this.Cursos.intCursoID,
          'strUrl': obj['fileName'],
          'striframe': obj['fullPath']
        };

        this.archivoComplementarioService.insertar(oBE).subscribe(
          result=>{
            console.log(result);
              if(result.code==200){
                this.pageLoad();

                this.dialogSerive.openMsgSuccessDialog("Aviso","Se registró correctamente").afterClosed().subscribe(res=>{
                  this.pageLoad();
                });

              }
              else{
                this.dialogSerive.openMsgErrorDialog(result.message).afterClosed().subscribe(res=>{

                });
              }
          }
        );

       }
       else{
debugger;
        this.dialogSerive.openMsgErrorDialog("Problema para subir el archivo").afterClosed().subscribe(res=>{

        });
       }
    };
  }

  pageLoad():void{

    this.archivoComplementarioService.listar(this.Cursos.intCursoID).subscribe(result=>{
      this.archivosComplementarios = result.result;
      console.log("archivosComplementarios",this.archivosComplementarios);
    }, (err)=>{
        console.log("Error",err);
    });
  }

  upload(archivosComplementario:any):void{
    debugger;
    this.archivosComplementario_current = archivosComplementario;
    this.uploader.onBuildItemForm = (fileItem: FileItem, form: any) => {

      form.append('intCursoID', this.Cursos.intCursoID);
      form.append('strSlug', this.Cursos.strSlug);
      form.append('strSubCarpeta', "ArchivoComplementarios");
      form.append('strTipoArchivo', archivosComplementario.strTipoArchivo);
    };



    this.uploader.uploadAll();
    /*
    this.paramterID = archivosComplementario.pa;
    if(this.paramterID==4){

      const oBE = {
        'intTipoArchivo' : this.paramterID,
        'intCursoID' : this.Cursos.intCursoID,
        'strUrl': this.values,
        'striframe': ''
      };

    this.archivoComplementarioService.insertar(oBE).subscribe(
      result=>{
        console.log(result);
          if(result.code==200){
           alert("Se registró correctamente");
          }
        }
      );

    }
    else{

    }
    */
  }



  donwload(ArchivosComplementario:any):void{

      window.open(environment.BASE_URL_PUBLIC+"/"+ArchivosComplementario.strUrl, '_blank');


  }
  eliminar(intArchivoComplementarioID:number){

    this.archivoComplementarioService.Eliminar(intArchivoComplementarioID).subscribe(
      result=>{
        console.log(result);
          if(result.code==200)
          {
            this.dialogSerive.openMsgSuccessDialog("Aviso","Se elimino correctamente").afterClosed().subscribe(res=>{
              this.pageLoad();
            });

          }
          else{
            this.dialogSerive.openMsgErrorDialog(result.message).afterClosed().subscribe(res=>{

            });

          }
      },
      (err)=>{
        console.log(err);
      }
    );
  }


}
