<div class="min-h-screen bg-gray-100">
    <div class="container py-8 grid grid-cols-5 gap-6">
      <aside class="aside-curso">
        <h1 class="font-bold text-lg mb-4">Edición del curso</h1>
        <ul class="text-sm text-gray-600">
            <li (click)="showAside(0)" [ngClass]="bInformation?'cursor-pointer leading-7 mb-1 border-l-4 border-indigo-400  pl-2':'cursor-pointer leading-7 mb-1 border-l-4 pl-2'">
                <a>Información del curso</a>
            </li>
            <li (click)="showAside(1)" [ngClass]="bFilesComplementary?'cursor-pointer leading-7 mb-1 border-l-4 border-indigo-400  pl-2':'cursor-pointer leading-7 mb-1 border-l-4 pl-2'">
              <a>Archivos Complementario</a>
            </li>
            <li (click)="showAside(2)" [ngClass]="bLecciones?'cursor-pointer leading-7 mb-1 border-l-4 border-indigo-400  pl-2':'cursor-pointer leading-7 mb-1 border-l-4 pl-2'">
                <a>Lecciones del Curso</a>
            </li>
            <li (click)="showAside(3)"  [ngClass]="bGoals?'cursor-pointer leading-7 mb-1 border-l-4 border-indigo-400  pl-2':'cursor-pointer leading-7 mb-1 border-l-4 pl-2'">
                <a>Metas del curso</a>
            </li>
            <li (click)="showAside(4)"  [ngClass]="bStudents?'cursor-pointer leading-7 mb-1 border-l-4 border-indigo-400  pl-2':'cursor-pointer leading-7 mb-1 border-l-4 pl-2'">
                <a>Estudiantes</a>
            </li>
            <li (click)="showAside(5)" [ngClass]="bSegmentation?'cursor-pointer leading-7 mb-1 border-l-4 border-indigo-400  pl-2':'cursor-pointer leading-7 mb-1 border-l-4 pl-2'">
                <a>Segmentación</a>
            </li>

            <li (click)="publicar()" class="cursor-pointer leading-10 mb-4 border-l-4 pl-2">
              <a>Publicar Curso</a>
            </li>

        </ul>
      </aside>


      <div class="col-span-4 card">
        <main [ngClass]="bInformation?'card-body bg-white':'hidden'">
            <app-course-modify [parametros]="parametros" [Cursos]="Cursos"></app-course-modify>
        </main>

        <main [ngClass]="bFilesComplementary?'card-body  bg-white':'hidden'" >
            <app-supplementary-files [parametros]="parametros" [Cursos]="Cursos"></app-supplementary-files>
        </main>

        <main [ngClass]="bLecciones?'card-body bg-white text-gray-600':'hidden'">
            <app-section [parametros]="parametros" [Cursos]="Cursos"></app-section>
        </main>

        <main [ngClass]="bGoals?'card-body  bg-white text-gray-600':'hidden'">
            <app-goal [parametros]="parametros" [Cursos]="Cursos"></app-goal>
        </main>

        <main [ngClass]="bStudents?'card-body  bg-white text-gray-600':'hidden'">
            <app-students-list [parametros]="parametros" [Cursos]="Cursos" ></app-students-list>
        </main>

        <main [ngClass]="bSegmentation?'card-body  bg-white text-gray-600':'hidden'">
          <app-segmentation [curso]="Cursos"></app-segmentation>
        </main>

        <main [ngClass]="bObservations?'card-body  bg-white text-gray-600':'hidden'">
          <app-observations></app-observations>
        </main>



      </div>
    </div>
    </div>
