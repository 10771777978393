



<div class="bg-gray-100">
  <div class="container mx-auto my-5 p-5">
      <div class="md:flex no-wrap md:-mx-2 ">
          <!-- Left Side -->
          <div class="w-full md:w-4/12 md:mx-2">
              <!-- Profile Card -->
              <div class="bg-white p-3 border-t-4 border-green-400">
                  <div class="image overflow-hidden">
                      <img class="h-auto w-full mx-auto"
                          src=""
                          alt="">
                  </div>
                  <h1 class="text-gray-900 font-bold text-xl leading-8 my-1">{{students.strNombres}} {{students.strApellidoPaterno}} {{students.strApellidoMaterno}}</h1>

                  <ul
                      class="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                      <li class="flex items-center py-3">
                          <span>Estado</span>
                          <span class="ml-auto"><span
                                  class="bg-green-500 py-1 px-2 rounded text-white text-sm">Activo</span></span>
                      </li>
                      <li class="flex items-center py-3">
                          <span>Fecha de Registro</span>
                          <span class="ml-auto"></span>
                      </li>
                  </ul>
              </div>
              <!-- End of profile card -->
              <div class="my-4"></div>
              <!-- Friends card -->

              <!-- End of friends card -->
          </div>
          <!-- Right Side -->
          <div class="w-full md:w-8/12 mx-2 h-full">
              <!-- Profile tab -->
              <!-- About Section -->
              <div class="bg-white p-3 shadow-sm rounded-sm">
                  <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                      <span clas="text-green-500">
                          <svg class="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                              stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                          </svg>
                      </span>
                      <span class="tracking-wide">Información</span>
                  </div>
                  <div class="text-gray-700">
                      <div class="grid md:grid-cols-5 text-sm">
                        <div class="px-2 py-2 font-semibold col-span-2">Nro. Documento</div>
                        <div class="px-2 py-2 col-span-3">{{students.strUsuario}}</div>
                        <div class="px-2 py-2 font-semibold col-span-2">Nombres</div>
                        <div class="px-2 py-2 col-span-3">{{students.strNombres}}</div>
                        <div class="px-2 py-2 font-semibold col-span-2">Apellidos</div>
                        <div class="px-2 py-2 col-span-3">{{students.strApellidoPaterno}} {{students.strApellidoMaterno}}</div>
                        <div class="px-2 py-2 font-semibold col-span-2">Genero</div>
                        <div class="px-2 py-2 col-span-3 ">{{students.strGenero}}</div>
                        <div class="px-2 py-2 font-semibold col-span-2">Nro. Contacto</div>
                        <div class="px-2 py-2 col-span-3">{{students.strCelular}}</div>
                        <div class="px-2 py-2 font-semibold col-span-2">Dirección</div>
                        <div class="px-2 py-2 col-span-3">{{students.strDireccion}}</div>

                        <div class="px-2 py-2 font-semibold col-span-2">Email.</div>
                        <div class="px-2 py-2 col-span-3">
                            <a class="text-blue-800">{{students.strCorreo}}</a>
                        </div>
                        <div class="px-2 py-2 font-semibold col-span-2">Fecha de nacimiento</div>
                        <div class="px-2 py-2 col-span-3">{{students.dteFechaNacimiento | date : 'dd/MM/yyyy' }}</div>
                      </div>
                  </div>

              </div>
              <!-- End of about section -->

              <div class="my-4"></div>

              <!-- Experience and education -->

              <!-- End of profile tab -->
          </div>
      </div>
  </div>
</div>
