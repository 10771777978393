import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { SecurityService } from '../../system/security.service';


@Injectable({
  providedIn: 'root'
})
export class PDFJsService {

  constructor(
    private securityService: SecurityService
  ) { }

  GenerarCertificado(FileName:string,CursoTitle:string,TipoConstancia:string){
    debugger;
    const doc = new jsPDF('l', 'mm', [297,210]);
    doc.setFont("RobotoSlab-Regular","normal");

    var PTSans = "AAEAAAAUAQA...";
    doc.addFileToVFS("RobotoSlab-Regular.ttf", "assets/fonts/Roboto_Slab/static/RobotoSlab-Regular.ttf");
    doc.addFont('RobotoSlab-Regular.ttf', 'RobotoSlab-Regular', 'normal');

   // doc.setFont('PTSans'); // set font

    const NOMBRES_APELLIDOS = this.securityService.GetNombres().toUpperCase() + " " + this.securityService.GetApellidoPaterno().toUpperCase() + " " + this.securityService.GetApellidoMaterno().toUpperCase();

    doc.addImage(TipoConstancia,"JPEG",0,0,297,210);
    // doc.addImage('assets/img/certificado.png',"JPEG",0,0,297,210);

    doc.setFontSize(25);
    doc.setFont('bold');
    doc.text(NOMBRES_APELLIDOS,174,84,{
      align:'center'
    });

    doc.setFontSize(20);
    doc.setFont('bold');
    doc.text(CursoTitle.toUpperCase(),174,125,{
      align:'center'
    });


    doc.save(FileName+".pdf");
  }
}
