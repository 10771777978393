import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LeccionPreguntasService } from 'src/app/Services/admin/leccion-preguntas.service';
import { DialogService } from 'src/app/Services/comun/dialog.service';
import { ComentariosService } from 'src/app/Services/estudiante/comentarios.service';
import { SecurityService } from 'src/app/system/security.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';

@Component({
  selector: 'app-preguntas-respuestas',
  templateUrl: './preguntas-respuestas.component.html',
  styleUrls: ['./preguntas-respuestas.component.scss']
})
export class PreguntasRespuestasComponent implements OnInit {

  LeccionPregunta:any;
  constructor(
    private utilsService : UtilsService,
    private securityService:SecurityService,
    private comentariosService: ComentariosService,
    public dialogRef: MatDialogRef<PreguntasRespuestasComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog : DialogService
  ) {
    this.LeccionPregunta = data;
  }

  ngOnInit(): void {

  }

  responder(value:string):void{
    if(value==null){

      return;
    }

    if(value==""){

      return;
    }
    const oBE = {
      strTitulo :  'Respuesta', //(document.getElementById("strTitulo") as HTMLInputElement).value),
      strComentario : value, // ((document.getElementById("strComentario") as HTMLInputElement).value),
      intLeccionComentarioPadreID : this.LeccionPregunta.intLeccionComentarioID,
      intLeccionID : this.LeccionPregunta.intLeccionID,
      //strUsuarioID : this.securityService.GetUsuarioID()
      strUsuarioID : this.utilsService.convertBase64Url(this.securityService.GetUsuarioID())
    };

    this.dialog.openConfirmDialog("¿Desea registrar la respuesta?")
    .afterClosed().subscribe(res=>{

        if(res){
          this.comentariosService.postComentarios(oBE).subscribe(result=>{
            console.log(result);
            if(result.code==200){

            }
            else{
              this.dialog.openMsgErrorDialog(result.message);
            }
          },(err)=>{
            console.log(err);
          });
        }
    });


  }
}
