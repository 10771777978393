
    <section class="min-h-screen flex items-stretch text-white ">
        <div class="md:flex w-4/6 hidden bg-gray-500 bg-no-repeat bg-cover relative items-center" style="background-image: url(assets/img/fondo-login.png);">
            <div class="absolute bg-black opacity-60 inset-0 z-0"></div>
            <div class="w-full px-24 z-10">
                <h1 class="text-5xl font-bold text-left tracking-wide">Capacítate ROM</h1>
                <p class="text-3xl my-4">Bienvenido a una nueva cultura de aprendizaje virtual.</p>
            </div>
        </div>
        <div class="lg:w-2/6 w-full flex items-center justify-center text-center  px-0 z-0">
            <div class="absolute md:hidden z-10 inset-0 bg-gray-500 bg-no-repeat bg-cover items-center" style="background-image: url(assets/img/fondo_login_mobile.png);height: 100%;background-position: center;background-repeat: no-repeat; background-size: cover;">
                <div class="absolute bg-black opacity-60 inset-0 z-0 "></div>
            </div>

            <div class="w-full  z-20  md:mt-0 ">
              <div class="mx-auto">
                  <img  class="mx-auto w-3/6 hidden md:block"  src="assets/img/rom_login.png"   />
                  <img  class="mx-auto w-5/6 block md:hidden"  src="assets/img/rom_login_mobile.png"   />
              </div>


              <form action="" class="w-full px-4 md:px-8 mx-auto" >
                  <div class="pb-2 pt-4">
                      <input #UserName class="text-gray-900 w-full p-4 h-12 text-lg rounded-lg border-2 border-gray-300 focus:outline-none focus:ring focus:border-blue-300" type="text" placeholder="Usuario" >
                  </div>
                  <div class="pb-2 pt-4">
                      <input #Password class="text-gray-900 w-full p-4 h-12 text-lg rounded-lg border-2 border-gray-300 focus:outline-none focus:ring focus:border-blue-300" type="password" placeholder="Contraseña">
                  </div>

 
               <div *ngIf="recapchat_key" class="pb-2 pt-4" style="transform:scale(0.9);transform-Origin:0">
                  <re-captcha (resolved)="resolved($event)"
                  siteKey="{{recapchat_key}}">
                  </re-captcha>
                </div> 


                  <div class="pb-2 pt-4">
                      <button (click)="login(UserName.value,Password.value)" class="btn-rom-magenta w-full   text-lg">Iniciar Sesión</button>
                  </div>


              </form>

            </div>

        </div>
    </section>



<style>
  div.rc-anchor-normal::after{
    width: 100% !important;
  }
</style>
