<input type="checkbox" id="sidebar-toggle">
<div class="sidebar overflow-y-scroll">
    <div class="sidebar-brand">
        <div class="brand-flex">
            <img src="assets/img/logo_fondo_azul.png" alt="">

            <div class="brand-icons">
             <!--   <i class="fa fa-bell"></i> -->

            </div>
        </div>
    </div>

    <app-sidebar></app-sidebar>

</div>
<div class="main-content" >

    <header id="header">
        <div class="menu-toggle">
            <label for="sidebar-toggle">
              <i class="fa fa-bars" aria-hidden="true"></i>
            </label>

        </div>
        <div class="flex flex-row">
          <div class="header-negocios">
            <!-- This example requires Tailwind CSS v2.0+ -->
          <div class="relative inline-block text-left">
            <!--
            <div>
              <button (click)="mostrarNegocios()" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
                Todos los negocios

                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>


            <div *ngIf="bmostrarNegocios" class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
              <div class="py-1" role="none">

                <a  class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-0">Account settings</a>
                <a  class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-1">Support</a>
                <a  class="text-gray-700 block px-4 py-2 text-sm" role="menuitem" tabindex="-1" id="menu-item-2">License</a>

              </div>
            </div>
          -->
          </div>
        </div>



        <div class="header-icons">
          <!--
          <i class="fa fa-search" aria-hidden="true"></i>
          <i class="fa fa-bookmark" aria-hidden="true"></i>
          -->
          <div class="py-5">
            <h3 class="text-xs font-semibold text-right text-color-rom-azul"> {{nombres}}</h3>
            <span class="text-right text-color-rom-azul">{{correo}}</span>
        </div>
        </div>
        </div>

    </header>
    <main id="main" class="bg-gray-100">
      <router-outlet></router-outlet>
    </main>
</div>
<label for="sidebar-toggle" class="body-label"></label>









