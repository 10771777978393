<!-- component -->


    <div class="bg-white min-w-1xl flex flex-col">
      <div class="px-12 py-5">
        <h2 class="text-gray-800 text-3xl font-semibold text-center">
          ¡Tu opinión nos importa!
        </h2>
      </div>
      <div class="bg-gray-200 w-full flex flex-col items-center">
        <div class="flex flex-col items-center py-6 space-y-3">
          <span class="text-lg text-gray-800">
            ¿Cómo calificarías este curso?
          </span>
          <div class="flex space-x-3 w-8/12 md:w-full">
            <svg (mouseenter) ="mouseEnter(1)" [ngClass]="valor>=1? 'w-12 h-12 text-yellow-500 cursor-pointer': 'w-12 h-12 text-gray-500 cursor-pointer'"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg (mouseenter) ="mouseEnter(2)"  [ngClass]="valor>=2? 'w-12 h-12 text-yellow-500 cursor-pointer': 'w-12 h-12 text-gray-500 cursor-pointer'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg (mouseenter) ="mouseEnter(3)"  [ngClass]="valor>=3? 'w-12 h-12 text-yellow-500 cursor-pointer': 'w-12 h-12 text-gray-500 cursor-pointer'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg (mouseenter) ="mouseEnter(4)" [ngClass]="valor>=4? 'w-12 h-12 text-yellow-500 cursor-pointer': 'w-12 h-12 text-gray-500 cursor-pointer'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <svg (mouseenter) ="mouseEnter(5)" [ngClass]="valor==5? 'w-12 h-12 text-yellow-500 cursor-pointer': 'w-12 h-12 text-gray-500 cursor-pointer'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
          </div>
        </div>
        <div class="w-3/4 flex flex-col">
          <textarea #strComentario rows="3" class="p-4 text-gray-500 rounded-xl resize-none" placeholder="Deja un mensaje, si quieres"></textarea>
          <button (click)="ValorarCurso(strComentario.value)" class="btn-rom-magenta py-2 my-2">Califica ahora</button>
        </div>
      </div>
      <div class="h-10 flex items-center justify-center">
        <a [mat-dialog-close]="true" class="text-gray-600 cursor-pointer">Quizás más tarde</a>
      </div>
    </div>



