import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SegmentacionService } from 'src/app/Services/admin/segmentacion.service';
import { ExcelJSService } from 'src/app/Services/comun/excel-js.service';
import { SecurityService } from 'src/app/system/security.service';
import { ReportesService } from '../../../../../Services/admin/reportes.service';

@Component({
  selector: 'app-usabilidad',
  templateUrl: './usabilidad.component.html',
  styleUrls: ['./usabilidad.component.scss']
})
export class UsabilidadComponent implements OnInit {

  form: FormGroup;
  parametros:any[];

  segmentaciones:any[];
  paises:any[];
  empresas:any[];
  negocios:any[];
  cuentas:any[];
  centroCostos:any[];
  areas:any[];
  puestoTrabajadores: any[];

  constructor(
    private formBuilder: FormBuilder,
    private reporteService:ReportesService,
    private exceljsService:ExcelJSService,
    private segmentacionService : SegmentacionService,
    private securityServices : SecurityService
  ) { }

  ngOnInit(): void {
    this.iniForm();
    this.getApp_BI();
  }



  iniForm():void {
    this.form = this.formBuilder.group({
      codEmpresa : ['00',Validators.required],
      codNegocio:['000',Validators.required],
      puestoTrabajador:[0,Validators.required],
      estadoTrabajador:['',Validators.required]
    });
  }

  getApp_BI():void{

    this.segmentacionService.getSegmentacionAppBi().subscribe(result=>{
       if(result.code==200){
        console.log(result);
        this.paises = result.obj.pais;
        this.empresas = result.obj.empresas.filter(x=>x.codPais=='155');
        this.negocios= result.obj.negocio.filter(x=>x.codPais=='155');
        this.puestoTrabajadores = result.obj.tipoPuesto.filter(x=>x.codPais=='155');
       }
    },(err)=>{
      console.log(err);
    });
  }



  exportar():void{
    var oBE = {
      CODEMPRESA : this.form.controls["codEmpresa"].value,
      CODUNIDADNEGOCIO : this.form.controls["codNegocio"].value,
      IDPUESTOTRABAJADOR : +this.form.controls["puestoTrabajador"].value,
      FLAG: this.form.controls["estadoTrabajador"].value
    };
    console.log("exportar",oBE);
    this.reporteService.usabilidad(oBE).subscribe(resul=>{
      console.log(resul);

        const FechaActual = new Date();
        const AnioActual = FechaActual.getFullYear();
        const MesActual = (FechaActual.getMonth()<=9? '0'+ (FechaActual.getMonth()+1): FechaActual.getMonth() + 1); //FechaActual.getMonth() + 1;
        const DiaActual = (FechaActual.getDate()<=9? '0'+FechaActual.getDate(): FechaActual.getDate());
        const HoraActual = (FechaActual.getHours()<=9? '0'+FechaActual.getHours(): FechaActual.getHours()); //FechaActual.getHours();
        const MinutoActual = (FechaActual.getMinutes()<=9? '0'+FechaActual.getMinutes(): FechaActual.getMinutes()); //FechaActual.getMinutes();
        const SegundosActual = (FechaActual.getSeconds()<=9? '0'+FechaActual.getSeconds(): FechaActual.getSeconds()); //FechaActual.getMinutes(); //FechaActual.getSeconds();


        const Archivo = "ReporteUsabilidad_"+ AnioActual+MesActual+DiaActual+"T"+HoraActual+MinutoActual+SegundosActual;
        const Sheet = "Usabilidad";


        const header = ["DNI","NOMBRES Y APELLIDOS","UNIDAD DE NEGOCIO","PUESTO DE TRABAJO","EMPRESA","PAIS","NEGOCIO","CUENTA","FECHA DE INGRESO","ESTADOALUMNO"];
        let body = [];
        for (let index = 0; index < resul.result.length; index++) {
          const datos = resul.result[index];

          var DNI = datos.dni;
          var NOMBRES = datos.nombres;
          var UNIDAD_NEGOCIO = datos.dE_UNIDADNEGOCIO;
          var PUESTO = datos.puestotrabajador;
          var EMPRESA = datos.empresa;
          var PAIS = datos.pais;
          var NEGOCIO = datos.negocio;
          var CUENTA = datos.cuenta;
          var FECHA_INGRESO = datos.fechA_REGISTRO;
          var ESTADOALUMNO = datos.estadoalumno;
          var datoarray = [DNI,NOMBRES,UNIDAD_NEGOCIO,PUESTO,EMPRESA,PAIS,NEGOCIO,CUENTA,FECHA_INGRESO,ESTADOALUMNO]
          body.push(datoarray)
        }


        this.exceljsService.DescargarExcelGenerico(Archivo,Sheet,header,body);

    },(err)=>{
      console.log(err);
    });
  }
}
