import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { CursosService } from 'src/app/Services/admin/cursos.service';
import { ParametrosService } from 'src/app/Services/admin/parametros.service';
import { Router } from '@angular/router';

import { TabService } from '../../../../../../tabconfig/tab.service';
import { SecurityService } from '../../../../../../system/security.service';
import { CourseEditComponent } from '../course-edit/course-edit.component';
import { Tab } from 'src/app/tabconfig/tab.model';
import { DialogService } from 'src/app/Services/comun/dialog.service';

@Component({
  selector: 'app-course-create',
  templateUrl: './course-create.component.html',
  styleUrls: ['./course-create.component.scss']
})
export class CourseCreateComponent implements OnInit {

  @Input() data;

  form: FormGroup;
  parametros:any[];

  formSubmitted:boolean;


  constructor(
    private formBuilder: FormBuilder,
    private cursoService: CursosService,
    private parametrosService:ParametrosService,

    private router: Router,
    private tabService: TabService,
    private securityService:SecurityService,
    private dialogServices: DialogService
  ) { }

  ngOnInit(): void {
    this.iniForm();
    this.pageLoad();

  }

  pageLoad():void {
    this.parametrosService.ObtenerParametros().subscribe(result=>{
      this.parametros = result.result;
      console.log("parametros",this.parametros);
    }, (err) =>{
      console.log("error",err);
    });
  }

  iniForm():void {
    this.form = this.formBuilder.group({
      intCursoID : [0],
      strTitulo : [null,Validators.required],
      strSlug : [null],
      strSubTitulo : [null],
      strDescripcion : [null,Validators.required],
      intNivelesID : [1,Validators.required],
      intCategoriaID : [1,Validators.required],
      intDuracionValue : [null,Validators.required],
      intDuracionID : [1,Validators.required],
      intCostoID:[1,Validators.required],
      intEstadoID:[1]
    });
  }

  RegistrarCurso():void {
    this.formSubmitted = true;
    if(this.form.invalid) return;


    var Entity = {
      intCursoID : 0,
      strTitulo : this.form.controls["strTitulo"].value,
      strSubTitulo : this.form.controls["strSubTitulo"].value,
      strDescripcion : this.form.controls["strDescripcion"].value,
      intNivelesID : +this.form.controls["intNivelesID"].value,
      intCategoriaID : +this.form.controls["intCategoriaID"].value,
      intDuracionValue : +this.form.controls["intDuracionValue"].value,
      intDuracionID : +this.form.controls["intDuracionID"].value,
      intCostoID : +this.form.controls["intCostoID"].value,
      strSlug : this.form.controls["strSlug"].value,
      intEstadoID : +this.form.controls["intEstadoID"].value,
      intProfesorID: +this.securityService.GetProfesorID()
    };
    this.cursoService.postCurso(Entity).subscribe(result=>{
      if(result.code==200){


        this.dialogServices.openMsgSuccessDialog("Aviso", "Se registró correctamente").afterClosed().subscribe(res =>
          {
            if(res){
              this.tabService.removeTab(this.data.id -1);
        this.tabService.addTab(new Tab(CourseEditComponent, "Editar Curso", { parent: 'CoursesListComponent', element: result.obj }));
            }
          }
        );

        //this.router.navigate(['/instructor/courses/edit',result.object.slug]);

      }
      else{

        this.dialogServices.openMsgErrorDialog(result.message).afterClosed().subscribe(res =>
          {
            if(res){

            }
          }
        );


      }
    },(err)=>{
      console.log(err);
    });

  }
}
