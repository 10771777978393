<div>
    <section>
      <h1 class="text-2xl font-bold">METAS DEL CURSO</h1>
      <hr class="mt-2 mb-6">
      <article *ngFor="let item of metas" class="card mb-4">
        <div class="card-body bg-gray-100 ">
          <header class="flex justify-between">
            <h1>{{item.strDescripcion}}</h1>
            <div>
              <i (click)="eliminar(item.intMetasID)"  class="fa fa-trash text-red-500 cursor-pointer ml-2"></i>
            </div>
          </header>
        </div>
    </article>



  <article class="card">
    <div class="card-body bg-gray-100">
      <form [formGroup]="formMetas" autocomplete="off">
        <input formControlName="strDescripcion" type="text" class="form-input w-full" placeholder="Agregar el nombre de la meta">
          <div class="flex justify-end mt-2">
            <button (click)="registrar()" type="submit" class="btn-rom-azul">Agregar meta</button>
          </div>
      </form>
     </div>
  </article>
  </section>

  <app-requirement [parametros]="parametros" [Cursos]="Cursos" ></app-requirement>

  </div>
