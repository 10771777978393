import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LeccionPreguntasService } from 'src/app/Services/admin/leccion-preguntas.service';
import { ComentariosService } from 'src/app/Services/estudiante/comentarios.service';
import { SecurityService } from 'src/app/system/security.service';
import { PreguntasRespuestasComponent } from '../preguntas-respuestas/preguntas-respuestas.component';
import { UtilsService } from 'src/app/Services/Utils/utils.service';

@Component({
  selector: 'app-mail-box',
  templateUrl: './mail-box.component.html',
  styleUrls: ['./mail-box.component.scss']
})
export class MailBoxComponent implements OnInit {

  bPreguntas:boolean;
  bNotificaciones:boolean;
  preguntas:any[];
  preguntasOriginal:any[];
  constructor(
    private utilsService : UtilsService,
    private comentariosService: ComentariosService,
    private securityService:SecurityService,
    public dialog: MatDialog,
  ) {
    this.bPreguntas = true;
    this.bNotificaciones = false;
  }

  ngOnInit(): void {
    this.getPreguntasPorProfesor();
  }

  getPreguntasPorProfesor(){
    //const intProfesorID = +this.securityService.GetProfesorID();
    const strProfesorID = this.utilsService.convertBase64Url(this.securityService.GetUsuarioID());
    this.comentariosService.getLeccionPreguntasProfesor(strProfesorID).subscribe(result=>{
      if(result.code==200){

        this.preguntasOriginal = result.result;
        this.preguntas = result.result.reduce((r,{dtFechaRegistro})=>{
          if(!r.some(o=>o.dtFechaRegistro==dtFechaRegistro)){
            r.push({
              dtFechaRegistro,
                groupItem:result.result.filter(v=>v.dtFechaRegistro==dtFechaRegistro)

            });
        }
        return r;
        },[]);

        console.log(this.preguntas);

      }
      else{

      }
    },
    (err)=>{
      console.log(err);
    });
  }

  PreguntasRespuestas(data:any):void{
    const dialogRef = this.dialog.open(PreguntasRespuestasComponent,{
      data:  data,
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }


  showAside(index: number): void {
    switch (index) {
      case 0:
        this.bPreguntas = true;
        this.bNotificaciones = false;
        break;

      case 1:
        this.bPreguntas = false;
        this.bNotificaciones = true;
        break;


      default:
        this.bPreguntas = true;
        this.bNotificaciones = false;
        break;
    }
  }

  buscador(value:string):void{

    if(this.preguntasOriginal!=null){

      let filtro = this.preguntasOriginal.filter(item=>item.strUsuario.toLowerCase().includes(value.toLowerCase()));

        this.preguntas = filtro.reduce((r,{dtFechaRegistro})=>{
          if(!r.some(o=>o.dtFechaRegistro==dtFechaRegistro)){
            r.push({
              dtFechaRegistro,
                groupItem:filtro.filter(v=>v.dtFechaRegistro==dtFechaRegistro)

            });
        }
        return r;
        },[]);

    }



  }
}
