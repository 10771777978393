import { Component, Input, OnInit } from '@angular/core';
import { MetasService } from 'src/app/Services/admin/metas.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DialogService } from 'src/app/Services/comun/dialog.service';


@Component({
  selector: 'app-goal',
  templateUrl: './goal.component.html',
  styleUrls: ['./goal.component.scss']
})
export class GoalComponent implements OnInit {

  @Input() parametros;
  @Input() Cursos;

  metas:any[];

  formMetas: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private metasService : MetasService,
    private dialogSerive: DialogService
  ) { }

  ngOnInit(): void {
    this.getMetas();
    this.initFormMetas();
  }

  initFormMetas() : void{
    this.formMetas = this.formBuilder.group({
      intMetasID : [null,Validators.required],
      intCursoID:[null,Validators.required],
      strDescripcion:[null,Validators.required]
    });
  }

  getMetas(){
    this.metasService.getMetas(this.Cursos.intCursoID).subscribe(result=>{
      if(result.code==200){
        this.metas = result.result;
      }
    },(err)=>{
      console.log(err);
    });
  }

  registrar(){
    const oBE = {
      "intMetasID" : 0,
      "intCursoID":this.Cursos.intCursoID,
      "strDescripcion": this.formMetas.controls["strDescripcion"].value
    }
    this.metasService.postMetas(oBE).subscribe(result=>{
      if(result.code==200){
        this.formMetas.controls["strDescripcion"].setValue(null);
        this.getMetas();
        this.dialogSerive.openMsgSuccessDialog("Aviso","Se registró correctamente").afterClosed().subscribe(res=>{

        });
      }
      else{
        this.dialogSerive.openMsgErrorDialog(result.message).afterClosed().subscribe(res=>{

        });
      }
    },(err)=>{
      console.log(err);
    });
  }

  eliminar(intMetasID:number){
    this.metasService.deleteMetas(intMetasID).subscribe(result=>{
      if(result.code==200){
        this.getMetas();
        this.dialogSerive.openMsgErrorDialog(result.message).afterClosed().subscribe(res=>{

        });
      }
      else{
        this.dialogSerive.openMsgErrorDialog(result.message).afterClosed().subscribe(res=>{

        });
      }
    },(err)=>{
      console.log(err);
    });
  }
}
