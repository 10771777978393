import { Component, Input, OnInit } from '@angular/core';
import { SegmentacionService } from '../../../../../../Services/admin/segmentacion.service';

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.scss']
})
export class EmpresasComponent implements OnInit {

  @Input() pais;
  @Input() empresa;
  @Input() negocios;
  @Input() cuentas;
  @Input() centroCostos;
  @Input() areas;

  @Input() curso;

  bNegocio:boolean;
  constructor(
    private segmentacionService: SegmentacionService
  ) {
    this.bNegocio = false;
  }

  ngOnInit(): void {
  }

  mostrarNegocio(CodPais:string,codEmpresa){
    this.bNegocio = !this.bNegocio;

    if(this.bNegocio){
      this.getUnidadNegocio(CodPais,codEmpresa);
    }
  }

  onCheckboxChange(value,obj):void{

    if (value.target.checked) {
      var Entity = {
        intCursoID : this.curso.intCursoID,
        intTipo: 2,
        strSegmentacion: obj.codEmpresa,
        intAccion: 1
      };
       this.segmentacionService.postSegmentacion(Entity).subscribe(result=>{
         if(result.code==200){

         }
         else{

         }
       },(err)=>{
         console.log(err);
       });

    } else {

      var _Entity = {
        intCursoID : this.curso.intCursoID,
        intTipo: 2,
        strSegmentacion: obj.codEmpresa,
        intAccion: 0
      };
       this.segmentacionService.postSegmentacion(_Entity).subscribe(result=>{
         if(result.code==200){

         }
         else{

         }
       },(err)=>{
         console.log(err);
       });

    }

  }

  getUnidadNegocio(CodPais:string,codEmpresa):void{
    this.segmentacionService.getSegmentacionAppBiUnidadNegocio(CodPais,codEmpresa,this.curso.intCursoID).subscribe(result=>{

      if(result.code==200){
        this.negocios = result.result;
      }

    }, (err)=>{
      console.log(err);
    });
  }
}
