
  <nav class="fixed top-0 left-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-0 bg-white border-r overflow-y-auto ">
    <div class="flex items-center mb-8 px-6">
      <a (click)="redirect(1)" class="mr-auto text-3xl font-bold leading-none"  ><img class="h-12" src="assets/img/ROM.png" alt="" width="auto"></a>
      <button (click)="closeMenuMobile()" class="navbar-close">
        <svg class="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
    </div>
    <div class="">
      <div class="block px-4 pt-2 text-xs bg-gray-100 text-gray-900">
        <div class="flex items-center">
          <div>
           <img class="h-16 w-16 rounded-full object-cover"
             src="https://ui-avatars.com/api/?name={{nombre}}+{{apellidoPaterno}}&amp;color=7F9CF5&amp;background=EBF4FF"

           />
          </div
          >
          <div class="px-3 py-6">
            <div class="font-bold text-xl">{{nombres}}</div>
            <div>{{correo}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="border-t border-gray-200"></div>
     <!--  <a (click)="redirect(2)" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer">Mi Perfil</a>-->
      <!-- <a (click)="redirect(3)" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer">Mis Aprendisajes</a>-->
      <!-- <div class="border-t border-gray-200 pt-1 mt-1"></div> -->
      <!-- <a (click)="redirect(4)" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer">Mensajes</a> -->
      <!-- <div class="border-t border-gray-200 pt-1 mt-1"></div>-->
      <!-- <a (click)="redirect(5)" class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer">Ayuda</a> -->

      <a (click)="redirect(2)"   class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer">Mis Cursos</a>
      <div class="border-t border-gray-200"></div>
      <a (click)="redirect(3)"   class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer">
        <p class="absolute bg-color-rom-azul justify-center rounded-full px-1 mr-4 text-white text-xs" style="right:0px;" >{{intTotalPreguntasNoLeidos}}</p>
        Mis Preguntas
      </a>

      <div class="border-t border-gray-200 pt-1 mt-1"></div>
      <a (click)="redirect(4)" *ngIf="intProfesorID>0 || intAdministradorID>0"   class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer">
        <p class="absolute bg-color-rom-azul justify-center rounded-full px-1 mr-4 text-white text-xs" style="right:0px;" >{{intTotalPreguntasNoLeidosProfesor}}</p>
        Administración
      </a>
      <div *ngIf="intProfesorID>0 || intAdministradorID>0" class="border-t border-gray-200 pt-1 mt-1"></div>

    </div>
    <div class="mt-auto">
      <div class="pt-6 w-full text-center">
        <a (click)="CerrarSesion()"  class="btn-rom-magenta cursor-pointer" >Cerrar Sesión</a></div>
      <p class="my-4 text-xs text-center text-gray-400">
        <span>&copy; 2020 All rights reserved.</span>
      </p>
      <div class="text-center">

       </div>
    </div>
  </nav>




