import { Component, ElementRef, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { CursosService } from 'src/app/Services/estudiante/cursos.service';
import { SecurityService } from 'src/app/system/security.service';
import { environment } from 'src/environments/environment';
import { CommentsComponent } from '../comments/comments.component';
import {MatDialog} from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { RatingComponent } from '../rating/rating.component';
import { PDFJsService } from '../../../../Services/comun/pdfjs.service';
import { DialogService } from 'src/app/Services/comun/dialog.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { UtilsService } from 'src/app/Services/Utils/utils.service';

@Component({
  selector: 'app-course-content',
  templateUrl: './course-content.component.html',
  styleUrls: ['./course-content.component.scss']
})
export class CourseContentComponent implements OnInit {

  dropdown:boolean;

  detalle : boolean;
  pregunta : boolean;
  tareas : boolean;
  materiales: boolean;
  evaluacion:boolean;

  registroNuevaRpta:boolean=false;
  msgRegistroCuestionario:string="";
  enviarRespuesta:boolean=false;

  curso:any;
  leccionActual:any;
  slug:string;
  BASE_URL_PUBLIC:string;
  html:any;

  form: FormGroup;
  userGroup = {};

  primera:boolean;
  loading:boolean = true;
  numIntento: number = 0;

  constructor(
    private utilsService : UtilsService,
    private CursosService:CursosService,
    private rutaActiva: ActivatedRoute,
    private dialogSerive: DialogService,

    private securityService:SecurityService,
    private sanitized: DomSanitizer,
    public dialog: MatDialog,
    private PDFJsService: PDFJsService
  ) {
    this.slug = this.rutaActiva.snapshot.params.slug;
    this.BASE_URL_PUBLIC = environment.BASE_URL_PUBLIC;
    this.primera = true;
  }

  ngOnInit(): void {

    this.detalle = true;    
    this.getCursoPorSlug(0);    

  }

  showDropdown(){
    this.dropdown = !this.dropdown;
  }

  active(value:number):void{

    switch (value) {
      case 1:
        this.detalle = true;
        this.materiales = false;
        this.evaluacion = false;
        this.pregunta = false;
        this.tareas = false;


      break;
      case 2:
        this.detalle = false;
        this.materiales = true;
        this.evaluacion = false;
        this.pregunta = false;
        this.tareas = false;


      break;
      case 3:
        this.detalle = false;
        this.materiales = false;
        this.evaluacion = true;
        this.pregunta = false;
        this.tareas = false;

        this.validarRegistroCuestionario();

      break;
      case 4:
        this.detalle = false;
        this.materiales = false;
        this.evaluacion = false;
        this.pregunta = true;
        this.tareas = false;

      break;
      case 5:
        this.detalle = false;
        this.materiales = false;
        this.evaluacion = false;
        this.pregunta = false;
        this.tareas = true;

      break;

      default:
        this.detalle = true;
        this.materiales = false;
        this.evaluacion = false;
        this.pregunta = false;
        this.tareas = false;

      break;
    }
  }

  validLesson(){
    //alert("Realize su evaluación por favor");
  }

  getCursoPorSlug(intLeccionID:number):void {
    const intUsuarioID = this.utilsService.convertBase64Url(this.securityService.GetUsuarioID());
    this.CursosService.getCursoPorSlugVista(this.slug,intLeccionID,intUsuarioID).subscribe(result=>{
      this.loading = false;
      if(result.code==200){
        this.curso = result.obj;
        console.log(this.curso);


        this.curso.eL_Leccion_DTO_Actual.striframe = this.sanitized.bypassSecurityTrustHtml(this.curso.eL_Leccion_DTO_Actual.striframe);
        this.leccionActual = this.curso.eL_Leccion_DTO_Actual;

        if(this.primera==true){
          this.primera = false;
          console.log(this.primera);
        }

      }
    },(err)=>{
      console.log(err);
    });
  }

  descargarMaterial(url:any, objrecurso:any):void{
    if(objrecurso.intRecursoID == 5){
      window.open(url, '_blank');
    }else{
      window.open(environment.BASE_URL_PUBLIC+"/"+url, '_blank');
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(CommentsComponent,{
      data:  this.curso,
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);

      this.getCursoPorSlug(this.curso.eL_Leccion_DTO_Actual.intLeccionID);
    });
  }

  verLeccion(leccion:any){
    this.active(1);
    // this.validarRegistroCuestionario();
    // console.log("verLeccion contentido",leccion);
    this.getCursoPorSlug(leccion.intLeccionID);    
  }

  verLeccionID(intLeccionID:any){
    this.active(1);
    // this.validarRegistroCuestionario();
    // console.log("verLeccion contentido",intLeccionID);
    this.getCursoPorSlug(intLeccionID);    
  }
  AsignarLeccionUsuario(value:boolean):void {
    this.getCursoPorSlug(0);
  }

  evaluar():void{
    debugger;
    var ArrayRespuesta = [];
    var msRadio = document.querySelectorAll("input[type=radio]:checked");
    const textareas = document.querySelectorAll('textarea');
    var msCheckBox = document.querySelectorAll("input[type=checkbox]:checked");
    var msCheckBoxTot = document.querySelectorAll("input[type=checkbox]");

    // concatenamos los valores en la cadena
    for (var x = 0; x < msRadio.length; x++) {
     var oBE = {
      intCuestionarioRespuesta: 0,
      intCuestionarioOpcionesID: +msRadio[x]["value"],
      strCuestionarioRespuesta: '',
      intTipoCuestionarioID:1,
      intCuestionarioID:0,
      strUsuarioID: this.utilsService.convertBase64Url(this.securityService.GetUsuarioID()),
      intLeccionID: +this.leccionActual.intLeccionID
     };
     ArrayRespuesta.push(oBE);
    }
    for (var x = 0; x < msCheckBox.length; x++) {
      var oBE = {
        intCuestionarioRespuesta: 0,
        intCuestionarioOpcionesID: +msCheckBox[x]["value"],
        strCuestionarioRespuesta: '',
        intTipoCuestionarioID:2,
        intCuestionarioID:0,
        strUsuarioID: this.utilsService.convertBase64Url(this.securityService.GetUsuarioID()),
        intLeccionID: +this.leccionActual.intLeccionID
       };
       ArrayRespuesta.push(oBE);
     }
    for (var x = 0; x < textareas.length; x++) {
      var oBE = {
       intCuestionarioRespuesta: 0,
       intCuestionarioOpcionesID: 0,
       strCuestionarioRespuesta: textareas[x]["value"],
       intTipoCuestionarioID:3,
       intCuestionarioID:+textareas[x].name,
       strUsuarioID: this.utilsService.convertBase64Url(this.securityService.GetUsuarioID()),
       intLeccionID: +this.leccionActual.intLeccionID
      };
      ArrayRespuesta.push(oBE);
     }
     this.dialogSerive.openConfirmDialog("¿Seguro que desea enviar su respuesta?").afterClosed().subscribe(res=>{
      if(res){
        debugger;
          this.CursosService.PostEvaluacion(ArrayRespuesta).subscribe(result=>{
            if(result.obj.doNota<result.obj.doNotaMinima){
              this.dialogSerive.openMsgErrorDialog("Su evaluación es de " + result.obj.doNota + "% y se necesita tener como minimo una nota de "+ result.obj.doNotaMinima+ "%")

              //this.numIntento = this.numIntento + 1;
              //if(this.numIntento < 3){
               // this.dialogSerive.openMsgErrorDialog("Intento " + this.numIntento +". Su evaluación es de " + result.obj.doNota + "% y se necesita tener como minimo una nota de "+ result.obj.doNotaMinima+ "%")
             // }
              //else{
             //   this.dialogSerive.openMsgErrorDialog("¡Has alcanzado el límite de intentos permitidos! Para volver a realizar el contenido obligatorio por favor refrescar la página.")
            //    document.getElementById("btnEnviarRespuesta").setAttribute('disabled', 'disabled');
             // }
            }
            console.log("Resultado evaluacion",result);

            this.validarRegistroCuestionario();
            this.RegistroFinalizacionCurso();

        }, (err)=>{
          console.log(err);
        });
      }
     },(err)=>{
       console.log(err);
     });

  }

  validarRegistroCuestionario(){        
 
    var oBE = {            
      //intUsuarioID: +this.securityService.GetUsuarioID(),
      strUsuarioID: this.utilsService.convertBase64Url(this.securityService.GetUsuarioID()),
      intLeccionID: +this.leccionActual.intLeccionID
     };
    
    this.CursosService.PostValidacionTerminoCuestionario(oBE).subscribe(result=>{
      if(result.code==200){        

          if(result.obj.strExisteEvaluacion === "S"){

            this.msgRegistroCuestionario = "EL CUESTIONARIO YA SE ENCUENTRA FINALIZADO";
            this.registroNuevaRpta = true;
            this.enviarRespuesta = false;
          }
          else{
            this.msgRegistroCuestionario = "";
            this.registroNuevaRpta = false;
            this.enviarRespuesta = true;
          }
      }
      else{
          this.msgRegistroCuestionario = "";
          this.registroNuevaRpta = false;
          this.enviarRespuesta = true;
      }

      this.getCursoPorSlug(oBE.intLeccionID);

    },(err)=>{
      console.log(err);
    });         
  }

  reseteoRespuestaCuestionario(){
    var oBE = {            
      //intUsuarioID: +this.securityService.GetUsuarioID(),
      strUsuarioID: this.utilsService.convertBase64Url(this.securityService.GetUsuarioID()),
      intLeccionID: +this.leccionActual.intLeccionID
     };
    
    this.dialogSerive.openConfirmDialog("¿Seguro que desea realizar nuevamente el cuestionario ?").afterClosed().subscribe(res=>{
          
          if(res){
            this.CursosService.ReseteoRespuestaCuestionario(oBE).subscribe(result=>{
              if(result.code==200){
                if(result.obj === true){
                  this.dialogSerive.openMsgSuccessDialog("","Proceso realizado con exito !!!")
                  this.msgRegistroCuestionario = "";
                  this.registroNuevaRpta = false;
                  this.enviarRespuesta = true;
                }

                this.RegistroFinalizacionCurso();
                this.getCursoPorSlug(oBE.intLeccionID);
                
              }

            }, (err)=>{
              console.log(err);
            });
          }
         },(err)=>{
           console.log(err);
         });            
  }

  RegistroFinalizacionCurso(){    
    var oBE = {            
      //intUsuarioID: +this.securityService.GetUsuarioID(),
      strUsuarioID: this.utilsService.convertBase64Url(this.securityService.GetUsuarioID()),
      intCursoID: +this.curso.intCursoID
     };
         
    this.CursosService.PostRegistroFinalizacionCurso(oBE).subscribe(result=>{
      console.log('Proceso realizado con exito');
    },(err)=>{
      console.log(err);
    }); 

  }

  GenerarConstancia():void{
    debugger;
      const strUsuarioID = this.utilsService.convertBase64Url(this.securityService.GetUsuarioID());
      const intCursoID = +this.curso.intCursoID;
      this.CursosService.getValidarValoracion(intCursoID,strUsuarioID).subscribe(result=>{
        this.loading = false;
        if(result.code==200){
          if( result.obj > 0){
            const FechaActual = new Date();
            const AnioActual = FechaActual.getFullYear();
            const MesActual = (FechaActual.getMonth()<=9? '0'+ (FechaActual.getMonth()+1): FechaActual.getMonth() + 1); //FechaActual.getMonth() + 1;
            const DiaActual = (FechaActual.getDate()<=9? '0'+FechaActual.getDate(): FechaActual.getDate());
            const HoraActual = (FechaActual.getHours()<=9? '0'+FechaActual.getHours(): FechaActual.getHours()); //FechaActual.getHours();
            const MinutoActual = (FechaActual.getMinutes()<=9? '0'+FechaActual.getMinutes(): FechaActual.getMinutes()); //FechaActual.getMinutes();
            const SegundosActual = (FechaActual.getSeconds()<=9? '0'+FechaActual.getSeconds(): FechaActual.getSeconds()); //FechaActual.getMinutes(); //FechaActual.getSeconds();
            const NombreArchivo = this.curso.strSlug + "_" + AnioActual + MesActual + DiaActual + "T" + HoraActual + MinutoActual + SegundosActual;
            debugger;
            this.CursosService.getValidarTipoConstancia(strUsuarioID,intCursoID).subscribe(result=>{
              debugger;
              this.PDFJsService.GenerarCertificado(NombreArchivo,this.curso.strTitulo,result.obj);  
            },(err)=>{
              console.log(err);
            }); 
                   
          }
          else{
            this.dialogSerive.openMsgSuccessDialog("","Debe ingresar una opinión del taller antes de generar la constancia.")
          }
        }
      },(err)=>{
        debugger;
        console.log(err);
      }); 
  }
 // ValidarValoracion(){
   //  const strUsuarioID = this.utilsService.convertBase64Url(this.securityService.GetUsuarioID());
   //  const intCursoID = +this.curso.intCursoID;
   //  var respuesta = true;
  //   this.CursosService.getValidarValoracion(intCursoID,strUsuarioID).subscribe(result=>{
   //    this.loading = false;
    //   if(result.code==200){
   //      debugger;
   //      if( result.obj > 0){
   //        respuesta = true;
    //     }
    //     else{
    //       respuesta = false;
     //    }
    //   }
   //  },(err)=>{
   //    debugger;
   //    console.log(err);
   //  }); 
   //  return respuesta
  // }

 


  valoracion():void{
    const dialogRef = this.dialog.open(RatingComponent,{
      data:  this.curso,
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  onCheckboxRespuesta(value:any):void{
    console.log(value);
  }
}
