<form autocomplete="off" class="w-full" [formGroup]="form">
    <h1 class="text-2xl font-bold">EDITAR  EL CURSO</h1>
    <hr class="mt-2 mb-6">
    <div class="mb-4">
      <label for="title">Título del curso</label>
      <input formControlName="strTitulo" type="text"  class="form-input block w-full mt-1">
    </div>

    <div class="mb-4">
      <label for="subtitle">Subtítulo del curso:</label>
      <input formControlName="strSubTitulo" class="form-input block w-full mt-1" name="subtitle" type="text" id="subtitle">
    </div>

    <div class="mb-4">
      <label for="description">Descripción del curso:</label>
      <textarea formControlName="strDescripcion" class="form-input block w-full mt-1" name="description"  ></textarea>
    </div>

    <div class="grid grid-cols-3 gap-3 mb-4">
      <div>
        <label for="level_id">Niveles:</label>
        <select formControlName="intNivelesID" class="form-input block w-full mt-1">
          <ng-container  *ngFor="let parametro of parametros">
            <option *ngIf="parametro.intGrupo==1000" value="{{parametro.intParametro}}" >{{parametro.strDescripcion}}</option>
          </ng-container>
        </select>
      </div>

      <div>
          <label for="category_id">Categoría:</label>
          <select formControlName="intCategoriaID" class="form-input block w-full mt-1">
            <ng-container  *ngFor="let parametro of parametros">
              <option *ngIf="parametro.intGrupo==500" value="{{parametro.intParametro}}" >{{parametro.strDescripcion}}</option>
            </ng-container>
          </select>
      </div>


      <div>
        <label for="price" class="block text-sm font-medium text-gray-700">Duración</label>
        <div class="mt-1 relative rounded-md">

          <input formControlName="intDuracionValue" type="text" class="form-input block w-full mt-1" placeholder="0">
          <div class="absolute inset-y-0 right-0 flex items-center">
            <select formControlName="intDuracionID" class="form-input block">
              <ng-container  *ngFor="let parametro of parametros">
                <option *ngIf="parametro.intGrupo==600" value="{{parametro.intParametro}}" >{{parametro.strDescripcion}}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>

    </div>

    <div class="grid grid-cols-3 gap-3 mb-4">
      <div>
          <label>Precio:</label>
          <select formControlName="intCostoID" class="form-input block w-full mt-1">
            <ng-container  *ngFor="let parametro of parametros">
              <option *ngIf="parametro.intGrupo==700" value="{{parametro.intParametro}}" >{{parametro.strDescripcion}}</option>
            </ng-container>
          </select>
      </div>
    </div>




    <div class="flex justify-end">
        <button (click)="ActualizarCurso()" class="btn-rom-azul" type="button">Actualizar información</button>
    </div>
  </form>
