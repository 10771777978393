
import {Component, Injectable, Input, OnInit} from '@angular/core';

import { SegmentacionService } from 'src/app/Services/admin/segmentacion.service';
import { DialogService } from 'src/app/Services/comun/dialog.service';
import { SecurityService } from 'src/app/system/security.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-segmentation',
  templateUrl: './segmentation.component.html',
  styleUrls: ['./segmentation.component.scss']
})
export class SegmentationComponent implements OnInit {

  @Input() curso;

  segmentaciones:any[];
  paises:any[];
  empresas:any[];
  negocios:any[];
  cuentas:any[];
  centroCostos:any[];
  areas:any[];

  bPais:boolean;
  bEmpresa:boolean;
  bNegocio:boolean;
  bCuenta:boolean;
  bCentroCosto:boolean;
  bArea:boolean;

  constructor(
    private dialogSerive: DialogService,
    private segmentacionService : SegmentacionService,
    private securityServices : SecurityService
  )
  {
    this.bPais = false;
    this.bEmpresa= false;
    this.bNegocio= false;
    this.bCuenta= false;
    this.bCentroCosto= false;
    this.bArea= false;
  }

  ngOnInit(): void {
    this.PageLoad();
  }

  PageLoad(){
    const IDJerarQuia = +this.securityServices.GetIDJerarquia();

    forkJoin([
      this.segmentacionService.getSegmentacionAppBiPais(this.curso.intCursoID)
    ]).subscribe(data=>{

      let app_bi = data[0];


      if(app_bi.code==200){
        this.paises = app_bi.result;
      }

    }, (err)=>{
      console.log(err);
    });
    /*
    this.segmentacionService.getSegmentacion(IDJerarQuia).subscribe(result=>{
      if(result.code==200){
        this.paises = result.obj.pais;
        this.empresas = result.obj.empresas;
        this.negocios= result.obj.negocio;
        this.cuentas= result.obj.cuenta;
        this.centroCostos= result.obj.centroCosto;
        this.areas= result.obj.area;

      }
    }, (err)=>{
      console.log(err);
    });
    */
  }

  mostrarEmpresa(){
    this.bEmpresa = !this.bEmpresa;
  }



}
