import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AdminLayoutsComponent } from './components/layouts/admin-layouts/admin-layouts.component';
import { SiteLayoutsComponent } from './components/layouts/site-layouts/site-layouts.component';

import { LoginComponent } from './components/pages/login/login.component';
import { ErrorsComponent } from './components/pages/errors/errors.component';
import { CourseContentComponent } from './components/pages/students/course-content/course-content.component';
import { AuthGuard } from './system/auth.guard';

const routes: Routes = [
  {
    path:'',
    component:LoginComponent
  },
  {
    path:'login',
    component:LoginComponent
  },
  {
    path:'error',
    component:ErrorsComponent
  },
  {
    path:'curso/view/:slug',
    canActivate: [AuthGuard],
    component:CourseContentComponent
  },
  {
    path:'',
    canActivate: [AuthGuard],
    component:SiteLayoutsComponent,
    children:[
      {
        path:'',
        redirectTo:'/home',
        pathMatch:'full'
      },
      {
        path:'',
        loadChildren:()=>import('./components/pages/site/site.module').then(m=>m.SiteModule)
      }
    ]
  },
  { path: 'dashboard', loadChildren: () => import('./components/pages/admin/admin.module').then(m => m.AdminModule), component: AdminLayoutsComponent },
  { path:'**', pathMatch:'full', redirectTo:'login'  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true}) ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
