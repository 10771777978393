import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ResultModel } from 'src/app/models/result.model';
import { SecurityService } from 'src/app/system/security.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequisitosService extends AppService {

  constructor(http: HttpClient, securityService:SecurityService) {
    super(http,securityService);
  }

  getRequisito<T>(intCursoID:number):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/requisito/${intCursoID}`;
    return this.get(this.endpoint);
  }

  postRequisito<T>(datos:any):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/requisito`;
    return this.post(this.endpoint,datos);
  }

  putRequisito<T>(datos:any):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/requisito`;
    return this.put(this.endpoint,datos);
  }
  deleteRequisito<T>(intRequisitoID:number):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/requisito/${intRequisitoID}`;
    return this.delete(this.endpoint);
  }
}
