import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'src/app/Services/comun/dialog.service';


@Component({
  selector: 'app-enrolled-detail',
  templateUrl: './enrolled-detail.component.html',
  styleUrls: ['./enrolled-detail.component.scss']
})
export class EnrolledDetailComponent implements OnInit {

  @Input() students;

  constructor(
    private dialogSerive: DialogService
     ) { }

  ngOnInit(): void {
  }

}
