
  <div class="px-4 py-2 w-full flex">

    <!-- remove input checkbox -->
    <!-- <div class="cursor-pointer bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500"> -->
      <!-- <input type="checkbox" class="opacity-0 absolute cursor-pointer" [readonly]="true"> -->
      <!-- (click)="terminarLeccion(leccion)" -->
      <!-- <label>{{leccion.intLeccionAprobada}}</label>
      <svg *ngIf="leccion.intLeccionAprobada>0 || doPorcentaje==100" class="fill-current  w-4 h-4 text-green-600 pointer-events-none cursor-pointer" viewBox="0 0 20 20"><path d="M0 11l2-2 5 5L18 3l2 2L7 18z"/></svg> -->
    <!-- </div> -->
    <div class="w-full">
      <div class="grid grid-cols-1">
          <div *ngIf="leccion.intLeccionID < intLeccionIDActual" (click)="verLeccion()"  class="cursor-pointer px-2 text-color-rom-azul">{{leccion.strDescripcion}}</div>
          <div *ngIf="leccion.intLeccionID == intLeccionIDActual" (click)="verLeccion()"  class="cursor-pointer px-2 text-color-rom-azul">{{leccion.strDescripcion}}</div>
          <div *ngIf="leccion.intLeccionID > intLeccionIDActual" (click)="verLeccion()"  class="cursor-pointer px-2">{{leccion.strDescripcion}}</div>
      </div>
    </div>
    <!-- <style>
      input:checked + svg {
          display: block;
      }
    </style> -->
  </div>



