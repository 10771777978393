<div class="bg-white min-w-1xl flex flex-col">
  <div class="px-12 py-2">
    <h2 class="text-gray-800 text-3xl font-semibold text-center">
      Responder pregunta
    </h2>
  </div>
  <div class="bg-gray-200 w-full flex flex-col items-center">

    <div class="w-3/4 flex flex-col py-2">
      <textarea #strComentario rows="3" class="p-4 text-gray-500 rounded-xl resize-none" placeholder="Deja una respuesta"></textarea>
      <button  (click)="responder(strComentario.value)"  class="btn-rom-magenta py-2 my-2">Responder</button>
    </div>
  </div>
  <div class="h-10 flex items-center justify-center">
    <a [mat-dialog-close]="true" class="text-gray-600 cursor-pointer">Responder más tarde</a>
  </div>
</div>



