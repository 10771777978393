import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ResultModel } from 'src/app/models/result.model';
import { SecurityService } from 'src/app/system/security.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SegmentacionService extends AppService {

  constructor(http: HttpClient, securityService:SecurityService) {
    super(http,securityService);
  }

  getSegmentacionAppBi<T>():Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/app-bi`;
    return this.get(this.endpoint);
  }

  postSegmentacion<T>(datos:any):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/cursos/segmentacion`;
    return this.post(this.endpoint,datos);
  }

  getSegmentacion<T>(intCursoID:number):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/cursos/segmentacion/${intCursoID}`;
    return this.get(this.endpoint);
  }

  getSegmentacionAppBiPais<T>(intCursoID:number):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/app-bi/pais/${intCursoID}`;
    return this.get(this.endpoint);
  }
  getSegmentacionAppBiEmpresa<T>(strCodPais:string, intCursoID:number):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/app-bi/empresa/${strCodPais}/${intCursoID}`;
    return this.get(this.endpoint);
  }

  getSegmentacionAppBiUnidadNegocio<T>(strCodPais:string,strCodEmpresa:string, intCursoID:number):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/app-bi/unidad-negocio/${strCodPais}/${strCodEmpresa}/${intCursoID}`;
    return this.get(this.endpoint);
  }
  getSegmentacionAppBiCuenta<T>(strCodPais:string,strCodEmpresa:string,strUnidadNegocio : string, intCursoID:number):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/app-bi/cuenta/${strCodPais}/${strCodEmpresa}/${strUnidadNegocio}/${intCursoID}`;
    return this.get(this.endpoint);
  }

  getSegmentacionAppBiCentroCosto<T>(strCodPais:string,strCodEmpresa:string,strUnidadNegocio:string,strCodCuenta:string, intCursoID:number):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/app-bi/centro-costo/${strCodPais}/${strCodEmpresa}/${strUnidadNegocio}/${strCodCuenta}/${intCursoID}`;
    return this.get(this.endpoint);
  }


  getSegmentacionAppBiPuestoTrabajo<T>(strCodPais:string,strCodEmpresa:string,strUnidadNegocio:string,strCodCuenta:string, intCursoID:number):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/app-bi/puesto-trabajo/${strCodPais}/${strCodEmpresa}/${strUnidadNegocio}/${strCodCuenta}/${intCursoID}`;
    return this.get(this.endpoint);
  }

}
