<div (click)="OpenLesson()" class="flex justify-between block px-4 py-4 text-sm  font-bold bg-gray-100 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer">
    <div>
        <a class="block text-sm  font-bold text-color-rom-azul hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out cursor-pointer">
            Sección {{item+1}}: {{seccion.strDescripcion}}
        </a>
    </div>
    <div *ngIf="!bOpenLesson"><i class="fa fa-sort-desc"></i></div>
    <div *ngIf="bOpenLesson"><i class="fa fa-sort-asc"></i></div>
</div>

<div *ngIf="bOpenLesson">
  <ul>
    <li class="flex" *ngFor="let leccion of seccion.eL_Leccion_DTO; let i = index">
      <app-course-content-lesson-view
        (vistaLeccion)="verLeccion($event)"
        (LeccionUsuario)="AsignarLeccionUsuario($event)"
        [leccion]="leccion"
        [intLeccionIDActual]="intLeccionIDActual"
        [doPorcentaje]="doPorcentaje"
        [EL_LeccionUsuario_DTO]="EL_LeccionUsuario_DTO"
        [ngClass]="{
          'bg-red-500 font-bold text-color-rom-azul': i === leccionSeleccionadaIndex && leccion.intLeccionID === leccionActual.intLeccionID
        }"
        (click)="seleccionarLeccion(i)"
      ></app-course-content-lesson-view>
    </li>
  </ul>

</div>




