
    <header class="">
      <div class="rounded p-3 mb-1 flex justify-between items-center">
        <div>
          <h1 (click)="mostrarEmpresa(pais.codPais)" class="cursor-pointer"><strong>País: </strong> {{pais.descripcion}}</h1>
        </div>
        <div>
          <label class="flex items-center">
            <span class="inline-flex">
              <input *ngIf="pais.intCursoID" checked type="checkbox" #checkPais  (change)="onCheckboxChange($event,pais)"   class="text-blue-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed">
              <input *ngIf="!pais.intCursoID"   type="checkbox" #checkPais  (change)="onCheckboxChange($event,pais)"   class="text-blue-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 focus:ring-offset-0  disabled:opacity-50 disabled:cursor-not-allowed">
            </span>
          </label>
        </div>
      </div>


    </header>

    <div *ngIf="bEmpresa">
      <div *ngFor="let empresa of empresas;" >
        <article *ngIf="pais.codPais == empresa.codPais" class="card mt-4">
          <div class="card-body">
              <app-empresas [curso]="curso" [pais]="pais" [empresa]="empresa" [negocios]="negocios" [cuentas]="cuentas" [centroCostos]="centroCostos" [areas]="areas"></app-empresas>

          </div>
        </article>
      </div>


    </div>

