<div class="carousel policy-banner">
  <div class="">
        <owl-carousel-o class="policy-banner-options" [options]="customOptionsBanner">
          <ng-container  *ngFor="let parametro of marquesinas; let i = index" >
              <ng-template   carouselSlide>
                <div  class="slider">
                  <img (click)="Redireccion(parametro.strReferencia3)" class="w-full object-cover" src="{{this.BASE_URL_PUBLIC}}/marquesinas/{{parametro.strDescripcion}}" alt=""  />
                </div>
              </ng-template>
          </ng-container>
        </owl-carousel-o>
        <!--
        <input *ngIf="parametro.intGrupo==1500 && i==0" class="carousel-open" type="radio" id="carousel-{{i+1}}" name="carousel" aria-hidden="true" hidden="" checked="checked">
        <input *ngIf="parametro.intGrupo==1500 && i!=0" class="carousel-open" type="radio" id="carousel-{{i+1}}" name="carousel" aria-hidden="true" hidden="">
        <div *ngIf="parametro.intGrupo==1500" class="carousel-item">
          <img *ngIf="parametro.strReferencia3!=null" (click)="Redireccion(parametro.strReferencia3)" src="{{this.BASE_URL_PUBLIC}}/marquesinas/{{parametro.strDescripcion}}">
          <img *ngIf="parametro.strReferencia3==null" src="{{this.BASE_URL_PUBLIC}}/marquesinas/{{parametro.strDescripcion}}">
        </div>
        -->

    <!--
    <label for="carousel-3" class="carousel-control prev control-1">‹</label>
    <label for="carousel-2" class="carousel-control next control-1">›</label>
    <label for="carousel-1" class="carousel-control prev control-2">‹</label>
    <label for="carousel-3" class="carousel-control next control-2">›</label>
    <label for="carousel-2" class="carousel-control prev control-3">‹</label>
    <label for="carousel-1" class="carousel-control next control-3">›</label>
      <ol class="carousel-indicators hidden">
        <ng-container  *ngFor="let parametro of parametros;let i = index">
          <li *ngIf="parametro.intGrupo==1500">
            <label for="carousel-{{i+1}}" class="carousel-bullet">•</label>
          </li>
        </ng-container>

      </ol>
      -->
  </div>
</div>

<section class="bg-white py-0 md:py-24 hidden md:block" >
  <div class="mx-auto">
    <h1 class="mx-auto text-center text-lg md:text-3xl mb-12 text-color-rom-azul font-bold font-roboto-slab">Conoce Capacítate ROM</h1>

    <div class="w-full md:w-10/12   mx-auto px-8 sm:px-8 lg:px-8 grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-x-12 gap-y-8">
      <ng-container  *ngFor="let parametro of parametros">
        <div *ngIf="parametro.intGrupo==1600">
          <div class=" ">
            <div class="w-full">
              <div class="bg-white rounded-lg py-3 text-left items-center">
                <div class="p-0 mb-4">
                  <img class="h-24" src="assets/img/home/{{parametro.strDescripcion}}" />
                </div>
                <div class="p-0 h-10">
                  <h3 class="text-left  text-lg text-color-rom-azul font-bold  leading-8 font-roboto-slab">{{parametro.strValor}}</h3>
                </div>
                <div class="p-0 h-16">
                  <p class="text-left text-base text-color-rom-azul  font-roboto">{{parametro.strReferencia1}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </ng-container>
    </div>

  </div>
</section>






  <section class="py-12 mt-0 bg-gray-100  policy-courses">
    <h1 class="mx-auto text-center text-xl md:text-3xl mb-8  text-color-rom-azul  font-bold font-roboto-slab">Recomendados para ti</h1>
    <div class="w-full mx-auto rounded-xl bg-gray-100    p-1 text-gray-800 overflow-hidden  min-w-80 max-w-3xl" >
      <div class="relative text-gray-600 focus-within:text-gray-400 mx-auto w-10/12">
        <span class="absolute inset-y-0 left-0 flex items-center pr-2">
        <button type="button" class="p-1 focus:outline-none focus:shadow-outline">
          <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-6 h-6"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
        </button>
        </span>
        <input type="text" name="strCurso" #strCurso (keyup.enter)="filtroCurso(strCurso.value)"  (keyup)="filtroCurso(strCurso.value)" class="w-full py-2 text-sm text-gray-900 border-gray-50 rounded-md pl-10 focus:outline-none focus:bg-white focus:text-gray-900" placeholder="buscador..." autocomplete="off">
      </div>


    </div>


    <br>


        <div  class="w-full md:w-10/12 mx-auto px-4 ">
          <ng-container *ngFor="let group of cursos; let i = index" >
            <h1 class="text-xl md:text-2xl my-8 text-color-rom-azul font-bold font-roboto-slab">{{group.strCategoria}}</h1>
            <owl-carousel-o class="policy-courses-options" [options]="customOptions">
            <ng-container  *ngFor="let curso of group.groupItem; let i = index" >
                <ng-template   carouselSlide>
                  <div  class="slider">
                    <article class="card flex flex-col">
                      <img class="h-36 w-full object-cover" src="{{BASE_URL_PUBLIC}}{{curso.strLogo}}" alt=""  />
                      <div class="card-body flex-1 flex flex-col">
                        <h1 class="h-14 text-xl text-color-rom-azul  font-bold mb-2 leading-5 font-roboto-slab">{{curso.strTitulo | slice:0:45}}</h1>
                        <p class="h-16 font-roboto">{{curso.strDescripcion | slice:0:75}}... </p>
                        <div class="flex">
                          <ul class="flex text-sm">
                            <li class="mr-1">
                              <i *ngIf="curso.intEvaloraciones>=1" class="fa fa-star text-yellow-500"></i>
                              <i *ngIf="curso.intEvaloraciones<1" class="fa fa-star text-gray-500"></i>
                            </li>
                            <li class="mr-1">
                              <i *ngIf="curso.intEvaloraciones>=2" class="fa fa-star text-yellow-500"></i>
                              <i *ngIf="curso.intEvaloraciones<2" class="fa fa-star text-gray-500"></i>
                            </li>
                            <li class="mr-1">
                              <i *ngIf="curso.intEvaloraciones>=3" class="fa fa-star text-yellow-500"></i>
                              <i *ngIf="curso.intEvaloraciones<3" class="fa fa-star text-gray-500"></i>
                            </li>
                            <li class="mr-1">
                              <i *ngIf="curso.intEvaloraciones>=4" class="fa fa-star text-yellow-500"></i>
                              <i *ngIf="curso.intEvaloraciones<4" class="fa fa-star text-gray-500"></i>
                            </li>
                            <li class="mr-1">
                              <i *ngIf="curso.intEvaloraciones==5" class="fa fa-star text-yellow-500"></i>
                              <i *ngIf="curso.intEvaloraciones<5" class="fa fa-star text-gray-500"></i>
                            </li>
                          </ul>
                          <p class="text-sm text-gray-700 ml-auto">
                            <i class="fa fa-users"></i>
                             {{curso.intEstudiantes}}

                          </p>
                        </div>
                        <br>
                        
                        <a  routerLink="/curso/info/{{curso.strSlug}}"  class="btn-rom-magenta cursor-pointer"> Ir a {{group.strCategoria}} </a>

                      </div>
                    </article>
                  </div>
                </ng-template>
            </ng-container>

            <ng-container class="hidden md:block" *ngFor="let item of group.group" >
              <ng-template carouselSlide></ng-template>
            </ng-container>

          </owl-carousel-o>
          </ng-container>


        </div>






  </section>


