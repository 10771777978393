import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CourseListComponent } from 'src/app/components/pages/admin/instructor/courses/course-list/course-list.component';
import { Tab } from 'src/app/tabconfig/tab.model';
import { TabService } from 'src/app/tabconfig/tab.service';
import { SolicitudEstudianteCursoComponent } from '../../pages/admin/solicitud-estudiante-curso/solicitud-estudiante-curso.component';
import { SecurityService } from '../../../system/security.service';
import { Router } from '@angular/router';
import { UsabilidadComponent } from '../../pages/admin/reporte/usabilidad/usabilidad.component';
import { CursoComponent } from '../../pages/admin/reporte/curso/curso.component';
import { ProfesorComponent } from '../../pages/admin/profesor/profesor.component';
import { AdministradorComponent } from '../../pages/admin/administrador/administrador.component';
import { MailBoxComponent } from '../../pages/admin/mail-box/mail-box.component';
import { HomeComponent } from '../../pages/admin/home/home.component';
import { EstudiantesComponent } from '../../pages/admin/estudiantes/estudiantes.component';
import { ComunService } from 'src/app/Services/comun/comun.service';
import { UtilsService } from 'src/app/Services/Utils/utils.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  @Output() btnAccion = new EventEmitter();

  tabs = new Array<Tab>();
  selectedTab: number;
  parameters: any[];

  emp_in_rol_type:string;

  nombre:string;
  nombres:string;
  apellidoPaterno:string;
  apellidoMaterno:string;
  correo:string;

  intProfesorID:number;
  intAdministradorID:number;

  intTotalPreguntasNoLeidosProfesor:number = 0;

  constructor(
    private tabService: TabService,
    private router : Router,
    private securityService: SecurityService,
    private comunService : ComunService,
    private utilsService : UtilsService
  ) {
    this.intProfesorID = this.securityService.GetProfesorID();
    this.intAdministradorID = this.securityService.GetCodAdministrador();
  }

  ngOnInit(): void {

    this.nombres = this.securityService.GetNombres() + " " + this.securityService.GetApellidoPaterno() + " " + this.securityService.GetApellidoMaterno();
    this.correo = this.securityService.GetCorreo();
    this.nombre = this.securityService.GetNombres();
    this.apellidoPaterno = this.securityService.GetApellidoPaterno();
    this.apellidoPaterno = this.securityService.GetApellidoMaterno();

    this.TotalPreguntasNoLeidosProfesor();
  }

  tabChanged(event) {
    console.log("tab changed");
  }

  addNewTab(componente,element) {
    switch (componente) {
      case 0:
        this.removeTabAll();
       // this.tabService.addTab(new Tab(HomeComponent, "Inicio", { parent: "AppComponent" }));
        break;

      case 1:
        this.tabService.addTab(new Tab(CourseListComponent, "Listado de Cursos", { parent: "AppComponent" }));
        break;

       case 2:
          this.tabService.addTab(new Tab(MailBoxComponent, "Buzón de mensaje", { parent: "AppComponent" }));
          break;

       case 7:
            this.tabService.addTab(new Tab(EstudiantesComponent, "Alumnos", { parent: "AppComponent" }));
       break;

       case 3:
          this.tabService.addTab(new Tab(ProfesorComponent, "Profesor", { parent: "AppComponent" }));
       break;

       case 4:
          this.tabService.addTab(new Tab(AdministradorComponent, "Administración", { parent: "AppComponent" }));
       break;

       case 5:
            this.tabService.addTab(new Tab(UsabilidadComponent, "Reporte Usabilidad", { parent: "AppComponent" }));
            break;
       case 6:
            this.tabService.addTab(new Tab(CursoComponent, "Reporte Curso", { parent: "AppComponent" }));
        break;
      /*
      case 2:
        this.tabService.addTab(new Tab(EmployeeListComponent, "Listado Profesor y Administrador", { parent: "AppComponent" }));
        break;
      case 3:
        this.tabService.addTab(new Tab(CoursesListComponent, "Listado Cursos", { parent: "AppComponent" }));
        break;
      case 4:
        this.tabService.addTab(new Tab(ParametersComponent, element.sys_vc_description, { parent: "AppComponent" , element: element}));
        break;
        case 5:
        this.tabService.addTab(new Tab(EmployeeQuizComponent, 'Encuesta de Profesor', { parent: "AppComponent" , element: element}));
        break;
        case 6:
        this.tabService.addTab(new Tab(CoursesQuizComponent, 'Encuesta del curso', { parent: "AppComponent" , element: element}));
        break;*/

    }
  }

  removeTab(index: number): void {
    this.tabService.removeTab(index);
  }

  removeTabAll(): void {
    this.tabService.removeTabAll();
  }

  CerrarSesion():void{
    this.securityService.ResetAuthData();
    this.router.navigate(['/login']);
  }

  TotalPreguntasNoLeidosProfesor(){
    //const intUsuarioID = +this.securityService.GetProfesorID();
    const intUsuarioID = this.utilsService.convertBase64Url(this.securityService.GetUsuarioID());
    this.comunService.getComentariosNoLeidosProfesor(intUsuarioID).subscribe(result=>{
      if(result.code==200){
        this.intTotalPreguntasNoLeidosProfesor = +result.obj;
      }
    }, (err)=>{
      console.log(err);
    });
  }
}
