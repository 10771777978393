import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ResultModel } from 'src/app/models/result.model';
import { SecurityService } from 'src/app/system/security.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CursosService extends AppService {

  constructor(http: HttpClient, securityService:SecurityService) {
    super(http,securityService);
  }

  getCursoPorSlug<T>(strSlug:string,strUsuarioID):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/estudiante/curso/info/${strSlug}/${strUsuarioID}`;
    return this.get(this.endpoint);
  }

  getCursoSolicitar<T>(datos:any):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/estudiante/curso/solicitar`;
    return this.post(this.endpoint,datos);
  }
  getCursoMatriculados<T>(strUsuarioID:string):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/estudiante/curso/matriculados/${strUsuarioID}`;
    return this.get(this.endpoint);
  }

  getCursoPorSlugVista<T>(strSlug:string,intLeccion:number,strUsuarioID:string):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/estudiante/curso/vista/${strSlug}/${intLeccion}/${strUsuarioID}`;
    return this.get(this.endpoint);
  }

  PostLeccionUsuario<T>(datos:any):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/estudiante/curso/vista`;
    return this.post(this.endpoint,datos);
  }

  PostValoracion<T>(datos:any):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/estudiante/curso/valoracion`;
    return this.post(this.endpoint,datos);
  }

  PostEvaluacion<T>(datos:any):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/estudiante/curso/leccion/evaluacion`;
    return this.post(this.endpoint,datos);
  }

  PostValidacionTerminoCuestionario<T>(datos:any):Observable<ResultModel>{
    debugger;
    this.endpoint = `${environment.BASE_URL_API}/estudiante/curso/leccion/validacion`;
    return this.post(this.endpoint,datos);
  }

  ReseteoRespuestaCuestionario<T>(datos:any):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/estudiante/curso/leccion/reseteo/cuestionario`;
    return this.post(this.endpoint,datos);
  }  

  PostRegistroFinalizacionCurso<T>(datos:any):Observable<ResultModel>{    
    this.endpoint = `${environment.BASE_URL_API}/estudiante/curso/leccion/finalizacion`;
    return this.post(this.endpoint,datos);
  }
  getValidarValoracion<T>(intCursoID:number,strUsuarioID:string):Observable<ResultModel>{
    debugger;
    this.endpoint = `${environment.BASE_URL_API}/estudiante/curso/validarvaloracion/${intCursoID}/${strUsuarioID}`;
    return this.get(this.endpoint);
  }
  getValidarTipoConstancia<T>(strUsuarioID:string,intCursoID:number):Observable<ResultModel>{
    debugger;
    this.endpoint = `${environment.BASE_URL_API}/estudiante/curso/validartipoconstancia/${strUsuarioID}/${intCursoID}`;
    return this.get(this.endpoint);
  }
}
