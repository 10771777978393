<div>
  <h1>ARCHIVOS COMPLEMENTARIOS</h1>
  <hr class="mt-2 mb-6">
    <article *ngFor="let archivosComplementario of archivosComplementarios" class="card mb-4">
        <div class="card-body bg-gray-100 ">
            <header class="cursor-pointer">
              <h1 *ngIf="archivosComplementario.strObligatorio!='1'">{{archivosComplementario.strTipoArchivo}} (Opcional)</h1>
              <h1 *ngIf="archivosComplementario.strObligatorio=='1'">{{archivosComplementario.strTipoArchivo}} (*)Obligatorio</h1>
            </header>
            <div>
              <hr class="my-2">
                  <div *ngIf="archivosComplementario.intArchivoComplementarioID>0" class="flex justify-start items-center">
                      <p>
                        <i (click)="donwload(archivosComplementario)" class="fa fa-download text-gray-500 mr-2 cursor-pointer"></i>
                        {{archivosComplementario.strTipoArchivo}}
                      </p>
                      <i (click)="eliminar(archivosComplementario.intArchivoComplementarioID)"  class="fa fa-trash text-red-500 cursor-pointer pl-4"></i>
                  </div>

                  <div *ngIf="archivosComplementario.intArchivoComplementarioID==0" class="  flex items-center ">
                    <input  type="file" class="form-input flex-1" ng2FileSelect [uploader]="uploader">
                    <button (click)="upload(archivosComplementario)" type="submit" class="btn-rom-azul ml-2">Guardar</button>
                  </div>
            </div>



        </div>
      </article>
  </div>
