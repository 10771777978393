<div>
  <h1>LECCIONES DEL CURSO</h1>
  <hr class="mt-2 mb-6">
  <article *ngFor="let seccion of secciones " class="card mb-6">
      <div class="card-body bg-gray-100">
        <header  class=" flex justify-between items-center">
          <h1  class="cursor-pointer"><strong>Sección 1: </strong>{{seccion.strDescripcion}}</h1>
          <div>
             Avances 100.00
          </div>
        </header>

        <div>
          <article *ngFor="let leccion of seccion.eL_Leccion_DTO" class="card mt-4">
            <div class="card-body">
              <header class="flex justify-between items-center">
                <h1  class="cursor-pointer">
                  <i class="fa fa-play-circle-o text-blue-500 mr-1"></i>
                    Lección 1:  {{leccion.strDescripcion}}
                </h1>
                <div>
                  Calificación: 80.00 Puntos
              </div>
              </header>
              <div>
                <article  class="card mb-4" *ngFor="let evalucion of leccion.eL_CuestionarioRespuesta_DTO">
                    <div class="card-body bg-gray-100">
                      <header  class="flex justify-between items-center">
                        <h1 class="cursor-pointer">{{evalucion.strDescripcion}}</h1>
                        <div>
                          x
                        </div>
                      </header>
                    </div>
                </article>
              </div>
            </div>
          </article>



        </div>
      </div>
  </article>



</div>
