import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-msg-success',
  templateUrl: './msg-success.component.html',
  styleUrls: ['./msg-success.component.scss']
})
export class MsgSuccessComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data : any,
    public dialogRef: MatDialogRef<MsgSuccessComponent>
  ) { }

  ngOnInit(): void {
  }

}
