import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { ResultModel } from 'src/app/models/result.model';
import { SecurityService } from 'src/app/system/security.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeccionService extends AppService {

  constructor(http: HttpClient, securityService:SecurityService) {
    super(http,securityService);
  }

  getSeccion<T>(intCursoID:number):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/seccion/${intCursoID}`;
    return this.get(this.endpoint);
  }
  postSeccion<T>(datos:any):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/seccion`;
    return this.post(this.endpoint,datos);
  }
  putSeccion<T>(datos:any):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/seccion`;
    return this.put(this.endpoint,datos);
  }
  deleteSeccion<T>(intSeccionID:number):Observable<ResultModel>{
    this.endpoint = `${environment.BASE_URL_API}/admin/seccion/${intSeccionID}`;
    return this.delete(this.endpoint);
  }
}
