import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs/dist/exceljs';
import * as fs from 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class ExcelJSService {

  constructor() { }

  DescargarExcelGenerico(title:string,sheetName:string,header:any[],body:any[]):void{
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(sheetName);

      //Add Header Row
    let headerRow = worksheet.addRow(header);
    worksheet.columns[0].width = 10;
    worksheet.columns[1].width =30;
    worksheet.columns[2].width = 25;
    worksheet.columns[3].width = 25;
    worksheet.columns[4].width = 10;
    worksheet.columns[5].width = 10;
    worksheet.columns[6].width = 20;
    worksheet.columns[7].width = 35;
    worksheet.columns[8].width = 25;

   /* worksheet.columns[9].width = 25;
    worksheet.columns[10].width = 25;
    worksheet.columns[11].width = 25;
    worksheet.columns[12].width = 25;
    worksheet.columns[13].width = 25;
    */

    // Cell Style : Fill and Border


    headerRow.eachCell((cell:any, number:any) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'E8E8E8' },
        bgColor: { argb: 'E8E8E8' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };

    });
    body.forEach((datos:any) => {
      let row = worksheet.addRow(datos);
    });
    worksheet.addRow([]);
    workbook.xlsx.writeBuffer().then((data:any) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, title +'.xlsx');
    });

  }
}
