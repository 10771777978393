<div class="card" >
    <div class="card-body">
      <fieldset class="w-full">
        <legend>Búsqueda</legend>
        <div class="card">
          <div class="card-body">

            <form class="w-full" autocomplete="off" [formGroup]="form">
              <div class="flex flex-wrap -mx-3 mb-2">
                <div class="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                    Categoría
                  </label>
                  <div class="relative">
                    <select formControlName="intCategoriaID" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                      <option value="0" >Todos</option>
                      <ng-container  *ngFor="let parametro of parametros">
                        <option *ngIf="parametro.intGrupo==500" value="{{parametro.intParametro}}" >{{parametro.strDescripcion}}</option>
                      </ng-container>
                    </select>

                  </div>
                </div>
                <div class="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                    Estado
                  </label>
                  <div class="relative">
                    <select formControlName="intEstadoID" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                      <option value="0" >Todos</option>
                      <ng-container  *ngFor="let parametro of parametros">
                        <option *ngIf="parametro.intGrupo==900" value="{{parametro.intParametro}}" >{{parametro.strDescripcion}}</option>
                      </ng-container>
                    </select>

                  </div>
                </div>
                <div class="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                    &nbsp;
                  </label>
                  <div class="flex">
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <button (click)="buscar()" class="font-medium pt-2 pb-2 pl-4 pr-4 rounded text-white btn-sm pull-right" style="background-color: #FF455C !important;" >
                        <i class="fa fa-search" aria-hidden="true"></i>
                        Buscar Curso</button>
                    </div>
                    <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                      <button (click)="create()" type="button" class="font-medium pt-2 pb-2 pl-4 pr-4 rounded  text-white btn-sm pull-right bg-blue-900" >
                        <i class="fa fa-book" aria-hidden="true"></i>
                        Nuevo Curso</button>

                    </div>
                  </div>
                </div>
              </div>

              <div class="flex flex-wrap -mx-3 mb-2">
                <div class="w-full px-3 mb-6 md:mb-0">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-state">
                    Buscador
                  </label>
                  <div class="relative">
                     <input formControlName="strTitulo"    type="text" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </fieldset>

      <fieldset class="w-full">
        <legend>Listado</legend>
        <div class="card">
          <div class="card-body">

            <div class="mat-elevation-z8 ">
              <table mat-table [dataSource]="dataSource">

                <!-- Position Column -->
                <ng-container matColumnDef="strDescripcion">
                  <th   mat-header-cell *matHeaderCellDef> Curso </th>
                  <td class="px-6 py-4 whitespace-nowrap" mat-cell *matCellDef="let element">

                    <div class="flex items-center">
                      <div class="flex-shrink-0 h-10 w-10">
                        <img *ngIf="element.strLogo"  class="h-10 w-10 rounded-full object-cover object-center" src="{{BASE_URL_PUBLIC}}/{{element.strLogo}}" alt="">
                        <img *ngIf="!element.strLogo"  class="h-10 w-10 rounded-full object-cover object-center" src="assets/img/courses/cursodefault.png" alt="">
                      </div>
                      <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900">

                            {{element.strTitulo | slice:0:65}}
                          </div>
                          <div *ngIf="element.strCategoria" class="text-sm text-gray-500">
                            {{element.strCategoria}}
                          </div>
                          <div *ngIf="element.strNiveles" class="text-sm text-gray-500">
                            {{element.strNiveles}}
                          </div>
                      </div>
                    </div>
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="intEstudiantes">
                  <th class="text-center" mat-header-cell *matHeaderCellDef> Matriculados </th>
                  <td class="px-6 py-4 whitespace-nowrap text-center" mat-cell *matCellDef="let element">
                     <div class="text-sm text-gray-900 "> {{element.intEstudiantes}}</div>
                     <div class="text-sm text-gray-500">Alumnos Matriculados</div>
                  </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="intEvaloraciones">
                  <th class="text-center" mat-header-cell *matHeaderCellDef> Calificación </th>
                  <td class="px-6 py-4 whitespace-nowrap text-center" mat-cell *matCellDef="let element">
                    <div class="text-sm text-gray-900 flex flex-wrap content-center object-center">
                      <div class="w-full mx-auto flex justify-center">
                        <div >
                          {{element.intEvaloraciones}}
                       </div>
                         <ul class="flex text-sm ml-2 justify-center center">
                           <li class="mr-1">
                             <i *ngIf="element.intEvaloraciones >= 1" class="fa fa-star text-yellow-500"></i>
                             <i *ngIf="element.intEvaloraciones >= 2" class="fa fa-star text-yellow-500"></i>
                             <i *ngIf="element.intEvaloraciones >= 3" class="fa fa-star text-yellow-500"></i>
                             <i *ngIf="element.intEvaloraciones >= 4" class="fa fa-star text-yellow-500"></i>
                             <i *ngIf="element.intEvaloraciones == 5" class="fa fa-star text-yellow-500"></i>

                             <i *ngIf="element.intEvaloraciones < 1" class="fa fa-star text-gray-500"></i>
                             <i *ngIf="element.intEvaloraciones < 2" class="fa fa-star text-gray-500"></i>
                             <i *ngIf="element.intEvaloraciones < 3" class="fa fa-star text-gray-500"></i>
                             <i *ngIf="element.intEvaloraciones < 4" class="fa fa-star text-gray-500"></i>
                             <i *ngIf="element.intEvaloraciones < 5" class="fa fa-star text-gray-500"></i>
                           </li>
                         </ul>
                      </div>

                    </div>
                    <div class="text-sm text-gray-500">
                        Valoración
                    </div>

                  </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="strEstado">
                  <th mat-header-cell *matHeaderCellDef> Estado </th>
                  <td mat-cell *matCellDef="let element"> {{element.strEstado}} </td>
                </ng-container>

                <ng-container matColumnDef="opcion">
                  <th style="width: 36%;" mat-header-cell *matHeaderCellDef> Opciones </th>
                  <td mat-cell *matCellDef="let element">
                      <i (click)="edit(element)" class="fa fa-edit cursor-pointer text-xl text-blue-700"></i>
                      <i (click)="eliminar(element)" class="fa fa-trash-o cursor-pointer text-xl text-red-700 pl-2" aria-hidden="true"></i>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>

              <mat-paginator #paginatorDatasource [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>

          </div>
        </div>
      </fieldset>

    </div>
  </div>
